import React from 'react';
import notificationService from '../../services/NotificationService';
import { useIsLoading } from '../../hooks/useIsLoading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingMessageEnum as LoadingMessage } from '../../helpers/LoadingMessageEnum';
import { useLoadingMessage } from '../../hooks/useLoadingMessage';
import { SubmitHandler, useForm } from 'react-hook-form';
import loggerService from '../../services/LoggerService';
import { getButtonPrimaryStyleClass, getInputTextStyleClass } from '../../config/FormSettings';
import { faSearch, faPaste } from '@fortawesome/free-solid-svg-icons';
import Tooltip from '../Tooltip';
import axiosInstance, { CustomAxiosRequestConfig } from '../../auth/config/axiosInstance';
import { ApiRoutesEnum } from '../../routes/ApiRoutesEnum';
import NotificationService from '../../services/NotificationService';
import { useNavigate } from 'react-router-dom';
import { canBeValidCampaignCode, generateCampaignCodeUrl } from '../../helpers/CampaignHelper';

interface CampaignSearchByCodeFormInputs {
    code: string;
}

const CampaignSearchByCode: React.FC = () => {
    const { register, handleSubmit, setValue, formState: { errors } } = useForm<CampaignSearchByCodeFormInputs>();

    const { isLoading, setIsLoading } = useIsLoading();
    const { loadingMessage, setLoadingMessage } = useLoadingMessage();
    const navigate = useNavigate();

    const onSubmit: SubmitHandler<CampaignSearchByCodeFormInputs> = async (data) => {
        const handleSubmitForm = async () => {
            setIsLoading(true);
            setLoadingMessage(LoadingMessage.Message);

            try {
                var code = data.code.toUpperCase();

                if (!code) {
                    NotificationService.setMessage({
                        message: "Enter a code",
                        status: false,
                    })
                    return;
                }

                if (!canBeValidCampaignCode(code))
                {
                    NotificationService.setMessage({
                        message: "This doesn't seem to be a valid Giveaway Code",
                        status: false,
                    })
                    return;
                }

                const response = await axiosInstance.get(ApiRoutesEnum.GET_CAMPAIGN_BY_CODE,
                    {
                        authNeeded: true,
                        params: {
                            code: code,
                        }
                    } as CustomAxiosRequestConfig);

                const { campaignResponse } = response.data;

                const campaignUrl = generateCampaignCodeUrl(campaignResponse.code, false);

                navigate(campaignUrl);

            } catch (error) {
                NotificationService.setError(error);
            }
            finally {
                setIsLoading(false);
            }
        };

        handleSubmitForm();
    };

    return (
        <>
            <h1 className="text-2xl font-bold mb-4">Search Giveaway Result By Code</h1>
            <p className="text-center mb-4">Find the result of the giveaways with the giveaway code. Make sure the results are safe</p>
            <form onSubmit={handleSubmit(onSubmit)} className="w-full max-w-md">
                <div className="flex items-center py-2">
                    <FontAwesomeIcon icon={faSearch} className="mr-2 text-primary" />
                    <input
                        type="text"
                        {...register('code', { required: 'CODE is required' })}
                        placeholder="Enter a Giveaway CODE"
                        className={`${getInputTextStyleClass()} w-full py-2 px-2`}
                    />
                    <Tooltip text="Paste">
                        <FontAwesomeIcon onClick={async () => { setValue("code", await navigator.clipboard.readText()) }} icon={faPaste} className="mr-2 text-primary cursor-pointer" />
                    </Tooltip>
                </div>
                <div className="w-full text-center py-1">
                    {errors.code && <p className="text-red-500 text-sm">{errors.code.message}</p>}
                </div>
                <button
                    type="submit"
                    className={`${getButtonPrimaryStyleClass(true)} w-full p-2`}
                    disabled={isLoading}
                >
                    {isLoading ? loadingMessage : "Search"}
                </button>
            </form>
        </>
    );
};

export default CampaignSearchByCode;