import React, { useEffect, useState } from 'react';
import notificationService from '../../../services/NotificationService';
import { useIsLoading } from '../../../hooks/useIsLoading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingMessageEnum as LoadingMessage } from '../../../helpers/LoadingMessageEnum';
import { useLoadingMessage } from '../../../hooks/useLoadingMessage';
import { SubmitHandler, useForm } from 'react-hook-form';
import loggerService from '../../../services/LoggerService';
import { RoutesEnum as AppRoutes } from '../../../routes/RoutesEnum';
import { SocialPlatformEnum as SocialPlatform } from '../../../helpers/SocialPlatformEnum';
import { CampaignStateDataDTO } from '../../../dto/CampaignStateDataDTO';
import { YesNoEnum } from '../../../helpers/YesNoEnum';
import { isValidTwitterXUrl, setNavigateCampaignState } from '../../../helpers/CampaignHelper';
import { getButtonPrimaryStyleClass, getInputTextStyleClass } from '../../../config/FormSettings';
import { useCampaignContext } from '../../../contexts/CampaignContext';
import CampaignPreview from '../CampaignPreview';
import { CampaignTypeEnum } from '../../../helpers/CampaignTypeEnum';
import { ensureTrailingSlash } from '../../../helpers/StringsHelper';
import { isRouteActive } from '../../../helpers/RoutesHelper';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../../auth/contexts/AuthContext';
import { faPaste, faRetweet, faX } from '@fortawesome/free-solid-svg-icons';
import Tooltip from '../../Tooltip';
import { CampaignViewEnum } from '../../../helpers/CampaignViewEnum';
import PasteBtn from '../../PasteBtn';

interface TwitterXUrlAppFormInputs {
    url: string;
}

const TwitterXUrlApp: React.FC = () => {
    const { setCampaignStateDataDTO, campaignStateDataDTO, setCampaignViewEnum, unmountCampaignRevertDefaults } = useCampaignContext();
    const { setIsAllowLoggedInFromHome } = useAuthContext();
    const location = useLocation();
    const { register, handleSubmit, setValue, formState: { errors } } = useForm<TwitterXUrlAppFormInputs>();

    const { isLoading, setIsLoading } = useIsLoading();
    const { loadingMessage, setLoadingMessage } = useLoadingMessage();
    const navigate = useNavigate();

    const handleCampaignStateData = (url: string) => {
        const campaignStateDataDTO: CampaignStateDataDTO = {
            SocialPlatform: SocialPlatform.Twitter,
            CampaignType: CampaignTypeEnum.Reposts,
            Referrer: AppRoutes.APP_TWITTER_URL,
            Api: YesNoEnum.No,
            Url: ensureTrailingSlash(url),
            SocialPlatformIcon: faX,
            SocialPlatformLikeIcon: faRetweet,
            SocialPlatformClassName: "text-twitter",
            CommentsSocialIcon: faRetweet,
            CommentsDisplayLabel: "Reposts",
            UsernameDisplay: "@",
            IsNotAvailableMessage: "Invalid Twitter / X.com tweet URL",
            ParticipantsCount: 0,
            ParticipantsCountDisplay: "0"
        };

        setCampaignViewEnum(CampaignViewEnum.Preview);
        setCampaignStateDataDTO(campaignStateDataDTO);

        setNavigateCampaignState(navigate);
    };

    useEffect(() => {
        unmountCampaignRevertDefaults();
    }, []);

    useEffect(() => {
        if (isRouteActive(location, AppRoutes.HOME)) {
            setIsAllowLoggedInFromHome(true);
        }

        return () => {
            setIsAllowLoggedInFromHome(false);
        };
    }, [campaignStateDataDTO]);

    const onSubmit: SubmitHandler<TwitterXUrlAppFormInputs> = async (data) => {
        const handleSubmitForm = async () => {
            setIsLoading(true);
            setLoadingMessage(LoadingMessage.Message);

            try {
                if (!isValidTwitterXUrl(data.url)) {
                    notificationService.setMessage({
                        message: 'Invalid Twitter / X tweet URL',
                        status: false
                    });

                    return;
                }

                handleCampaignStateData(data.url);
            } catch (error) {
                notificationService.setMessage({
                    message: 'Failed, try again.',
                    status: false
                });

                loggerService.error(error);
            }
            finally {
                setIsLoading(false);
            }
        };

        handleSubmitForm();
    };

    const handleClipboardRead = async () => {
        try {
            navigator.clipboard.readText().then((text) => setValue("url", text));
        } catch (err) {
            //no permission
        }
    };

    return (
        <>
            <h1 className="text-2xl font-bold mb-4">Twitter / X Comment Picker</h1>
            <p className="text-center mb-4">Pick a random winner from your X.com retweets</p>
            <form onSubmit={handleSubmit(onSubmit)} className="w-full max-w-md">
                <div className="flex items-center py-2">
                    <FontAwesomeIcon icon={faX} className="mr-2 text-twitter" />
                    <input
                        type="text"
                        {...register('url', { required: 'URL is required' })}
                        placeholder="Enter a Twitter / X Tweet URL"
                        className={`${getInputTextStyleClass()} w-full py-2 px-2`}
                    />
                    <PasteBtn onClick={handleClipboardRead} />
                </div>
                <div className="w-full text-center py-1">
                    {errors.url && <p className="text-red-500 text-sm">{errors.url.message}</p>}
                </div>
                <button
                    type="submit"
                    className={`${getButtonPrimaryStyleClass(true)} w-full p-2`}
                    disabled={isLoading}
                >
                    {isLoading ? loadingMessage : "Start"}
                </button>
            </form>
        </>
    );
};

export default TwitterXUrlApp;