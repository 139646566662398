import React from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import axiosInstance from '../config/axiosInstance';
import { useNavigate } from 'react-router-dom';
import notificationService from '../../services/NotificationService';
import { useIsLoading } from '../../hooks/useIsLoading';
import { useLoadingMessage } from '../../hooks/useLoadingMessage';
import { LoadingMessageEnum as LoadingMessage } from '../../helpers/LoadingMessageEnum';
import { RoutesEnum as AppRoutes } from '../../routes/RoutesEnum';
import { ApiRoutesEnum as ApiRoutes } from '../../routes/ApiRoutesEnum';
import useLoadRecaptcha from '../../hooks/useLoadRecaptcha';
import GoogleOauthAuth from '../components/GoogleOauthAuth';
import FacebookInstagramOauthAuth from '../components/FacebookInstagramOauthAuth';
import { useAuthContext } from '../contexts/AuthContext';
import AuthLinkLabels from './AuthLinkLabels';
import { AuthLinkLabelsDisplayEnum } from '../helpers/AuthLinkLabelsDisplayEnum';
import { getButtonPrimaryStyleClass, getInputTextStyleClass, getStandardCardBackground } from '../../config/FormSettings';
import { SocialPlatformEnum } from '../../helpers/SocialPlatformEnum';

interface LoginAuthFormInputs {
    usernameOrEmail: string;
    password: string;
}

interface LoginAuthProps {
    isAuthModal?: boolean;
    setIsModalOpen?: (isModalOpen: boolean) => void;
    setAuthLinkLabelsDisplayEnum?: (authLinkLabelsDisplayEnum: AuthLinkLabelsDisplayEnum) => void;
    authLinkLabelsDisplayEnum?: AuthLinkLabelsDisplayEnum;
    handleCampaignStateData?: () => void;
}

const LoginAuth: React.FC<LoginAuthProps> = ({ isAuthModal = false, setIsModalOpen, setAuthLinkLabelsDisplayEnum, authLinkLabelsDisplayEnum = AuthLinkLabelsDisplayEnum.LOGIN, handleCampaignStateData }) => {
    const { login } = useAuthContext();
    const { register, handleSubmit, formState: { errors } } = useForm<LoginAuthFormInputs>();
    const { loadingMessage, setLoadingMessage } = useLoadingMessage();
    const navigate = useNavigate();
    const isRecaptchaLoaded = useLoadRecaptcha();
    const { isLoading, setIsLoading } = useIsLoading();

    const onSubmit: SubmitHandler<LoginAuthFormInputs> = async (data: any) => {
        const handleSubmitForm = async () => {
            try {
                setIsLoading(true);
                setLoadingMessage(LoadingMessage.Message);

                if (!isRecaptchaLoaded) {
                    setLoadingMessage('Loading reCAPTCHA...');
                    setTimeout(handleSubmitForm, 1000);
                    return;
                }

                let recaptchaToken;
                try {
                    recaptchaToken = await window.grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY as string, { action: 'submit' });
                } catch (error) {
                    notificationService.setMessage({
                        message: 'reCAPTCHA failed. Please try again.',
                        status: false
                    });

                    setIsLoading(false);

                    return;
                }

                const formData = { ...data, recaptchaToken };

                const response = await axiosInstance.post(ApiRoutes.LOGIN, formData, {
                });

                const { token, refreshToken } = response.data;

                await login(token, refreshToken);

                if (isAuthModal) {
                    if (setIsModalOpen) {
                        setIsModalOpen(false);
                    }

                    notificationService.setMessage({
                        message: "Connected",
                        status: true
                    });

                    if (handleCampaignStateData) {
                        handleCampaignStateData();
                    }

                    setIsLoading(false);

                    //we don't need navigate, component will lose state
                }
                else {
                    notificationService.setMessage({
                        message: "Connected",
                        status: true
                    });

                    setIsLoading(false);

                    navigate(AppRoutes.DASHBOARD);
                }
            } catch (error: any) {
                notificationService.setError(error);

                setIsLoading(false);
            }
        };

        handleSubmitForm();
    };

    return (
        <div className={`${getStandardCardBackground(!isAuthModal)}`}>
            <h2 className="text-2xl font-bold mb-6">Login</h2>
            <div className="w-full justify-center">
                <FacebookInstagramOauthAuth
                    text="Login with"
                    setIsLoadingParent={setIsLoading}
                    isLoadingParent={isLoading}
                    isAuthModal={isAuthModal}
                    setIsModalOpen={setIsModalOpen}
                    handleCampaignStateData={handleCampaignStateData}
                    showOnly={SocialPlatformEnum.Facebook}
                />
            </div>
            <div className="w-full flex justify-center">
                <GoogleOauthAuth
                    text="Login with Google"
                    setIsLoading={setIsLoading}
                    isLoading={isLoading}
                    isAuthModal={isAuthModal}
                    setIsModalOpen={setIsModalOpen}
                    handleCampaignStateData={handleCampaignStateData}
                />
            </div>
            <div className="flex items-center my-4">
                <hr className="flex-grow border-gray-300" />
                <span className="mx-2 text-gray-500">OR</span>
                <hr className="flex-grow border-gray-300" />
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className='w-full'>
                <div className="mb-4">
                    <input
                        type="text"
                        {...register('usernameOrEmail', {
                            required: 'Username or Email are required'
                        })}
                        maxLength={100}
                        placeholder='Email or Username'
                        className={`${getInputTextStyleClass()} w-full py-2 px-2`}
                    />
                    {errors.usernameOrEmail && <p className="text-red-500 text-sm">{errors.usernameOrEmail.message}</p>}
                </div>
                <div className="mb-4">
                    <input
                        type="password"
                        {...register('password', {
                            required: 'Password is required',
                            maxLength: { value: 20, message: "Password cannot exceed 20 characters" }
                        })}
                        maxLength={20}
                        placeholder='Password'
                        className={`${getInputTextStyleClass()} w-full py-2 px-2`}
                    />
                    {errors.password && <p className="text-red-500 text-sm">{errors.password.message}</p>}
                </div>
                <button
                    type="submit"
                    className={`${getButtonPrimaryStyleClass(false)} w-full mt-4 mb-2`}
                    disabled={isLoading}
                >
                    {isLoading ? loadingMessage : "Login"}
                </button>
            </form>
            <AuthLinkLabels
                setAuthLinkLabelsDisplayEnum={setAuthLinkLabelsDisplayEnum}
                authLinkLabelsDisplayEnum={authLinkLabelsDisplayEnum}
            />
        </div>
    );
};

export default LoginAuth;