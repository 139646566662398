import React from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useParams, useNavigate, Link } from 'react-router-dom';
import axiosInstance from '../config/axiosInstance';
import notificationService from '../../services/NotificationService';
import Layout from '../../components/Layout';
import { Helmet } from 'react-helmet';
import { ApiRoutesEnum as ApiRoutes } from '../../routes/ApiRoutesEnum';
import { RoutesEnum as AppRoutes } from '../../routes/RoutesEnum';
import useLoadRecaptcha from '../../hooks/useLoadRecaptcha';
import { useIsLoading } from '../../hooks/useIsLoading';
import { useLoadingMessage } from '../../hooks/useLoadingMessage';
import { LoadingMessageEnum as LoadingMessage } from '../../helpers/LoadingMessageEnum';
import { getButtonPrimaryStyleClass, getInputTextStyleClass, getStandardCardBackground, getTextLinkStyleClass } from '../../config/FormSettings';

interface NewPasswordFormInputs {
  newPassword: string;
  confirmPassword: string;
}

const SetNewPassword: React.FC = () => {

  const { token } = useParams<{ token: string }>();
  const { register, handleSubmit, formState: { errors }, watch } = useForm<NewPasswordFormInputs>();
  const newPassword = watch('newPassword');
  const { isLoading, setIsLoading } = useIsLoading();
  const { loadingMessage, setLoadingMessage } = useLoadingMessage();
  const navigate = useNavigate();
  const isRecaptchaLoaded = useLoadRecaptcha();

  const onSubmit: SubmitHandler<NewPasswordFormInputs> = async data => {
    const handleSubmitForm = async () => {
      try {
        setIsLoading(true);
        setLoadingMessage(LoadingMessage.Message);

        if (data.newPassword !== data.confirmPassword) {
          notificationService.setMessage({
            message: "Passwords do not match",
            status: false
          });

          setIsLoading(false);
          return;
        }

        if (!isRecaptchaLoaded) {
          setLoadingMessage('Loading reCAPTCHA...');
          setTimeout(handleSubmitForm, 1000);
          return;
        }

        let recaptchaToken;
        try {
          recaptchaToken = await window.grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY as string, { action: 'submit' });
        } catch (error) {
          notificationService.setMessage({
            message: 'reCAPTCHA failed. Please try again.',
            status: false
          });

          setIsLoading(false);

          return;
        }

        const formData = { ...data, token, recaptchaToken };

        const response = await axiosInstance.post(ApiRoutes.SET_NEW_PASSWORD, formData, {

        });

        const { status, message } = response.data;

        notificationService.setMessage({
          message: message,
          status: status
        });

        setIsLoading(false);

        navigate(AppRoutes.LOGIN);
      } catch (error: any) {
        notificationService.setError(error);

        setIsLoading(false);
      }
    };

    handleSubmitForm();
  };

  return (
    <Layout>
      <Helmet>
        <title>socialspicker.com - Set New Password</title>
      </Helmet>
      <Link to={AppRoutes.HOME} className="mb-8">
        <img src="/assets/logoBlack.svg" alt="Logo" className="w-full h-auto max-w-3xs" />
      </Link>
      <div className={`${getStandardCardBackground()}`}>
        <h2 className="text-2xl font-bold mb-6">Set New Password</h2>
        <form onSubmit={handleSubmit(onSubmit)} className='w-full'>
          <div className="mb-4">
            <input
              type="password"
              {...register('newPassword', {
                required: 'New password is required',
                minLength: { value: 6, message: 'Password must be at least 6 characters' },
                maxLength: { value: 20, message: "Password cannot exceed 20 characters" }
              })}
              maxLength={20}
              placeholder='New Password'
              className={`${getInputTextStyleClass()} w-full p-2 mt-1`}
            />
            {errors.newPassword && <p className="text-red-500 text-sm">{errors.newPassword.message}</p>}
          </div>
          <div className="mb-4">
            <input
              type="password"
              {...register('confirmPassword', { required: 'Confirm password is required', validate: value => value === newPassword || "Passwords do not match" })}
              maxLength={20}
              placeholder='Confirm Password'
              className={`${getInputTextStyleClass()} w-full p-2 mt-1`}
            />
            {errors.confirmPassword && <p className="text-red-500 text-sm">{errors.confirmPassword.message}</p>}
          </div>
          <button
            type="submit"
            className={`${getButtonPrimaryStyleClass(false)} w-full p-2`}
            disabled={isLoading}
          >
            {isLoading ? loadingMessage : "Set New Password"}
          </button>
        </form>
        <div className="mt-4 text-center">
          <Link to={AppRoutes.LOGIN} className={`${getTextLinkStyleClass()}`}>I Remember my password</Link>
        </div>
      </div>
    </Layout>
  );
};

export default SetNewPassword;