import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Layout from '../components/Layout';
import { Helmet } from 'react-helmet';
import { faCalendar, faUserFriends, faUser, faPencil, faCopy, faShare } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import { CampaignModel } from '../models/CampaignModel';
import { useIsLoading } from '../hooks/useIsLoading';
import axiosInstance, { CustomAxiosRequestConfig } from '../auth/config/axiosInstance';
import { ApiRoutesEnum } from '../routes/ApiRoutesEnum';
import notificationService from '../services/NotificationService';
import LoadingScreen from '../components/loadings/LoadingScreen';
import { getButtonPrimaryStyleClass, getButtonSuccessStyleClass, getStandardCardBackground } from '../config/FormSettings';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { RoutesEnum } from '../routes/RoutesEnum';
import { prettifyNumbers } from '../helpers/StringsHelper';
import SPPagination from '../components/SPPagination';
import { paginationSize } from '../config/envConfig';
import { MaxWEnum } from '../helpers/FormEnum';
import { getCampaignStatusLabel, getCampaignStatusClass, getCampaignReadyDaysRemaining, getSocialPlatformIconBySocialPlatform, getBgBySocialPlatform } from '../helpers/CampaignHelper';
import Tooltip from '../components/Tooltip';
import EditCampaignModal from '../components/modals/EditCampaignModal';
import { StatusEnum } from '../helpers/StatusEnum';
import { getDefinedRoutesWithoutParams } from '../helpers/RoutesHelper';
import { CampaignTypeEnum } from '../helpers/CampaignTypeEnum';

const Dashboard: React.FC = () => {

  const navigate = useNavigate();
  const [campaigns, setCampaigns] = useState<CampaignModel[] | null>(null);
  const { isLoading, setIsLoading } = useIsLoading();
  const [editCampaign, setEditCampaign] = useState<CampaignModel | null>(null);
  const [isEditCampaignModal, setIsEditCampaignModal] = useState<boolean>(false);
  const [totalCountCampaigns, setTotalCountCampaigns] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const pageCount = Math.ceil(totalCountCampaigns / paginationSize);

  useEffect(() => {
    const getCampaigns = async () => {
      try {
        setIsLoading(true);

        const response = await axiosInstance.get(ApiRoutesEnum.GET_CAMPAIGNS_BY_USER,
          {
            authNeeded: true,
            params: {
              pageNumber: currentPage,
              pageSize: paginationSize
            }
          } as CustomAxiosRequestConfig);

        const campaigns: CampaignModel[] = response.data.campaigns
          .map((campaign: any) => ({
            Id: campaign.id,
            Title: campaign.title,
            SocialPlatform: campaign.socialPlatform,
            CampaignType: campaign.campaignType,
            Date: campaign.date,
            ParticipantsCount: campaign.participantsCount,
            Author: campaign.author,
            Code: campaign.code,
            Status: campaign.status,
            Message: campaign.message
          }));

        setCampaigns(campaigns);
        setTotalCountCampaigns(response.data.totalCountCampaigns);

        setIsLoading(false);
      }
      catch (error: any) {
        notificationService.setError(error);

        setIsLoading(false);
      }
    };

    getCampaigns();

  }, [currentPage]);

  const handlePageChange = (selected: { selected: number }) => {
    setCurrentPage(selected.selected + 1);
  };

  const handleCreate = () => {
    navigate(RoutesEnum.APPS);
  };

  return (
    <Layout>
      <Helmet>
        <title>socialspicker.com - Dashboard</title>
      </Helmet>

      {isLoading ? (
        <>
          <LoadingScreen
            loadingMessage='Loading campaigns...'
          />
        </>
      ) : (
        <>
          {campaigns && campaigns.length > 0 ? (
            <>
              {/* Filters Section */}
              <div className={`${getStandardCardBackground(true, MaxWEnum.None)} mb-1`}>
                <div className="flex flex-row w-full">

                  <div className="flex items-center ml-auto">
                    <button onClick={handleCreate} className={`${getButtonSuccessStyleClass(false)}`}>
                      Create
                    </button>
                  </div>

                </div>
              </div>
              {/* Filters Section - end */}

              <div className="list-group list-group-flush w-full h-full min-h-96">
                {campaigns.map((campaign, index) =>
                  <div key={index} className="list-group-item flex items-center mb-1 justify-between bg-white shadow-md group">
                    <div className="rounded-lg p-6 flex flex-col items-center w-full h-full">
                      <div className="w-full mb-3 text-base font-semibold">
                        {campaign.Title}
                      </div>

                      {/*content*/}
                      <div className="w-full flex flex-col sm:flex-row items-center space-y-4 sm:space-y-0 sm:space-x-4">
                        <div className="flex-1">
                          <div className="text-sm text-gray-500">
                            <div className="mr-4 mt-2 inline-block">
                              <div className={`${getBgBySocialPlatform(campaign.SocialPlatform)}
                          text-white w-10 h-10 flex items-center text-center justify-center rounded-full`}>
                                <FontAwesomeIcon icon={getSocialPlatformIconBySocialPlatform(campaign.SocialPlatform ?? null)} className='items-center text-center h-7 w-7' />
                              </div>
                            </div>
                            <div className="mr-4 mt-2 inline-block">
                              <FontAwesomeIcon icon={faCalendar} className="mr-1" />
                              {campaign.Date}
                            </div>
                            {campaign.Author && (
                              <div className="mr-4 mt-2 inline-block">
                                <FontAwesomeIcon icon={faUser} className="mr-1" />
                                {campaign.Author}
                              </div>
                            )}
                            <div className="mr-4 mt-2 inline-block">
                              <FontAwesomeIcon icon={faUserFriends} className="mr-1" />
                              Participants {prettifyNumbers(campaign.ParticipantsCount)}
                            </div>
                            <div className="mr-4 mt-2 inline-block">
                              <FontAwesomeIcon icon={faShare} className="mr-1" />
                              Type: {campaign.SocialPlatform}
                            </div>
                            <div className="mr-4 mt-2 inline-block text-center rounded shadow-md bg-gray-100 p-1 cursor-pointer"
                              onClick={() => {
                                navigator.clipboard.writeText(campaign.Code ?? "");
                                notificationService.setMessage({
                                  message: "Copied",
                                  status: true,
                                });
                              }
                              }>
                              <Tooltip text="Click to copy">
                                Code <br></br> <span className='font-semibold text-primary'>{campaign.Code} <FontAwesomeIcon className='ml-2' icon={faCopy} /></span>
                              </Tooltip>
                            </div>
                          </div>
                        </div>
                        {/*dropdown*/}
                        <div className="ml-4 flex flex-col sm:flex-row items-center space-y-2 sm:space-y-0 sm:space-x-2">
                          <div className="ml-2 relative text-left">
                            <div className={`inline-block p-1 mb-1 shadow-md rounded text-sm ${getCampaignStatusClass(campaign.Status)}`}>
                              {getCampaignStatusLabel(campaign.Status)}
                            </div>
                            {campaign.Status === StatusEnum.Failed && (
                              <div className="mr-4 mt-2 text-center rounded shadow-md bg-gray-100 p-1 mb-2 text-sm">
                                {campaign.Message}
                              </div>
                            )}
                            {campaign.Status === StatusEnum.Ready && campaign.CampaignType !== CampaignTypeEnum.List && (
                              <div className="mr-4 mt-2 text-center rounded shadow-md bg-gray-100 p-1 mb-2 text-sm">
                                Time left to start <br></br> <span className='font-semibold text-primary'>{getCampaignReadyDaysRemaining(campaign.Date ?? "")}</span>
                              </div>
                            )}
                            {campaign.Status === StatusEnum.Ready && (
                              <div>
                                <button
                                  onClick={() => navigate(`${getDefinedRoutesWithoutParams(RoutesEnum.C)}${decodeURIComponent(campaign.Code ?? "")}`)}
                                  className={`${getButtonSuccessStyleClass(false)}`}
                                >
                                  Start Giveaway
                                </button>
                              </div>
                            )}
                            {campaign.Status === StatusEnum.Finish && (
                              <div>
                                <button
                                  onClick={() => navigate(`${getDefinedRoutesWithoutParams(RoutesEnum.C)}${decodeURIComponent(campaign.Code ?? "")}`)}
                                  className={`${getButtonPrimaryStyleClass(false)}`}
                                >
                                  Go To Result
                                </button>
                              </div>
                            )}
                          </div>
                          <div className="relative inline-block text-left">
                            <button
                              type="button"
                              className="ml-2 inline-flex items-center p-2 text-gray-500"
                              onClick={() => { setEditCampaign(campaign); setIsEditCampaignModal(true); }}
                            >
                              <FontAwesomeIcon icon={faPencil} className="text-lg" />
                            </button>
                          </div>
                        </div>
                        {/*dropdown - end*/}
                      </div>
                      {/*content - end*/}

                    </div>
                  </div>
                )}
                <SPPagination
                  pageCount={pageCount}
                  onPageChange={handlePageChange}
                  currentPage={currentPage}
                />
              </div>

            </>
          ) : (
            <>
              <div className='pt-8 align-center text-center'>
                <p className="text-center mb-4">Create your first campaign</p>
                <button onClick={handleCreate} className={`${getButtonPrimaryStyleClass(false)}`}>
                  Create
                </button>
              </div>
            </>
          )}
        </>
      )}

      <EditCampaignModal
        campaign={editCampaign}
        setCampaigns={setCampaigns}
        isOpen={isEditCampaignModal}
        onClose={() => setIsEditCampaignModal(false)}
      />

    </Layout>
  );
};

export default Dashboard;