import React, { useEffect, useState } from 'react';
import notificationService from '../../../services/NotificationService';
import { useIsLoading } from '../../../hooks/useIsLoading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingMessageEnum as LoadingMessage } from '../../../helpers/LoadingMessageEnum';
import { useLoadingMessage } from '../../../hooks/useLoadingMessage';
import { SubmitHandler, useForm } from 'react-hook-form';
import loggerService from '../../../services/LoggerService';
import { RoutesEnum as AppRoutes } from '../../../routes/RoutesEnum';
import { SocialPlatformEnum as SocialPlatform } from '../../../helpers/SocialPlatformEnum';
import { CampaignStateDataDTO } from '../../../dto/CampaignStateDataDTO';
import { YesNoEnum } from '../../../helpers/YesNoEnum';
import { getButtonPrimaryStyleClass, getInputTextStyleClass } from '../../../config/FormSettings';
import { useCampaignContext } from '../../../contexts/CampaignContext';
import { CampaignTypeEnum } from '../../../helpers/CampaignTypeEnum';
import { isRouteActive } from '../../../helpers/RoutesHelper';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../../auth/contexts/AuthContext';
import { faListNumeric, faPaste, faUsers } from '@fortawesome/free-solid-svg-icons';
import CampaignPreview from '../CampaignPreview';
import { WinnerDTO } from '../../../dto/WinnerDTO';
import { CampaignPreviewDTO } from '../../../dto/CampaignPreviewDTO';
import { getMaxLength, prettifyNumbers } from '../../../helpers/StringsHelper';
import Tooltip from '../../Tooltip';
import { CampaignViewEnum } from '../../../helpers/CampaignViewEnum';
import { setNavigateCampaignState } from '../../../helpers/CampaignHelper';
import PasteBtn from '../../PasteBtn';

interface RandomNameWinnerListAppFormInputs {
    list: string;
}

const YoutubeUrlApp: React.FC = () => {
    const { setCampaignStateDataDTO, campaignStateDataDTO, setCampaignParticipants, setCampaignPreviewDTO, unmountCampaignRevertDefaults, setCampaignViewEnum } = useCampaignContext();
    const { setIsAllowLoggedInFromHome } = useAuthContext();
    const location = useLocation();
    const { register, handleSubmit, setValue, formState: { errors } } = useForm<RandomNameWinnerListAppFormInputs>();
    const { isLoading, setIsLoading } = useIsLoading();
    const { loadingMessage, setLoadingMessage } = useLoadingMessage();
    const navigate = useNavigate();

    const handleCampaignStateData = async (list: string) => {
        const campaignStateDataDTO: CampaignStateDataDTO = {
            SocialPlatform: SocialPlatform.List,
            CampaignType: CampaignTypeEnum.List,
            Referrer: AppRoutes.APP_RANDOM_NAME_WINNERS,
            Api: YesNoEnum.No,
            SocialPlatformIcon: faListNumeric,
            SocialPlatformClassName: "text-randomNameWinner",
            CommentsDisplayLabel: "Participants",
            CommentsSocialIcon: faUsers,
            ParticipantsCount: 0,
            ParticipantsCountDisplay: "0"
        };

        const participantsNames: string[] = list.split('\n').map(line => line.trim()).filter(line => line !== '');

        const participants: WinnerDTO[] = participantsNames.map((participantName: any) => ({
            Username: getMaxLength(participantName, 100),
            UsernameDisplay: participantName,
            SocialPlatformIcon: campaignStateDataDTO.SocialPlatformIcon,
            SocialPlatformLikeIcon: campaignStateDataDTO.SocialPlatformLikeIcon,
            SocialPlatformClassName: campaignStateDataDTO.SocialPlatformClassName,
            IsValid: true,
            IsSubstitute: false,
        }));

        campaignStateDataDTO.ParticipantsCount = participants.length;
        campaignStateDataDTO.ParticipantsCountDisplay = prettifyNumbers(participants.length);

        setCampaignParticipants(participants);

        const CampaignPreviewDTO: CampaignPreviewDTO = {
            ParticipantsCount: participants.length,
            ParticipantsCountDisplay: prettifyNumbers(participants.length)
        };

        setCampaignPreviewDTO(CampaignPreviewDTO);
        setCampaignViewEnum(CampaignViewEnum.Preview);
        setCampaignStateDataDTO(campaignStateDataDTO);

        setNavigateCampaignState(navigate);
    };

    useEffect(() => {
        unmountCampaignRevertDefaults();
    }, []);

    useEffect(() => {
        if (isRouteActive(location, AppRoutes.HOME)) {
            setIsAllowLoggedInFromHome(true);
        }

        return () => {
            setIsAllowLoggedInFromHome(false);
        };
    }, [campaignStateDataDTO]);

    const onSubmit: SubmitHandler<RandomNameWinnerListAppFormInputs> = async (data) => {
        const handleSubmitForm = async () => {
            setIsLoading(true);

            try {
                await handleCampaignStateData(data.list);
            } catch (error) {
                notificationService.setMessage({
                    message: 'Failed, try again.',
                    status: false
                });

                loggerService.error(error);
            }
            finally {
                setIsLoading(false);
            }
        };

        handleSubmitForm();
    };

    const handleClipboardRead = async () => {
        try {
            navigator.clipboard.readText().then((text) => setValue("list", text));
        } catch (err) {
            //no permission
        }
    };

    return (
        <>
            <h1 className="text-2xl font-bold mb-4">Random Name Picker</h1>
            <p className="text-center mb-4">Pick a winner from a list of participants</p>
            <div className="w-full max-w-md text-left">
                <PasteBtn onClick={handleClipboardRead} />
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className="w-full max-w-md">
                <div className="flex items-center py-2">
                    <FontAwesomeIcon icon={faListNumeric} className="mr-2 text-randomNameWinner" />
                    <textarea
                        {...register('list', { required: 'Participants required' })}
                        placeholder="List of participants on each row"
                        rows={10}
                        className={`${getInputTextStyleClass()} w-full py-2 px-2`}
                    ></textarea>
                </div>
                <div className="w-full text-center py-1">
                    {errors.list && <p className="text-red-500 text-sm">{errors.list.message}</p>}
                </div>
                <button
                    type="submit"
                    className={`${getButtonPrimaryStyleClass(true)} w-full p-2`}
                    disabled={isLoading}
                >
                    {isLoading ? loadingMessage : "Start"}
                </button>
            </form>
        </>
    );
};

export default YoutubeUrlApp;