import React, { useEffect, useRef, useState } from 'react';
import CloseButton from '../../buttons/CloseButton';
import { getButtonErrorStyleClass, getButtonSuccessStyleClass, getInputTextStyleClass } from '../../../config/FormSettings';
import axiosInstance, { CustomAxiosRequestConfig } from '../../../auth/config/axiosInstance';
import { ApiRoutesEnum } from '../../../routes/ApiRoutesEnum';
import notificationService from '../../../services/NotificationService';
import { useCampaignContext } from '../../../contexts/CampaignContext';
import { UserDataDTO } from '../../../dto/UserDataDTO';
import NotificationService from '../../../services/NotificationService';

interface Props {
  userDataDto: UserDataDTO | null;
  isOpen: boolean;
  onClose: () => void;
}

const EditUserModal: React.FC<Props> = ({ userDataDto, isOpen, onClose }) => {
  const [isEditUser, setIsEditUser] = useState<boolean>(false);
  const [credits, setCredits] = useState<number | null>(userDataDto?.Credits || null);
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const editWinner = async () => {
      if (isEditUser) {
        try {
          const formData = {
            ParticipantCredits: credits,
            UserId: userDataDto?.Id
          }

          const response = await axiosInstance.post(
            ApiRoutesEnum.EDIT_USER_ADMIN,
            formData,
            { authNeeded: true } as CustomAxiosRequestConfig);

          NotificationService.setMessage({
            status: response.data.status,
            message: response.data.message
          });

          setIsEditUser(false);

          onClose();
        }
        catch (error: any) {
          notificationService.setError(error);

          setIsEditUser(false);
        }
      }
    }

    const init = async () => {
      await editWinner();
    }

    init();

    return () => {
      setCredits(null);
    }
  }, [isEditUser]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose]);

  if (!isOpen || !userDataDto) return null;

  return (
    <div
      className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 overflow-auto">
      <div
        ref={modalRef}
        className="relative bg-white p-6 rounded shadow-lg w-full max-w-lg mx-4 sm:mx-0 max-h-[90vh] overflow-y-auto">
        <div className='flex items-center justify-center absolute top-2 right-2'>
        </div>
        <div className="flex flex-col w-full my-4 text-center">
          <div className="font-semibold p-2 rounded-md mb-2">
            {userDataDto.Email} / {userDataDto.Name} / {userDataDto.Username} <span className='font-normal'>Edit</span>
          </div>
          <div className='p-1'>
            <input
              type="text"
              placeholder={`Current credits ${userDataDto.Credits}`}
              value={credits ?? ""}
              onChange={(e) => setCredits(parseInt(e.target.value))}
              className={`${getInputTextStyleClass()} w-full py-2 px-2`}
            />
          </div>
          <div className="p-4 rounded-md mb-2">
            <div className="px-5 mt-4 text-center">
              <div className='mb-6'>
                Current credits: {userDataDto.Credits}
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-2">
                <CloseButton
                  text="Close"
                  onClick={onClose}
                />
                <button
                  type="button"
                  onClick={() => { setIsEditUser(true); }}
                  className={`w-full ${getButtonSuccessStyleClass(true)}`}>
                  Edit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditUserModal;