import React from 'react';
import Layout from '../components/Layout';
import { Helmet } from 'react-helmet';

const PrivacyPolicy: React.FC = () => {
  const privacyData = [
    {
      header: "",
      text: `Last updated: 27 Nov, 2024`,
    },
    {
      header: "",
      text: `Socials Picker provides this Privacy Policy to inform you of our policies and procedures regarding the collection, use, protection, and disclosure of Personal Information received from your use of this website, located at https://socialspicker.com/ (“Site”), as well as all related websites including browser extensions, and other services provided by us (collectively, together with the Site, our “Service”), and in connection with our customer, vendor, and partner relationships. This Privacy Policy also tells you about your rights and choices with respect to your Personal Information, and how you can reach us to update your contact information or get answers to questions you may have about our privacy practices. In addition to the activities described in this Privacy Policy, we may process Personal Information on behalf of our commercial customers when they use the Service. We process such Personal Information as a data processor of our commercial customers, which are the entities responsible for the data processing. To understand how a commercial customer processes your Personal Information, please refer to that customer's privacy policy.`,
    },
    {
      header: "1. Personal information we may collect",
      text: `For the purpose of this Privacy Policy, “Personal Information” means any information relating to an identified or identifiable individual. We obtain Personal Information relating to you from various sources described below. Where applicable, we indicate whether and why you must provide us with your Personal Information, as well as the consequences of failing to do so. If you do not provide Personal Information when requested, you may not be able to benefit from our Service if that information is necessary to provide you with the service or if we are legally required to collect it.`,
    },
    {
      header: "2. Personal information provided by you",
      text: `Registration
If you desire to have access to certain restricted sections of the Site or request to receive marketing materials, you may be required to become a registered user, and to submit the following types of Personal Information to Socials Picker: your name, username, email address, password, business name (optional), business address (optional), business tax id (optional).

Customer Support
We may collect information through your communications with our customer support team or other communications that you may send us and their contents.

Social Media
In order to allow you to read comments on your social media platforms, we may ask you to provide your username, account ids, social handle, timezones, and email address.

Other
We may also collect your contact details when you provide them in the context of our customer, vendor, and partner relationships.

Data Deletion
You can delete your account, at any time by emailing our support at hq@socialspicker.com. Your account information will be immediately deleted from the server, and data will be permanently deleted.
`,
    },
    {
      header: "2a. Personal Information Collected from Connected Social Media Accounts",
      text: `If you connect your third party social media account to your Socials Picker account, we may collect certain information stored in your social media account such as:

Facebook
Socials Picker may allow you to connect a Facebook page or profile to your Socials Picker account, in which case we will access certain information from Facebook regarding your account. In particular, we may collect profile image, display name, username / page ID or profile ID. This includes the content of your post and engagement data (such as click rates, likes, re-shares, impressions, as well as general engagement counts), to the extent permitted by applicable law. This data will only be used by Socials Picker to provide you with the Service you expect and will not be shared with any third parties.

Twitter
Socials Picker may allow you to connect a Twitter profile to your Socials Picker account, in which case we will access certain information from Twitter regarding your account. In particular, we may collect profile image, display name, username / profile ID, access tokens, and sent posts. This includes the content of your post and engagement data (such as click rates, likes, retweets, re-shares, impressions, as well as general engagement counts), to the extent permitted by applicable law. This data will only be used by Socials Picker to provide you with the Service you expect and will not be shared with any third parties.

Instagram
Socials Picker may allow you to connect an Instagram profile to your Socials Picker account, in which case we will access certain information from Instagram regarding your account. In particular, we may collect profile image, display name, username / profile ID, access tokens, and sent posts. This includes the content of your post and engagement data (such as click rates, likes, re-shares, impressions, as well as general engagement counts), to the extent permitted by applicable law. This data will only be used by Socials Picker to provide you with the Service you expect and will not be shared with any third parties. Additionally, if you connect Twitter, Facebook, or Instagram when utilizing Socials Picker Reply we may collect: profile image, display name, username / page ID, access tokens, sent or received comment text and sent or received private message text. Additionally, if you connect Twitter, Facebook, or Instagram when utilizing Socials Picker we may collect: profile image, display name, username / handle, access tokens, social channels insights data and social accounts demographic data, sent tweets, social account's followers count, social account's insights data and social account's audience data.`,
    },
    {
      header: "2b. Personal Information Automatically Obtained from Your Interactions with the Service",
      text: `Log Data
When you use our Service, our servers automatically record information that your browser sends whenever you visit a website (“Log Data”). This Log Data may include information such as your IP address, browser type or the domain from which you are visiting, the web-pages you visit, the search terms you use, and any advertisements on which you click.

Cookies and Similar Technologies
Like many websites, we also use “cookie” technology to collect additional website usage data and to improve the Site and our Service. A cookie is a small data file that we transfer to your computer's hard disk. A session cookie enables certain features of the Site and our service and is deleted from your computer when you disconnect from or leave the Site. A persistent cookie remains after you close your browser and may be used by your browser on subsequent visits to the Site. Persistent cookies can be removed by following your web browser help file directions. Most Internet browsers automatically accept cookies. Socials Picker may use both session cookies and persistent cookies to better understand how you interact with the Site and our Service, to monitor aggregate usage by our users and web traffic routing on the Site, and to improve the Site and our Service. We may also automatically record certain information from your device by using various types of technology, including “clear gifs” or “web beacons.” This automatically collected information may include your IP address or other device address or ID, web browser and/or device type, the web pages or sites that you visit just before or just after you use the Service, the pages or other content you view or otherwise interact with on the Service, and the dates and times that you visit, access, or use the Service. We also may use these technologies to collect information regarding your interaction with email messages, such as whether you opened, clicked on, or forwarded a message, to the extent permitted under applicable law. You can instruct your browser, by editing its options, to stop accepting cookies or to prompt you before accepting a cookie from the websites you visit. Please note that if you delete, or choose not to accept, cookies from the Service, you may not be able to utilize the features of the Service to their fullest potential.

Third Party Web Beacons and Third Party Buttons
We may display third-party content on the Service, including third-party advertising. Third-party content may use cookies, web beacons, or other mechanisms for obtaining data in connection with your viewing of the third party content on the Service. Additionally, we may implement third party buttons, such as Facebook “share” buttons, that may function as web beacons even when you do not interact with the button. Information collected through third-party web beacons and buttons is collected directly by these third parties, not by Socials Picker. Please consult such third party's data collection, use, and disclosure policies for more information.

Links to Other Websites
Our Site contains links to other websites. The fact that we link to a website is not an endorsement, authorization or representation of our affiliation with that third party. We do not exercise control over third party websites. These other websites may place their own cookies or other files on your computer, collect data or solicit Personal Information from you. Other sites follow different rules regarding the use or disclosure of the Personal Information you submit to them. We are not responsible for the content, privacy and security practices, and policies of third-party sites or services to which links or access are provided through the Service. We encourage you to read the privacy policies or statements of the other websites you visit.`,
    },
    {
      header: "2c. Social Network Permissions",
      text: `For the correct provision of our service, Socials Picker may need to request social network permissions for Facebook, Twitter, Instagram or Google. Socials Picker promise to not make any use of said permissions beyond the provision of the service itself.

Access to Socials Picker platform through Facebook
Data collected: Facebook user 's email address, unique user identifier on Facebook.
Purpose: It is used to access the Socials Picker platform without the need of introducing a password. Furthermore, if it is the first time registering, the name, last name, and email fields will be automatically filled in for a quick registration. Accessing the Socials Picker platform through Facebook login is optional.
Facebook permissions requested:

public_profile: allows to collect the unique identifier and Facebook user name.
email: allows to collect the Facebook user 's email. Allows quick registration.
Data collection and elimination: Data will be kept until Facebook Login is disabled, the Socials Picker account is eliminated, or its elimination is specifically requested. The elimination of the data collected can also be requested by following the instructions listed in point 4 of this privacy policy.
Applications for comment-based sweepstakes on Facebook and Instagram
Data collected: List of users’ comments on organic and promoted posts on a Facebook or Instagram business account. The text, user ID, and time and date of every comment will be collected. Additionally, to be able to collect the comments, it is necessary to access the list of Facebook or Instagram business accounts that the contest administrator manages, the list of posts in an account, and in the case of managing a contest based on promoted posts or ads it is necessary to access the list of advertising accounts.
Purpose: The purpose is to be able to get the list of finalists of Facebook and Instagram comment-based sweepstakes. To provide the service it is necessary to store the data collected from the comments on the sweepstake’s post. The list of accounts that the client manages, the list of posts, the list of advertising accounts, and the list of ads are not stored. They are only used so that the user can select the post or ads for which the comments have to be imported.
Facebook permissions requested:

pages_show_list: To collect the Facebook and Instagram business accounts.
pages_read_engagement: To collect the posts of the Facebook and Instagram accounts.
pages_read_user_content: To collect the comments on a post.
instagram_basic: To collect the basic information of an Instagram account and its posts.
instagram_manage_comments: To be able to read the comments on an Instagram post.
Data collection and elimination: Data will be kept until Facebook Login is disabled, the Socials Picker account is eliminated, or its elimination is specifically requested. The elimination of the data collected can also be requested by following the instructions listed in point 4 of this privacy policy.`,
    },
    {
      header: "3. How we may use your personal information",
      text: `Create and manage your account, provide our Service, and respond to your inquiries;
Manage account authentication such as two-factor authentication;
Communicate with you to verify your account and for informational and operational purposes, such as account management, customer service, or system maintenance, including by periodically emailing you service-related announcements;
Tailor our Service (e.g., we may use cookies and similar technologies to remember your preferences);
Aggregate your Personal Information for analytical purposes;
Provide customer support;
Operate, evaluate and improve our business (including by developing new products and services; managing our communications; determining the effectiveness of our advertising; analyzing how the Service is being accessed and used; tracking performance of the Service; debugging the Service; facilitating the use of our Service);
Send you marketing communications about products, services, offers, programs and promotions of Socials Picker, and affiliated companies;
Ensure the security of our Service;
Manage our customer, service provider and partner relationships;
Enforce our agreements related to our Service and our other legal rights; and comply with applicable legal requirements, industry standards and our policies.`,
    },
    {
      header: "4. User rights regarding data.",
      text: `Socials Picker aims to take reasonable steps to allow you to correct, amend, delete, or limit the use of your Personal Data.

Whenever made possible, you can update your Personal Data directly within your account settings section. If you are unable to change your Personal Data, please contact us to make the required changes.

Users have the right to:

Access their personal data.
Request the rectification of inaccurate data or, if applicable, request its deletion.
Request limitation of the treatment of data.
Oppose the processing of their data.
Request its portability.

Data Deletion

You can delete your account, at any time, by mailing us at hq@socialspicker.com. Your account information will be immediately deleted from the web server, and data will be permanently deleted.`,
    },
  ]

  return (
    <Layout>
      <Helmet>
        <title>socialspicker.com - Privacy Policy</title>
      </Helmet>
      <div className="container mx-auto p-6 bg-white rounded-lg shadow-md mt-8">
        <h1 className="text-3xl font-bold mb-4 text-gray-800">Terms and Conditions</h1>

        {privacyData.map((item) => (
          <>
            {item.header && (
              <h2 className="text-xl font-semibold mb-2 text-gray-800">{item.header}</h2>
            )}
            {item.text && (
              <p style={{ whiteSpace: "pre-line" }} className="text-gray-700 mb-4 leading-relaxed whitespace-pre-line">
                {item.text}
              </p>
            )}
          </>
        ))}

      </div>
    </Layout>
  );
};

export default PrivacyPolicy;