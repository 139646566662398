import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComments, faCopy, faExternalLink, faQuestion, faQuoteLeft, faRemove, faUsers } from '@fortawesome/free-solid-svg-icons';
import CampaignCountdown from './CampaignCountdown';
import { Link, useNavigate } from 'react-router-dom';
import { getButtonErrorStyleClass, getButtonSuccessStyleClass, getStandardCardBackground, getTextLinkStyleClass } from '../../config/FormSettings';
import ConfettiCanvas from '../ConfettiCanvas';
import { useCampaignContext } from '../../contexts/CampaignContext';
import { useIsLoading } from '../../hooks/useIsLoading';
import CampaignLoading from '../loadings/CampaignLoading';
import { useLoadingMessage } from '../../hooks/useLoadingMessage';
import { prettifyNumbers } from '../../helpers/StringsHelper';
import { RoutesEnum } from '../../routes/RoutesEnum';
import { WinnerDTO } from '../../dto/WinnerDTO';
import { CampaignViewEnum } from '../../helpers/CampaignViewEnum';
import { useAuthContext } from '../../auth/contexts/AuthContext';
import { MaxWEnum } from '../../helpers/FormEnum';
import { getDefinedRoutesWithoutParams } from '../../helpers/RoutesHelper';
import { SocialPlatformEnum } from '../../helpers/SocialPlatformEnum';
import { generateCampaignCodeUrl, getBgBySocialPlatform, getCampaignAuthorProfileLinkBySocialPlatform, getSocialPlatformIconBySocialPlatform, getTextBySocialPlatform } from '../../helpers/CampaignHelper';
import useIsMobile from '../../hooks/useIsMobile';
import EditWinnerModal from '../modals/EditWinnerModal';
import axiosInstance, { CustomAxiosRequestConfig } from '../../auth/config/axiosInstance';
import { ApiRoutesEnum } from '../../routes/ApiRoutesEnum';
import NotificationService from '../../services/NotificationService';
import CampaignShareModal from '../modals/CampaignShareModal';
import Tooltip from '../Tooltip';

const CampaignWinner: React.FC = () => {
    const {
        setCampaignWinners,
        campaignWinners,
        setCampaignSubstitutes,
        campaignSubstitutes,
        campaignViewEnum,
        campaign,
        isCampaignOwner,
    } = useCampaignContext();
    const { isLoading, setIsLoading } = useIsLoading();
    const { loadingMessage, setLoadingMessage } = useLoadingMessage();
    const [isEditWinnerModal, setIsEditWinnerModal] = useState<boolean>(false);
    const [isCampaignShareModal, setIsCampaignShareModal] = useState<boolean>(false);
    const [isValidateWinner, setIsValidateWinner] = useState<boolean>(false);
    const [editWinner, setEditWinner] = useState<WinnerDTO | null>(null);
    const { isMobile } = useIsMobile();
    const [isCountdown, setIsCountdown] = useState<boolean>(true);

    useEffect(() => {
        const validateWinner = async () => {
            if (isValidateWinner) {
                try {
                    const formData = {
                        WinnerId: editWinner?.Id,
                        Code: campaign?.Code,
                        IsValid: !editWinner?.IsValid,
                        InvalidReason: ""
                    }

                    const response = await axiosInstance.post(
                        ApiRoutesEnum.EDIT_CAMPAIGN_WINNER,
                        formData,
                        { authNeeded: true } as CustomAxiosRequestConfig);

                    const newWinner = { ...editWinner, IsValid: !editWinner?.IsValid, InvalidReason: "" }

                    if (newWinner.IsSubstitute) {
                        setCampaignSubstitutes((prevState) => {
                            const newWinners = (prevState ?? []).map((oldWinner) =>
                                oldWinner.Id === newWinner.Id ? { ...oldWinner, ...newWinner } : oldWinner
                            );
                            return newWinners;
                        });
                    }
                    else {
                        setCampaignWinners((prevState) => {
                            const newWinners = (prevState ?? []).map((oldWinner) =>
                                oldWinner.Id === newWinner.Id ? { ...oldWinner, ...newWinner } : oldWinner
                            );
                            return newWinners;
                        });
                    }

                    setIsValidateWinner(false);
                }
                catch (error: any) {
                    NotificationService.setError(error);

                    setIsValidateWinner(false);
                }
            }
        }

        const init = async () => {
            await validateWinner();
        };

        init();
    }, [isValidateWinner]);

    const getWinnerDisplay = (winner: WinnerDTO, index: number) => {
        return (<div key={winner.Username} className={`w-full h-full min-h-32 relative bg-white rounded-lg px-4 py-2 m-4 flex items-center shadow-2xl  
            ${winner.IsValid ?
                "border-success shadow-success"
                :
                "border-error shadow-error"
            }`}>
            <div className="flex-1 w-full">

                <div className="absolute left-[20px] text-[70px] font-bold text-gray-200 font-semibold text-center items-center z-0">
                    {index + 1}
                </div>
                {winner.ProfileImage && (
                    <div className="relative text-center mt-4 z-10">
                        <img
                            alt={winner.Username}
                            src={winner.ProfileImage}
                            className="rounded-full w-12 h-full mx-auto"
                        />
                    </div>
                )}
                <div className="relative text-lg font-semibold text-center items-center p-2 z-10">
                    {winner?.ProfileLink ? (
                        <Link to={winner.ProfileLink} target="_blank" className={`${getTextLinkStyleClass()} py-2 px-4 rounded z-10`}>
                            <FontAwesomeIcon icon={getSocialPlatformIconBySocialPlatform(campaign?.SocialPlatform)} className={`mr-2 ${getTextBySocialPlatform(campaign?.SocialPlatform)}`} />
                            <span className="break-words">{winner?.Username}</span>
                            <FontAwesomeIcon icon={faExternalLink} className='ml-1 text-black' />
                        </Link>
                    ) : (
                        <>
                            <FontAwesomeIcon icon={getSocialPlatformIconBySocialPlatform(campaign?.SocialPlatform)} className={`mr-2 ${getTextBySocialPlatform(campaign?.SocialPlatform)}`} />
                            <span className="break-words">{winner?.Username}</span>
                        </>
                    )}
                </div>

                {winner?.Comment && (
                    <div className="relative text-center items-center text-sm text-gray-600 mt-2 p-2 z-10">
                        {winner?.CommentLink ? (
                            <Link to={winner.CommentLink} target="_blank" className={`${getTextLinkStyleClass()} py-2 px-4 rounded`}>
                                {winner?.Comment}
                                <FontAwesomeIcon icon={faExternalLink} className='ml-1 text-black' />
                            </Link>
                        ) : (
                            <>
                                <FontAwesomeIcon icon={faQuoteLeft} className={`mr-2 text-${campaign?.SocialPlatform.toLowerCase()}`} />
                                {winner?.Comment}
                            </>
                        )}
                    </div>
                )}

                {!winner.IsValid && winner?.InvalidReason && (
                    <div className="bg-gray-100 shadow-md text-center text-sm text-gray-600 mt-2 p-4 z-10">
                        <div className="flex justify-between items-center">
                            <div className="items-center flex-1">
                                <FontAwesomeIcon icon={faRemove} className={`mr-2 text-error`} />
                                {winner?.InvalidReason}
                            </div>
                        </div>
                    </div>
                )}

                {/*buttons*/}
                {isCampaignOwner && (
                    <div className="text-center items-center mt-2 p-2">
                        <div className={`flex flex-col sm:flex-row gap-2 justify-center items-center`}>
                            {winner.IsValid ? (
                                <button onClick={() => { setEditWinner(winner); setIsEditWinnerModal(true); }} className={`${getButtonErrorStyleClass(false)} p-1 text-sm z-10`}>
                                    {isEditWinnerModal ? "Wait..." : "Invalidate"}
                                </button>
                            ) : (
                                <button onClick={() => { setEditWinner(winner); setIsValidateWinner(true); }} className={`${getButtonSuccessStyleClass(false)} p-1 text-sm z-10`}>
                                    {isValidateWinner ? "Wait..." : "Validate"}
                                </button>
                            )}
                        </div>
                    </div>
                )}
                {/*buttons - end*/}

            </div>
        </div>
        )
    }

    return (
        <>
            {isLoading ? (
                <>
                    <CampaignLoading
                        loadingMessage={loadingMessage}
                    />
                </>
            ) : (
                <>
                    {isCountdown && campaignViewEnum === CampaignViewEnum.WinnersEdit ?
                        (
                            <>
                                <CampaignCountdown
                                    setIsCountdown={setIsCountdown}
                                    isCountdown={isCountdown}
                                />
                            </>
                        ) :
                        (
                            <>
                                <div className={`${getStandardCardBackground(false, MaxWEnum.MaxWXl, undefined, undefined, undefined, true, false)}`}>
                                    <div>
                                        <Link
                                            className={`${getTextLinkStyleClass()} text-md font-semibold`}
                                            to={`${getDefinedRoutesWithoutParams(RoutesEnum.P)}@${decodeURIComponent(campaign?.Username ?? "")}`}>@{campaign?.Username}</Link>
                                    </div>
                                    <div className='mt-4'>
                                        <div className={`text-lg font-semibold items-center flex-1`}>
                                            <div className="mr-2 mt-2 inline-block">
                                                <div className={`${getBgBySocialPlatform(campaign?.SocialPlatform)}
                          text-white w-7 h-7 flex items-center text-center justify-center rounded-full`}>
                                                    <FontAwesomeIcon icon={getSocialPlatformIconBySocialPlatform(campaign?.SocialPlatform ?? null)} className='items-center text-center h-5 w-5' />
                                                </div>
                                            </div>

                                            <p className='inline-block'>{campaign?.Title}</p>
                                        </div>
                                    </div>

                                    <div className={`${!isMobile && "flex"} mt-2 w-full`}>
                                        {/*left col*/}
                                        <div className={`${isMobile ? "w-2/2" : "w-1/2"} p-4 mx-auto`}>
                                            {campaign?.Author && (
                                                <div className="mb-3 text-center">
                                                    <div className="inline-flex items-center space-x-2">
                                                        <FontAwesomeIcon
                                                            icon={getSocialPlatformIconBySocialPlatform(campaign?.SocialPlatform)}
                                                            className={`mr-2 text-${campaign?.SocialPlatform.toLowerCase()}`} />
                                                        <Link to={getCampaignAuthorProfileLinkBySocialPlatform(campaign?.SocialPlatform, campaign?.Author) ?? "#"}
                                                            target='_blank'
                                                            className={`${getTextLinkStyleClass()} inline-flex items-center space-x-2`}
                                                        >
                                                            {campaign?.Author}
                                                            <FontAwesomeIcon
                                                                icon={faExternalLink} className='ml-1' />
                                                        </Link>
                                                    </div>
                                                </div>
                                            )}
                                            <div className="mb-3 text-center">
                                                <span className="items-center">
                                                    <FontAwesomeIcon icon={faUsers}
                                                        className={`mr-2 ${getTextBySocialPlatform(campaign?.SocialPlatform)}`} />
                                                    {campaign?.ParticipantsCount} participants
                                                </span>
                                            </div>
                                        </div>
                                        {/*left col - end*/}
                                        {/*right col*/}
                                        {(campaign?.Post || campaign?.Description) && (
                                            <div
                                                className={`p-4 ${isMobile ? "w-full" : "w-1/2"} p-4`}
                                            >
                                                {campaign?.Post && (
                                                    <div className="mb-3 text-center">
                                                        <Link to={campaign?.Post ?? "#"}
                                                            target='_blank'
                                                            className={`${getTextLinkStyleClass()} inline-flex items-center space-x-2`}
                                                        >
                                                            Giveaway
                                                            <FontAwesomeIcon
                                                                icon={faExternalLink} className='ml-1' />
                                                        </Link>
                                                    </div>
                                                )}
                                                {campaign?.Description && (
                                                    <div className="mb-3 text-center">
                                                        <p className='items-center'>
                                                            <FontAwesomeIcon icon={faQuoteLeft}
                                                                className={`mr-2 text-${campaign?.SocialPlatform.toLowerCase()}`} />
                                                            {campaign.Description}
                                                        </p>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                        {/*right col - end*/}
                                    </div>

                                    <div className='text-center items-center mb-2'>
                                        <p className='text-base'>{campaign?.Date}</p>
                                    </div>
                                    <div className='text-center items-center mb-4'>
                                        <div className="inline-block text-center rounded shadow-md bg-gray-100 p-1 cursor-pointer items-center flex"
                                            onClick={() => {
                                                navigator.clipboard.writeText(campaign?.Code ?? "");
                                                NotificationService.setMessage({
                                                    message: "Copied",
                                                    status: true,
                                                });
                                            }
                                            }>
                                            <Tooltip text="Click to copy">
                                                <span className='font-semibold text-primary'>{campaign?.Code}</span>
                                                <FontAwesomeIcon className='ml-2' icon={faCopy} />
                                            </Tooltip>
                                        </div>
                                    </div>
                                    <div className='text-center items-center'>
                                        <p className='text-primary'>Via socialspicker.com Giveaway</p>
                                        <p className='text-primary'>#1 Giveaway picker</p>
                                    </div>
                                </div>

                                <div className={`${getStandardCardBackground(false, MaxWEnum.None, "", "mt-1 mb-4", undefined, true, false)}`}>
                                    <div className="text-center mt-6 mb-2">
                                        <h4 className="text-xl text-gray-400 font-semibold">Winners</h4>
                                    </div>
                                    <div className="p-4 flex flex-wrap gap-4 justify-center items-center">
                                        {campaignWinners && campaignWinners.map((winner, index) => (
                                            <div key={index} className="w-full sm:w-64 flex justify-center">
                                                {getWinnerDisplay(winner, index)}
                                            </div>
                                        ))}
                                    </div>

                                    <div className="text-center mt-14">
                                        <h4 className="text-xl text-gray-400 font-semibold">Substitutes</h4>
                                    </div>
                                    <div className="p-4 flex flex-wrap gap-4 justify-center items-center">
                                        {campaignSubstitutes && campaignSubstitutes.map((winner, index) => (
                                            <div key={index} className="w-full sm:w-64 flex justify-center">
                                                {getWinnerDisplay(winner, index)}
                                            </div>
                                        ))}
                                    </div>
                                </div>

                                <div className="fixed bottom-0 left-0 right-0 flex justify-center sm:mb-0 md:mb-4 z-10">
                                    <button
                                        type="button" onClick={() => setIsCampaignShareModal(true)} className={`${getButtonSuccessStyleClass(true)} w-full max-w-md`}>
                                        Share
                                    </button>
                                </div>

                                <ConfettiCanvas />

                                <EditWinnerModal
                                    winner={editWinner}
                                    setCampaignWinners={setCampaignWinners}
                                    setCampaignSubstitutes={setCampaignSubstitutes}
                                    isOpen={isEditWinnerModal}
                                    onClose={() => setIsEditWinnerModal(false)}
                                />

                                <CampaignShareModal
                                    isOpen={isCampaignShareModal}
                                    onClose={() => setIsCampaignShareModal(false)}
                                />
                            </>
                        )}
                </>
            )}
        </>
    );
};

export default CampaignWinner;
