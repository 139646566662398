import React, { createContext, useContext, useEffect, useState } from 'react';
import AuthService from '../services/AuthService';
import { UserDataModel as UserData } from '../../models/UserDataModel';
import { useNavigate } from 'react-router-dom';

interface AuthContextProps {
  getUserDataFromToken: (navigate: (path: string) => void, forceRefresh: boolean) => Promise<UserData | null>;
  userData: UserData | null;
  login: (token: string, refreshToken: string) => Promise<void>;
  logout: () => Promise<void>;
  isLoggedIn: () => Promise<boolean>;
  isLoggedInState: boolean;
  getRole: () => Promise<string | null>;
  hasRole: (role: string) => Promise<boolean>;
  getToken: () => Promise<string | null>;
  getRefreshToken: () => Promise<string | null>;
  setInstagramFacebookAccessToken: (token: string) => Promise<void>;
  getInstagramFacebookAccessToken: () => Promise<string | null>;
  removeInstagramFacebookAccessToken: () => Promise<void>;
  setFacebookAccessToken: (token: string) => Promise<void>;
  getFacebookAccessToken: () => Promise<string | null>;
  removeFacebookAccessToken: () => Promise<void>;
  setIsAllowLoggedInFromHome: (value: boolean) => void;
  isAllowLoggedInFromHome: boolean;
  showGoogleAdsense: boolean;
  setShowGoogleAdsense: (value: boolean) => void;
  setIsPolingPaymentFired: (value: boolean) => void;
  isPolingPaymentFired: boolean;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export const AuthProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const [userData, setUserData] = useState<UserData | null>(null);
  const [isLoggedInState, setIsLoggedInState] = useState<boolean>(false);
  const [tokenState, setTokenState] = useState<string | null>();
  const [isAllowLoggedInFromHome, setIsAllowLoggedInFromHome] = useState<boolean>(false);
  const [showGoogleAdsense, setShowGoogleAdsense] = useState<boolean>(false);
  const [isPolingPaymentFired, setIsPolingPaymentFired] = useState<boolean>(false);

  const authService = AuthService();
  const navigate = useNavigate();

  useEffect(() => {
    const initAuth = async () => {
      const userData = await getUserDataFromToken(navigate);

      setUserData(userData);
      setIsLoggedInState(userData != null);
    };

    initAuth();
  }, [tokenState]);

  const getUserDataFromToken = async (navigate: (path: string) => void, forceRefresh: boolean = false) => {
    const refreshedUserData = await authService.getUserDataFromToken(navigate, forceRefresh);

    setUserData(refreshedUserData);
    setIsLoggedInState(refreshedUserData != null);

    return refreshedUserData;
  }

  const login = async (token: string, refreshToken: string) => {
    await authService.login(token, refreshToken);

    setTokenState(await authService.getToken());
  };

  const logout = async () => {
    await authService.logout(navigate);

    setTokenState(await authService.getToken());
  };

  const isLoggedIn = async () => {
    const loggedIn = await authService.isLoggedIn();

    setIsLoggedInState(loggedIn);

    return loggedIn;
  };

  const getRole = async () => {
    return authService.getRole();
  };

  const hasRole = async (role: string) => {
    return authService.hasRole(role);
  };

  const getToken = async () => {
    return authService.getToken();
  };

  const getRefreshToken = async () => {
    return authService.getRefreshToken();
  };

  const setInstagramFacebookAccessToken = async (facebookAccessToken: string) => {
    await authService.setInstagramFacebookAccessToken(facebookAccessToken);
  };

  const getInstagramFacebookAccessToken = async () => {
    return authService.getInstagramFacebookAccessToken();
  };

  const removeInstagramFacebookAccessToken = async () => {
    return authService.removeInstagramFacebookAccessToken();
  };

  const setFacebookAccessToken = async (facebookAccessToken: string) => {
    await authService.setFacebookAccessToken(facebookAccessToken);
  };

  const getFacebookAccessToken = async () => {
    return authService.getFacebookAccessToken();
  };

  const removeFacebookAccessToken = async () => {
    return authService.removeFacebookAccessToken();
  };

  return (
    <AuthContext.Provider value={{
      getUserDataFromToken,
      userData,
      login,
      logout,
      isLoggedIn,
      isLoggedInState,
      getRole,
      hasRole,
      getToken,
      getRefreshToken,
      getInstagramFacebookAccessToken,
      setInstagramFacebookAccessToken,
      removeInstagramFacebookAccessToken,
      getFacebookAccessToken,
      setFacebookAccessToken, removeFacebookAccessToken,
      setIsAllowLoggedInFromHome,
      isAllowLoggedInFromHome,
      showGoogleAdsense,
      setShowGoogleAdsense,
      isPolingPaymentFired,
      setIsPolingPaymentFired
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export default AuthProvider;