import { faPaste } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import Tooltip from './Tooltip';

interface Props {
  onClick: () => Promise<void>;
}

const PasteBtn: React.FC<Props> = ({ onClick }) => {
  return (
    <Tooltip text="Paste">
      <button
        type="button"
        onClick={async (e) => {
          e.preventDefault();
          await onClick();
        }}
        className="mr-2 text-primary cursor-pointer bg-transparent border-0 p-0"
        aria-label="Paste"
      >
        <FontAwesomeIcon icon={faPaste} />
      </button>
    </Tooltip>
  );
};

export default PasteBtn;