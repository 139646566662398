import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faQuestion, faQuoteLeft } from '@fortawesome/free-solid-svg-icons';
import { getButtonDisabledStyleClass, getButtonPrimaryStyleClass, getButtonSuccessStyleClass } from '../../config/FormSettings';
import { YesNoEnum } from '../../helpers/YesNoEnum';
import CloseButton from '../buttons/CloseButton';
import { useAuthContext } from '../../auth/contexts/AuthContext';
import { CampaignPreviewViewStepsEnum } from '../../helpers/CampaignPreviewViewStepsEnum';
import axiosInstance, { CustomAxiosRequestConfig } from '../../auth/config/axiosInstance';
import CampaignLoading from '../loadings/CampaignLoading';
import { useCampaignContext } from '../../contexts/CampaignContext';
import loggerService from '../../services/LoggerService';
import notificationService from '../../services/NotificationService';
import { CampaignViewEnum } from '../../helpers/CampaignViewEnum';
import { apiNoFetchExceptionDelayRequestsMs, apiNoFetchMaxRetry, maxParticipantsCount, minParticipantsCount } from '../../config/envConfig';
import { useIsLoading } from '../../hooks/useIsLoading';
import { useLoadingMessage } from '../../hooks/useLoadingMessage';
import { LoadingMessageEnum } from '../../helpers/LoadingMessageEnum';
import { CampaignStateDataDTO } from '../../dto/CampaignStateDataDTO';
import { ApiRoutesEnum } from '../../routes/ApiRoutesEnum';
import AuthModal from '../../auth/components/modals/AuthModal';
import LoginAuth from '../../auth/components/LoginAuth';
import SignupAuth from '../../auth/components/SignupAuth';
import { AuthLinkLabelsDisplayEnum } from '../../auth/helpers/AuthLinkLabelsDisplayEnum';
import useIsMobile from '../../hooks/useIsMobile';
import { CampaignTypeEnum } from '../../helpers/CampaignTypeEnum';
import { CampaignPreviewDTO } from '../../dto/CampaignPreviewDTO';
import { AxiosResponse } from 'axios';
import { prettifyNumbers } from '../../helpers/StringsHelper';

const CampaignPreview: React.FC = () => {
    const {
        navigateReferrer,
        setCampaignViewEnum,
        campaignStateDataDTO,
        setCampaignStateDataDTO,
        campaignPreviewDTO,
        setCampaignPreviewDTO,
        setCurrentProgressStepIndex,
    } = useCampaignContext();
    const { isLoggedIn } = useAuthContext();
    const [isAuthModalOpen, setIsAuthModalOpen] = useState<boolean>(false);
    const { isLoading, setIsLoading } = useIsLoading();
    const { loadingMessage, setLoadingMessage } = useLoadingMessage();
    const { isMobile } = useIsMobile();
    const [authLinkLabelsDisplayEnum, setAuthLinkLabelsDisplayEnum] = useState<AuthLinkLabelsDisplayEnum>(AuthLinkLabelsDisplayEnum.LOGIN);

    const [campaignPreviewViewStepsEnum, setCampaignPreviewViewStepsEnum] = useState<CampaignPreviewViewStepsEnum>(CampaignPreviewViewStepsEnum.First);

    useEffect(() => {
        const handleViewPreview = async () => {
            try {
                if (campaignStateDataDTO?.CampaignType === CampaignTypeEnum.Comments ||
                    campaignStateDataDTO?.CampaignType === CampaignTypeEnum.Reposts) {
                    if (campaignStateDataDTO?.Api === YesNoEnum.No) {

                        setIsLoading(true);
                        setLoadingMessage(LoadingMessageEnum.Message + "\n Be patient please <3");

                        const fetchPostWithRetry = async (url: string, params: any, retries = apiNoFetchMaxRetry, delay = apiNoFetchExceptionDelayRequestsMs): Promise<AxiosResponse> => {
                            try {
                                const response = await axiosInstance.get(url,
                                    {
                                        params,
                                        authNeeded: false
                                    } as CustomAxiosRequestConfig
                                );
                                return response;
                            } catch (error) {
                                if (retries > 0) {
                                    await new Promise(resolve => setTimeout(resolve, delay));

                                    return fetchPostWithRetry(url, params, retries - 1, delay * 2);
                                }
                                else {
                                    throw error;
                                }
                            }
                        };

                        while (true) {
                            const response = await fetchPostWithRetry(
                                ApiRoutesEnum.CAMPAIGNS_FETCH_GET_POST,
                                {
                                    serviceConfigurationKey: "",
                                    socialPlatform: campaignStateDataDTO?.SocialPlatform,
                                    url: campaignStateDataDTO?.Url
                                }
                            );

                            const CampaignPreviewDTO: CampaignPreviewDTO = {
                                Username: response?.data?.response?.username as string,
                                ProfileImage: response?.data?.response?.profileImage as string,
                                PostDescription: response?.data?.response?.postDescription as string,
                                PostImage: response?.data?.response?.postImage as string,
                                ParticipantsCount: response?.data?.response?.commentsCount as number,
                                ViewsCount: response?.data?.response?.viewsCount as number,
                                IsAvailable: response?.data?.response?.isAvailable as boolean
                            };

                            if (CampaignPreviewDTO?.ParticipantsCount !== null) {

                                CampaignPreviewDTO.ParticipantsCountDisplay = prettifyNumbers(CampaignPreviewDTO.ParticipantsCount);
                            }

                            if (!CampaignPreviewDTO?.IsAvailable) {
                                notificationService.setMessage({
                                    message: campaignStateDataDTO?.IsNotAvailableMessage ?? "",
                                    status: false
                                });

                                navigateReferrer();

                                return;
                            }

                            setCampaignPreviewDTO(CampaignPreviewDTO);

                            setCampaignStateDataDTO((prevState: CampaignStateDataDTO | null) => {
                                if (!prevState) return null;

                                return {
                                    ...prevState,
                                    Username: CampaignPreviewDTO?.Username,
                                    Description: CampaignPreviewDTO?.PostDescription,
                                    ParticipantsCount: Number(CampaignPreviewDTO?.ParticipantsCount) ?? 0,
                                    ParticipantsCountDisplay: CampaignPreviewDTO?.ParticipantsCountDisplay ?? "0"
                                };
                            });

                            break;
                        }

                        setCampaignPreviewViewStepsEnum(CampaignPreviewViewStepsEnum.First);

                        setIsLoading(false);

                        return;
                    }
                }
                else if (campaignStateDataDTO?.CampaignType === CampaignTypeEnum.List) {
                    setCampaignPreviewViewStepsEnum(CampaignPreviewViewStepsEnum.First);

                    setIsLoading(false);

                    return;
                }
            }
            catch (error: any) {
                notificationService.setMessage({
                    message: 'Failed to display preview, try again.',
                    status: false
                });

                loggerService.error(error);

                setIsLoading(false);

                navigateReferrer();
            }
        };

        const init = async () => {
            await handleViewPreview();
        };

        init();
    }, []);

    const handleNavigateCampaignState = async () => {
        if (campaignStateDataDTO) {
            if (!(await isValidAuth())) {
                return;
            }

            setCurrentProgressStepIndex(1);
            setCampaignViewEnum(CampaignViewEnum.Settings);
        }
    };

    const isValidAuth = async (): Promise<boolean> => {
        if (!(await isLoggedIn())) {
            setIsAuthModalOpen(true);

            setIsLoading(false);

            return false;
        }

        return true;
    };

    return (
        <>
            {isLoading ? (
                <>
                    <CampaignLoading
                        loadingMessage={loadingMessage}
                    />
                </>
            ) : (
                <>
                    <div className="mt-5 p-4 w-full max-w-campaignPreview bg-white shadow-md rounded">

                        {campaignStateDataDTO?.Api === YesNoEnum.No &&
                            (
                                <>
                                    <div className="mb-5 text-right">
                                        <CloseButton
                                            text="Back"
                                            onClick={() => { navigateReferrer(); }}
                                        />
                                    </div>

                                    {campaignPreviewViewStepsEnum === CampaignPreviewViewStepsEnum.First && (
                                        <>
                                            <div className={`${isMobile ? "w-full" : "flex"}`}>
                                                {campaignPreviewDTO?.PostImage && (
                                                    <div className={`${isMobile ? "w-2/2" : "w-1/2"} p-4`}>
                                                        <img src={`${campaignPreviewDTO?.PostImage}`} alt={`${campaignStateDataDTO.Username}`} className="w-full h-auto object-cover max-w-md" />
                                                    </div>
                                                )}
                                                <div className={`${isMobile ? "w-2/2" : "w-1/2"} p-4 mx-auto`}>
                                                    {campaignPreviewDTO?.ProfileImage && (
                                                        <div className="mb-3 text-center">
                                                            <img
                                                                alt={campaignPreviewDTO?.Username}
                                                                src={campaignPreviewDTO?.ProfileImage}
                                                                className="rounded-full w-12 h-full mx-auto"
                                                            />
                                                        </div>
                                                    )}
                                                    <div className="mb-3 text-center">
                                                        <h4 className="items-center">
                                                            <FontAwesomeIcon icon={campaignStateDataDTO?.SocialPlatformIcon ?? faQuestion} className={`mr-2 ${campaignStateDataDTO.SocialPlatformClassName}`} />
                                                            {campaignStateDataDTO.Username}
                                                        </h4>
                                                    </div>
                                                    {campaignPreviewDTO?.PostDescription && (
                                                        <div className="mb-3 text-center">
                                                            <p className='items-center'>
                                                                <FontAwesomeIcon icon={faQuoteLeft} className={`mr-2 ${campaignStateDataDTO.SocialPlatformClassName}`} />
                                                                {campaignPreviewDTO?.PostDescription}
                                                            </p>
                                                        </div>
                                                    )}
                                                    {campaignPreviewDTO?.ViewsCount && (
                                                        <div className="mb-3 text-center">
                                                            <p className='items-center'>
                                                                <FontAwesomeIcon icon={faEye} className={`mr-2 ${campaignStateDataDTO.SocialPlatformClassName}`} />
                                                                {campaignPreviewDTO?.ViewsCount}
                                                            </p>
                                                        </div>
                                                    )}
                                                    <div className="mb-3 text-center">
                                                        <span className="items-center">
                                                            <FontAwesomeIcon icon={campaignStateDataDTO?.CommentsSocialIcon ?? faQuestion} className={`mr-2 ${campaignStateDataDTO.SocialPlatformClassName}`} />
                                                            {campaignStateDataDTO?.ParticipantsCountDisplay}
                                                        </span>
                                                    </div>
                                                    <div className="mb-0 text-center">
                                                        <button
                                                            disabled={(campaignPreviewDTO?.ParticipantsCount &&
                                                                campaignPreviewDTO?.ParticipantsCount < minParticipantsCount) ?
                                                                true :
                                                                campaignPreviewDTO?.ParticipantsCount && campaignPreviewDTO?.ParticipantsCount > maxParticipantsCount ?
                                                                    true :
                                                                    false}
                                                            onClick={async () => { await handleNavigateCampaignState(); }}
                                                            className={`${(campaignPreviewDTO?.ParticipantsCount && campaignPreviewDTO?.ParticipantsCount < minParticipantsCount) ? getButtonDisabledStyleClass(false) :
                                                                campaignPreviewDTO?.ParticipantsCount && campaignPreviewDTO?.ParticipantsCount > maxParticipantsCount ?
                                                                    getButtonDisabledStyleClass(false) :
                                                                    getButtonSuccessStyleClass(false)} w-full mb-3 max-w-md`}>
                                                            {(campaignPreviewDTO?.ParticipantsCount && campaignPreviewDTO?.ParticipantsCount < minParticipantsCount) ?
                                                                `Need more ${campaignStateDataDTO?.CommentsDisplayLabel}...` :
                                                                campaignPreviewDTO?.ParticipantsCount && campaignPreviewDTO?.ParticipantsCount > maxParticipantsCount ?
                                                                    `Maximum participants allowed ${prettifyNumbers(maxParticipantsCount)}...` :
                                                                    `Load ${campaignStateDataDTO?.CommentsDisplayLabel}`}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}

                                </>
                            )}

                    </div>

                    <AuthModal isOpen={isAuthModalOpen} onClose={() => setIsAuthModalOpen(false)}>
                        {authLinkLabelsDisplayEnum === AuthLinkLabelsDisplayEnum.LOGIN ? (
                            <LoginAuth
                                isAuthModal={true}
                                setIsModalOpen={setIsAuthModalOpen}
                                setAuthLinkLabelsDisplayEnum={setAuthLinkLabelsDisplayEnum}
                                authLinkLabelsDisplayEnum={authLinkLabelsDisplayEnum}
                            />
                        ) :
                            <SignupAuth
                                isAuthModal={true}
                                setIsModalOpen={setIsAuthModalOpen}
                                setAuthLinkLabelsDisplayEnum={setAuthLinkLabelsDisplayEnum}
                                authLinkLabelsDisplayEnum={authLinkLabelsDisplayEnum}
                            />
                        }
                    </AuthModal>
                </>
            )}
        </>
    );
};

export default CampaignPreview;