import React, { useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import axiosInstance from '../config/axiosInstance';
import notificationService from '../../services/NotificationService';
import Layout from '../../components/Layout';
import { Helmet } from 'react-helmet';
import { ApiRoutesEnum as ApiRoutes } from '../../routes/ApiRoutesEnum';
import { RoutesEnum as AppRoutes } from '../../routes/RoutesEnum';
import useLoadRecaptcha from '../../hooks/useLoadRecaptcha';
import { useAuthContext } from '../contexts/AuthContext';
import { useIsLoading } from '../../hooks/useIsLoading';
import { useLoadingMessage } from '../../hooks/useLoadingMessage';
import { LoadingMessageEnum as LoadingMessage } from '../../helpers/LoadingMessageEnum';
import { getStandardCardBackground } from '../../config/FormSettings';

const SetEmailVerify: React.FC = () => {
  const { login } = useAuthContext();
  const { token, newEmail } = useParams<{ token: string; newEmail?: string }>();
  const navigate = useNavigate();
  const isRecaptchaLoaded = useLoadRecaptcha();
  const { isLoading, setIsLoading } = useIsLoading();
  const { loadingMessage, setLoadingMessage } = useLoadingMessage();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        setLoadingMessage(LoadingMessage.Message);

        if (!isRecaptchaLoaded) {
          setLoadingMessage('Loading reCAPTCHA...');
          return;
        }

        let recaptchaToken;
        try {
          recaptchaToken = await window.grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY as string, { action: 'submit' });
        } catch (error) {
          notificationService.setMessage({
            message: 'reCAPTCHA failed. Please try again.',
            status: false
          });

          setIsLoading(false);

          return;
        }

        const formData = {
          Token: token,
          RecaptchaToken: recaptchaToken,
          ...(newEmail && { NewEmail: newEmail }),
        };

        const response = await axiosInstance.post(
          ApiRoutes.SET_EMAIL_VERIFY,
          formData);

        const { status, message, jwtToken, refreshToken } = response.data;

        await login(jwtToken, refreshToken);

        notificationService.setMessage({
          message: message,
          status: status
        });

        setIsLoading(false);

        navigate(AppRoutes.DASHBOARD);
      } catch (error: any) {
        notificationService.setError(error);

        setIsLoading(false);

        navigate(AppRoutes.HOME);
      }
    };

    fetchData();
  }, [isRecaptchaLoaded]);

  return (
    <Layout>
      <Helmet>
        <title>socialspicker.com - Verifying Account</title>
      </Helmet>
      <Link to={AppRoutes.HOME} className="mb-8">
        <img src="/assets/logoBlack.svg" alt="Logo" className="w-full h-auto max-w-3xs" />
      </Link>
      <div className={`${getStandardCardBackground()}`}>
        <h2 className="text-2xl font-bold mb-6">Verifying your account</h2>
        <div className="mt-4 text-center">
          {isLoading && (loadingMessage)}
        </div>
      </div>
    </Layout>
  );
};

export default SetEmailVerify;