import { createContext, useState, useContext, ReactNode } from 'react';
import { WinnerDTO } from '../dto/WinnerDTO';
import { CampaignViewEnum } from '../helpers/CampaignViewEnum';
import { CampaignSettingsDTO } from '../dto/CampaignSettingsDTO';
import { useNavigate } from 'react-router-dom';
import { CampaignStateDataDTO } from '../dto/CampaignStateDataDTO';
import { CampaignPreviewDTO } from '../dto/CampaignPreviewDTO';
import { removeCampaignStatePersistent } from '../helpers/CampaignHelper';
import { CampaignModel } from '../models/CampaignModel';

interface CampaignContextProps {
    unmountCampaignRevertDefaults: () => void;
    campaignParticipants: WinnerDTO[] | null;
    setCampaignParticipants: (value: WinnerDTO[] | null | ((prevParticipants: WinnerDTO[] | null) => WinnerDTO[])) => void;
    filteredCampaignParticipants: WinnerDTO[] | null;
    setFilteredCampaignParticipants: (value: WinnerDTO[] | null) => void;
    isSettingsOpened: boolean;
    setIsSettingsOpened: (value: boolean) => void;
    campaignWinners: WinnerDTO[] | null;
    setCampaignWinners: (value: WinnerDTO[] | null | ((prevState: WinnerDTO[] | null) => WinnerDTO[] | null)) => void;
    campaignSubstitutes: WinnerDTO[] | null;
    setCampaignSubstitutes: (value: WinnerDTO[] | null | ((prevState: WinnerDTO[] | null) => WinnerDTO[] | null)) => void;
    campaignViewEnum: CampaignViewEnum | null;
    setCampaignViewEnum: (value: CampaignViewEnum | null) => void;
    campaignSettingsDTO: CampaignSettingsDTO | null;
    setCampaignSettingsDTO: (value: CampaignSettingsDTO | null) => void;
    isSavingSettings: boolean;
    setIsSavingSettings: (value: boolean) => void;
    isSavingCampaign: boolean;
    setIsSavingCampaign: (value: boolean) => void;
    setIsCampaignOwner: (value: boolean) => void;
    isCampaignOwner: boolean;
    setCampaign: (value: CampaignModel | null) => void;
    campaign: CampaignModel | null;
    navigateReferrer: () => void;
    campaignStateDataDTO: CampaignStateDataDTO | null;
    setCampaignStateDataDTO: (value: CampaignStateDataDTO | null | ((prevState: CampaignStateDataDTO | null) => CampaignStateDataDTO | null)) => void;
    campaignPreviewDTO: CampaignPreviewDTO | null;
    setCampaignPreviewDTO: (value: CampaignPreviewDTO | null | ((prevState: CampaignPreviewDTO | null) => CampaignPreviewDTO | null)) => void;
    setCurrentProgressStepIndex: (value: number) => void;
    currentProgressStepIndex: number;
    winners: number;
    setWinners: React.Dispatch<React.SetStateAction<number>>;
    substitutes: number;
    setSubstitutes: React.Dispatch<React.SetStateAction<number>>;
    eliminateDuplicates: boolean;
    setEliminateDuplicates: React.Dispatch<React.SetStateAction<boolean>>;
    filterByMention: boolean;
    setFilterByMention: React.Dispatch<React.SetStateAction<boolean>>;
    mention: string;
    setMention: React.Dispatch<React.SetStateAction<string>>;
    filterByHashtag: boolean;
    setFilterByHashtag: React.Dispatch<React.SetStateAction<boolean>>;
    hashTag: string;
    setHashtag: React.Dispatch<React.SetStateAction<string>>;
    countdown: number;
    setCountdown: React.Dispatch<React.SetStateAction<number>>;
}

const CampaignContext = createContext<CampaignContextProps | undefined>(undefined);

const CampaignProvider = ({ children }: { children: ReactNode }) => {
    const navigate = useNavigate();

    const [campaignViewEnum, setCampaignViewEnum] = useState<CampaignViewEnum | null>(null);
    const [campaignSettingsDTO, setCampaignSettingsDTO] = useState<CampaignSettingsDTO | null>(null);
    const [isSavingSettings, setIsSavingSettings] = useState<boolean>(false);
    const [isSavingCampaign, setIsSavingCampaign] = useState<boolean>(false);
    const [isSettingsOpened, setIsSettingsOpened] = useState<boolean>(true);
    const [campaignParticipants, setCampaignParticipants] = useState<WinnerDTO[] | null>(null);
    const [campaignWinners, setCampaignWinners] = useState<WinnerDTO[] | null>(null);
    const [campaignSubstitutes, setCampaignSubstitutes] = useState<WinnerDTO[] | null>(null);
    const [filteredCampaignParticipants, setFilteredCampaignParticipants] = useState<WinnerDTO[] | null>(null);
    const [campaignStateDataDTO, setCampaignStateDataDTO] = useState<CampaignStateDataDTO | null>(null);
    const [campaignPreviewDTO, setCampaignPreviewDTO] = useState<CampaignPreviewDTO | null>(null);
    const [currentProgressStepIndex, setCurrentProgressStepIndex] = useState<number>(0);
    const [isCampaignOwner, setIsCampaignOwner] = useState<boolean>(false);
    const [campaign, setCampaign] = useState<CampaignModel | null>(null);

    //settings
    const [winners, setWinners] = useState<number>(1);
    const [substitutes, setSubstitutes] = useState<number>(1);
    const [eliminateDuplicates, setEliminateDuplicates] = useState<boolean>(false);
    const [filterByMention, setFilterByMention] = useState<boolean>(false);
    const [mention, setMention] = useState<string>('');
    const [filterByHashtag, setFilterByHashtag] = useState<boolean>(false);
    const [hashTag, setHashtag] = useState<string>('');
    const [countdown, setCountdown] = useState<number>(10);
    //settings - end

    const unmountCampaignRevertDefaults = () => {
        setCampaignViewEnum(null);
        setCampaignSettingsDTO(null);
        setIsSavingSettings(false);
        setIsSavingCampaign(false);
        setIsSettingsOpened(true);
        setCampaignParticipants(null);
        setCampaignWinners(null);
        setCampaignSubstitutes(null);
        setFilteredCampaignParticipants(null);
        setCampaignStateDataDTO(null);
        setCampaignPreviewDTO(null);
        setCurrentProgressStepIndex(0);
        setIsCampaignOwner(false);
        setCampaign(null);

        //settings
        setWinners(1);
        setSubstitutes(1);
        setEliminateDuplicates(false);
        setFilterByMention(false);
        setMention('');
        setFilterByHashtag(false);
        setHashtag('');
        setCountdown(10);
        //settings - end

        removeCampaignStatePersistent();
    }

    const navigateReferrer = () => {
        unmountCampaignRevertDefaults();

        if (campaignStateDataDTO?.Referrer) {
            navigate(campaignStateDataDTO.Referrer);
        }
    }

    return (
        <CampaignContext.Provider value={{
            unmountCampaignRevertDefaults,
            campaignParticipants,
            setCampaignParticipants,
            filteredCampaignParticipants,
            setFilteredCampaignParticipants,
            campaignWinners,
            setCampaignWinners,
            campaignSubstitutes,
            setCampaignSubstitutes,
            campaign,
            setCampaign,
            campaignViewEnum,
            setCampaignViewEnum,
            campaignSettingsDTO,
            setCampaignSettingsDTO,
            isSavingSettings,
            setIsSavingSettings,
            isSavingCampaign,
            setIsSavingCampaign,
            isSettingsOpened,
            setIsSettingsOpened,
            navigateReferrer,
            campaignStateDataDTO,
            setCampaignStateDataDTO,
            campaignPreviewDTO,
            setCampaignPreviewDTO,
            isCampaignOwner,
            setIsCampaignOwner,
            currentProgressStepIndex,
            setCurrentProgressStepIndex,
            winners,
            setWinners,
            substitutes,
            setSubstitutes,
            eliminateDuplicates,
            setEliminateDuplicates,
            filterByMention,
            setFilterByMention,
            mention,
            setMention,
            filterByHashtag,
            setFilterByHashtag,
            hashTag,
            setHashtag,
            countdown,
            setCountdown
        }}>
            {children}
        </CampaignContext.Provider>
    );
};

export const useCampaignContext = () => {
    const context = useContext(CampaignContext);
    if (!context) {
        throw new Error('useCampaignContext must be used within a CampaignProvider');
    }
    return context;
};

export default CampaignProvider;