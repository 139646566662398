import { NotificationItem } from "../dto/NotificationItem";

interface NotificationMethods {
  setMessage: (notification: NotificationItem) => void;
  removeMessage: (index: number) => void;
  subscribe: (listener: (messages: NotificationItem[]) => void) => void;
  unsubscribe: () => void;
}

class NotificationService implements NotificationMethods {
  private static instance: NotificationService | null = null;

  private messages: NotificationItem[] = [];
  private messageSubscriber: ((messages: NotificationItem[]) => void) | null = null;

  // Private constructor prevents direct instantiation
  private constructor() { }

  // Public method to get the singleton instance
  public static getInstance(): NotificationService {
    if (!NotificationService.instance) {
      NotificationService.instance = new NotificationService();
    }
    return NotificationService.instance;
  }

  public setMessage = (notification: NotificationItem): void => {
    this.messages.push(notification);
    this.notifySubscriber();
  };

  public setError = (error: any): void => {
    if (error && error?.response && error.response?.data && error.response.data?.message && error.response.data?.status != null) {
      const notification: NotificationItem = {
        message: error.response.data.message,
        status: error.response.data.status,
      };

      this.messages.push(notification);
      this.notifySubscriber();
    }
  };

  public removeMessage = (index: number): void => {
    this.messages.splice(index, 1);
    this.notifySubscriber();
  };

  public subscribe = (listener: (messages: NotificationItem[]) => void): void => {
    this.messageSubscriber = listener;
    if (this.messages.length > 0) {
      listener([...this.messages]); // Notify immediately with existing messages
    }
  };

  public unsubscribe = (): void => {
    this.messageSubscriber = null;
  };

  private notifySubscriber = (): void => {
    if (this.messageSubscriber) {
      this.messageSubscriber([...this.messages]);
    }
  };
}

export default NotificationService.getInstance();