import React, { ReactNode } from 'react';
import useIsMobile from '../hooks/useIsMobile';
import { getMainCardBackground } from '../config/FormSettings';

interface LayoutContentProps {
  children: ReactNode;
}

const LayoutContent: React.FC<LayoutContentProps> = ({ children }) => {
  const { isMobile } = useIsMobile();

  return (
    <>
      <div className={`w-full container mx-auto`}>
        <div className={`${getMainCardBackground(isMobile)}`}>
          {children}
        </div>
      </div>
    </>
  );
};

export default LayoutContent;