import React, { useEffect } from 'react';
import axiosInstance, { CustomAxiosRequestConfig } from './axiosInstance';
import { ApiRoutesEnum as ApiRoutes } from '../../routes/ApiRoutesEnum';
import { useAuthContext } from '../contexts/AuthContext';

const AxiosInterceptorSetup: React.FC = () => {
  const { login, logout, getToken, getRefreshToken } = useAuthContext();

  useEffect(() => {
    // Set up request interceptor
    const requestInterceptor = axiosInstance.interceptors.request.use(
      async (config) => {
        const token = await getToken();
        if ((config as CustomAxiosRequestConfig).authNeeded && token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    // Set up response interceptor
    const responseInterceptor = axiosInstance.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        const originalRequest = error.config;
        originalRequest._reject = false;

        if (error.response && error.response.status === 401) {
          // If the request is for the login endpoint, do not attempt a refresh or logout
          if (originalRequest.url.includes(ApiRoutes.LOGIN)) {
            return Promise.reject(error);
          }

          // If the refresh token request itself fails, log out the user
          if (originalRequest.url.includes(ApiRoutes.REFRESH)) {
            await logout();

            return Promise.reject(error);
          }

          if (!originalRequest._retry && !originalRequest._reject && originalRequest._reject === false) {
            originalRequest._retry = true;

            try {
              const refreshToken = await getRefreshToken();

              // Check if refreshToken exists before attempting to refresh the token
              if (!refreshToken) {
                await logout();

                originalRequest._reject = true;
              }

              const response = await axiosInstance.post(ApiRoutes.REFRESH, { refreshToken });

              // If the refresh token request fails, log out the user
              if (response.status === 401) {
                await logout();

                originalRequest._reject = true;
              }

              const { token, refreshToken: newRefreshToken } = response.data;
              await login(token, newRefreshToken);
              axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
              originalRequest.headers['Authorization'] = `Bearer ${token}`;

              return axiosInstance(originalRequest);
            } catch (err) {
              await logout();

              originalRequest._reject = true;
            }
          }
          else {
            await logout();

            originalRequest._reject = true;
          }
        }
        else {
          originalRequest._reject = true;
        }

        if (originalRequest._reject) {
          return Promise.reject(error);
        }
      }
    );

    return () => {
      axiosInstance.interceptors.request.eject(requestInterceptor);
      axiosInstance.interceptors.response.eject(responseInterceptor);
    };
  }, []);

  return null;
};

export default AxiosInterceptorSetup;