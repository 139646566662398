import React, { useEffect, useState } from 'react';
import { getButtonDisabledStyleClass, getButtonSuccessStyleClass, getStandardCardBackground } from '../../config/FormSettings';
import { useCampaignContext } from '../../contexts/CampaignContext';
import notificationService from '../../services/NotificationService';
import { CampaignModel } from '../../models/CampaignModel';
import axiosInstance, { CustomAxiosRequestConfig } from '../../auth/config/axiosInstance';
import { ApiRoutesEnum } from '../../routes/ApiRoutesEnum';
import { useAuthContext } from '../../auth/contexts/AuthContext';
import { AxiosResponse } from 'axios';
import { defaultMaxRetry, defaultWaitBetweenRequestsMs } from '../../config/envConfig';
import CloseButton from '../buttons/CloseButton';
import { MaxWEnum } from '../../helpers/FormEnum';
import { CampaignViewEnum } from '../../helpers/CampaignViewEnum';
import { useNavigate } from 'react-router-dom';
import { RoutesEnum } from '../../routes/RoutesEnum';
import { prettifyNumbers } from '../../helpers/StringsHelper';
import CampaignLoading from '../loadings/CampaignLoading';
import ConfirmCampaignModal from '../modals/ConfirmCampaignModal';
import { getParticipantCreditPackage } from '../../helpers/CampaignHelper';
import Credits from '../Credits';

const CampaignCredits: React.FC = () => {
    const {
        campaignSettingsDTO,
        setCampaignViewEnum,
        campaignStateDataDTO,
        setIsSavingCampaign,
        setCurrentProgressStepIndex,
        isSavingCampaign,
        campaignWinners,
        campaignSubstitutes,
    } = useCampaignContext();
    const { userData, getUserDataFromToken, isPolingPaymentFired } = useAuthContext();
    const [isLoadingCredits, setIsLoadingCredits] = useState<boolean>(false);
    const [hasCreditsAvailable, setHasCreditsAvailable] = useState<boolean>(false);
    const [isConfirmCampaignModal, setIsConfirmCampaignModal] = useState<boolean>(false);
    const navigate = useNavigate();

    useEffect(() => {
        const init = async () => {
            setIsLoadingCredits(true);

            const user = await getUserDataFromToken(navigate, true);

            if (user && campaignStateDataDTO && user?.Credits >= campaignStateDataDTO?.ParticipantsCount) {
                setHasCreditsAvailable(true);
            }

            setIsLoadingCredits(false);
        }

        init();
    }, [isPolingPaymentFired]);

    useEffect(() => {
        const init = async () => {
            await saveCampaign();

            await getUserDataFromToken(navigate, true);
        }

        if (isSavingCampaign) {
            init();
        }
    }, [isSavingCampaign]);

    const saveCampaign = async () => {
        try {
            setIsSavingCampaign(true);

            //we must make sure the state is always set
            if (campaignStateDataDTO && campaignSettingsDTO && userData) {

                const campaignModel: CampaignModel = {
                    Title: campaignSettingsDTO.Title,
                    SocialPlatform: campaignStateDataDTO.SocialPlatform,
                    CampaignType: campaignStateDataDTO.CampaignType,
                    Author: campaignStateDataDTO?.Username,
                    Post: campaignStateDataDTO?.Url,
                    ParticipantsCount: campaignStateDataDTO.ParticipantsCount,
                    UserId: userData.Id,
                    Description: campaignStateDataDTO?.Description,
                }

                const formData = {
                    campaignDto: campaignModel,
                    settingsDto: campaignSettingsDTO,
                    winnerDto: [
                        ...(campaignWinners || []),
                        ...(campaignSubstitutes || [])
                    ]
                };

                const saveCampaignRetry = async (retries = defaultMaxRetry, delay = defaultWaitBetweenRequestsMs): Promise<AxiosResponse> => {
                    try {
                        const response = await axiosInstance.post(
                            ApiRoutesEnum.CREATE_CAMPAIGN,
                            formData,
                            { authNeeded: true } as CustomAxiosRequestConfig);
                        return response;
                    } catch (error: any) {
                        if (error.response && error.response.status === 403) {
                            throw error;
                        } else if (retries > 0) {
                            await new Promise(resolve => setTimeout(resolve, delay));
                            return saveCampaignRetry(retries - 1, delay);
                        } else {
                            notificationService.setMessage({
                                message: "Failed while creating the campaign, try again",
                                status: false,
                            });

                            throw error;
                        }
                    }
                };

                const response: any = await saveCampaignRetry();

                setIsSavingCampaign(false);

                navigate(RoutesEnum.DASHBOARD);
            }
            else {
                throw new Error();
            }
        }
        catch (error: any) {
            notificationService.setError(error);

            setIsSavingCampaign(false);
        }
    };

    return (
        <>
            {isSavingCampaign ? (
                <>
                    <CampaignLoading
                        loadingMessage={"Saving campaign... please wait"}
                    />
                </>
            ) : (
                <>
                    <div className={`${getStandardCardBackground(true, MaxWEnum.None, "", "my-2", true)}`}>
                        <div className="mb-2 mt-2 text-right float-right">
                            {!isSavingCampaign && (
                                <CloseButton
                                    text="Back"
                                    onClick={() => {
                                        setCurrentProgressStepIndex(1);
                                        setCampaignViewEnum(CampaignViewEnum.Settings);
                                    }}
                                />
                            )}
                        </div>
                    </div>

                    <div className={`${getStandardCardBackground(true, MaxWEnum.None, "", "my-2", true)} `}>
                        <div className="flex flex-col w-full my-4 text-center">
                            <div className="font-semibold p-2 rounded-md mb-2">
                                There are {campaignStateDataDTO?.ParticipantsCountDisplay} participants found
                            </div>
                            {hasCreditsAvailable && (
                                <>
                                    <div className={`bg-successBackground text-success font-semibold p-4 rounded-md mb-2`}>
                                        {isLoadingCredits ? (
                                            "Loading credits..."
                                        ) : (
                                            `You have ${userData?.CreditsDisplay} Participant Credits available`
                                        )}
                                    </div>
                                    <div>
                                        {prettifyNumbers(getParticipantCreditPackage(campaignStateDataDTO?.ParticipantsCount ?? 0)?.ParticipantCredits)} Participant Credits will be deducted from your account
                                    </div>
                                </>
                            )}
                            {!hasCreditsAvailable && (
                                <>
                                    <div className='p-2 m-2 items-center text-center'>
                                        <div className='bg-error text-white p-2 mb-2 rounded shadow-md'>
                                            Not enough credits, you need {prettifyNumbers(getParticipantCreditPackage(campaignStateDataDTO?.ParticipantsCount ?? 0)?.ParticipantCredits)} Participant Credits
                                        </div>
                                        <div className='flex justify-center items-center'>
                                            <Credits
                                                showNotifications={false}
                                            />
                                        </div>
                                    </div>
                                </>
                            )}
                            <div className="p-4 rounded-md mb-2">
                                <button
                                    type="button"
                                    disabled={isLoadingCredits || !hasCreditsAvailable}
                                    onClick={() => setIsConfirmCampaignModal(true)}
                                    className={`w-full ${(isLoadingCredits || !hasCreditsAvailable) ? getButtonDisabledStyleClass(true) : getButtonSuccessStyleClass(true)}`}>
                                    {isLoadingCredits ?
                                        "Loading credits..." :
                                        !hasCreditsAvailable ?
                                            "Not enough credits..." :
                                            "Start"}
                                </button>
                            </div>
                        </div>
                    </div>
                </>
            )}

            <ConfirmCampaignModal
                isOpen={isConfirmCampaignModal}
                onClose={() => setIsConfirmCampaignModal(false)}
                setValue={() => setIsSavingCampaign(true)} />
        </>
    );
};

export default CampaignCredits;