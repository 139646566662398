import React, { useState } from 'react';
import { getButtonPrimaryStyleClass, getStandardCardBackground, getTextLinkStyleClass } from '../config/FormSettings';
import { MaxWEnum } from '../helpers/FormEnum';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';

const StickyFollowButton: React.FC = () => {
  const [displayElement, setDisplayElement] = useState<boolean>(true);

  const element = localStorage.getItem("StickyFollowButton");

  const handleElement = () => {
    localStorage.setItem("StickyFollowButton", "hidden");

    setDisplayElement(false);
  }

  if (!displayElement || element === "hidden")
    return null;

  return (
    <div className="flex items-center justify-center">
      <div className={`${getStandardCardBackground(true, MaxWEnum.MaxWSm, "p-2", "mb-2", false)} bottom-0 fixed`}>
        <div className='grid grid-cols-[auto,1fr,auto,auto] gap-2 items-center'>
          <div>
            <img alt='logo' src='/assets/logo192.png' className='rounded w-full max-w-[35px] mx-auto' />
          </div>
          <div className='mx-auto'>
            <Link to="https://instagram.com/socialspicker" target='_blank' className={`${getTextLinkStyleClass()} mx-auto`}>
              socialspicker
            </Link>
          </div>
          <div className='mx-auto'>
            <Link to="https://instagram.com/socialspicker" target='_blank' className={getButtonPrimaryStyleClass(false)}>
              Follow Us
            </Link>
          </div>
          <div onClick={handleElement} className='text-center max-w-[20px] mx-auto ml-1'>
            <FontAwesomeIcon className='cursor-pointer' icon={faClose} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StickyFollowButton;