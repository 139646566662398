import React, { useEffect, useRef } from 'react';
import CloseButton from '../buttons/CloseButton';
import { getButtonErrorStyleClass, getButtonSuccessStyleClass } from '../../config/FormSettings';
import { useCampaignContext } from '../../contexts/CampaignContext';
import { CampaignViewEnum } from '../../helpers/CampaignViewEnum';

interface ConfirmCampaignModalProps {
  isOpen: boolean;
  onClose: () => void;
  setValue: () => void;
}

const ConfirmCampaignModal: React.FC<ConfirmCampaignModalProps> = ({ isOpen, onClose, setValue }) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const {
    campaignSettingsDTO,
    campaignViewEnum,
  } = useCampaignContext();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div
      className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 overflow-auto">
      <div
        ref={modalRef}
        className="relative bg-white p-6 rounded shadow-lg w-full max-w-lg mx-4 sm:mx-0 max-h-[90vh] overflow-y-auto">
        <div className='flex items-center justify-center absolute top-2 right-2'>
        </div>
        <div className="flex flex-col w-full my-4 text-center">
          <div className="font-semibold p-2 rounded-md mb-2">
            Are you ready to start the campaign "{campaignSettingsDTO?.Title}"?
          </div>
          <div className='p-1'>
            {campaignViewEnum === CampaignViewEnum.Settings && (
              <>
                Your campaign will run in background, we will notify you once is ready by email or you can check the status in My Campaigns
              </>
            )}
            {campaignViewEnum === CampaignViewEnum.SettingsEdit && (
              <>
                Countdown will start once you start, GOOD LUCK to your participants!
              </>
            )}
          </div>
          <div className="p-4 rounded-md mb-2">
            <div className="px-5 mt-4 text-center">
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-2">
                <CloseButton
                  text="Close"
                  onClick={onClose}
                />
                <button
                  type="button"
                  onClick={() => { setValue(); onClose() }}
                  className={`w-full ${getButtonSuccessStyleClass(true)}`}>
                  Start
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmCampaignModal;