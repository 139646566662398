import { useNavigate, useParams } from 'react-router-dom';
import Layout from '../components/Layout';
import { Helmet } from 'react-helmet';
import { useEffect, useState } from 'react';
import axiosInstance, { CustomAxiosRequestConfig } from '../auth/config/axiosInstance';
import { ApiRoutesEnum } from '../routes/ApiRoutesEnum';
import NotificationService from '../services/NotificationService';
import { RoutesEnum } from '../routes/RoutesEnum';
import LoadingScreen from '../components/loadings/LoadingScreen';
import { prettifyNumbers } from '../helpers/StringsHelper';
import { UserDataDTO } from '../dto/UserDataDTO';
import { getButtonSuccessStyleClass, getInputTextStyleClass, getStandardCardBackground } from '../config/FormSettings';
import { MaxWEnum } from '../helpers/FormEnum';
import { useAuthContext } from '../auth/contexts/AuthContext';
import { SubmitHandler, useForm } from 'react-hook-form';
import { getDefinedRoutesWithoutParams } from '../helpers/RoutesHelper';
import { paginationSize } from '../config/envConfig';
import { BillingDTO } from '../dto/BillingDTO';
import SPPagination from '../components/SPPagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faDownload, faMoneyBill } from '@fortawesome/free-solid-svg-icons';
import Tooltip from '../components/Tooltip';
import { canSubmit } from '../helpers/RateLimitActionHelper';

interface BillingFormInputs {
  businessName: string;
  businessAddress: string;
  businessTaxId: string;
}

const Billing: React.FC = () => {
  const { register, handleSubmit, formState: { errors }, setValue } = useForm<BillingFormInputs>();
  const navigate = useNavigate();
  const { getUserDataFromToken } = useAuthContext();
  const [isProfileLoading, setIsProfileLoading] = useState<boolean>(false);
  const [profile, setProfile] = useState<UserDataDTO | null>(null);
  const [isLoadingBusinessInfo, setIsLoadingBusinessInfo] = useState<boolean>(false);
  const [isDownloadInvoice, setIsDownloadInvoice] = useState<boolean>(false);
  const [billing, setBilling] = useState<BillingDTO[] | null>(null);
  const [totalCountBilling, setTotalCountBilling] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const pageCount = Math.ceil(totalCountBilling / paginationSize);

  useEffect(() => {
    const getProfileData = async () => {
      try {
        setIsProfileLoading(true);

        const response = await axiosInstance.get(ApiRoutesEnum.GET_USER, { authNeeded: true } as CustomAxiosRequestConfig);

        const user = response.data;

        const profileData: UserDataDTO = {
          Id: user.id,
          Email: user.email,
          Name: user.name,
          Username: user.username,
          Verified: user.verified,
          UserCreatedDateString: user.userCreatedDateString,
          BusinessName: user.businessName,
          BusinessAddress: user.businessAddress,
          BusinessTaxId: user.businessTaxId,
        };

        setProfile(profileData);

        setValue('businessName', profileData?.BusinessName || "");
        setValue('businessAddress', profileData?.BusinessAddress || "");
        setValue('businessTaxId', profileData?.BusinessTaxId || "");

        setIsProfileLoading(false);
      }
      catch (error: any) {
        NotificationService.setError(error);

        setIsProfileLoading(false);
      }
    };

    const init = async () => {
      await getProfileData();
    };

    init();
  }, []);

  useEffect(() => {

    const getBilling = async () => {
      try {
        setIsProfileLoading(true);

        const responseBilling = await axiosInstance.get(ApiRoutesEnum.GET_BILLING_BY_USER,
          {
            authNeeded: true,
            params: {
              pageNumber: currentPage,
              pageSize: paginationSize
            }
          } as CustomAxiosRequestConfig);

        const billing: BillingDTO[] = responseBilling.data.billing
          .map((billing: any) => ({
            Id: billing.id,
            InvoiceId: billing.invoiceId,
            Amount: billing.amount,
            Currency: billing.currency,
            Date: billing.date,
            UserId: billing.userId,
          }));

        setBilling(billing);
        setTotalCountBilling(responseBilling.data.totalCountBilling);

        setIsProfileLoading(false);
      }
      catch (error: any) {
        NotificationService.setError(error);

        setIsProfileLoading(false);
      }
    }

    const init = async () => {
      await getBilling();
    };

    init();
  }, [currentPage]);

  const handlePageChange = (selected: { selected: number }) => {
    setCurrentPage(selected.selected + 1);
  };

  const onSubmit: SubmitHandler<BillingFormInputs> = async (data: any) => {
    const handleSubmitForm = async () => {
      try {
        setIsLoadingBusinessInfo(true);

        //billing info change

        const formData = {
          BusinessName: data.businessName,
          BusinessAddress: data.businessAddress,
          BusinessTaxId: data.businessTaxId,
        };

        const response = await axiosInstance.post(
          ApiRoutesEnum.EDIT_USER,
          formData,
          { authNeeded: true } as CustomAxiosRequestConfig
        );

        const { status, message } = response.data;

        NotificationService.setMessage({
          status: status,
          message: message
        });

        setProfile((prevState: UserDataDTO | null) => {
          if (!prevState) return null;

          return {
            ...prevState,
            BusinessName: data.businessName,
            BusinessAddress: data.businessAddress,
            BusinessTaxId: data.businessTaxId,
          };
        });

        setIsLoadingBusinessInfo(false);

        return;
      } catch (error: any) {
        NotificationService.setError(error);

        setIsLoadingBusinessInfo(false);
      }
    };

    handleSubmitForm();
  };

  const handleBillingDownload = async (billing: BillingDTO) => {
    try {
      if (!canSubmit(5000)) {
        return;
      }

      setIsDownloadInvoice(true);

      //billing info change

      const formData = {
        InvoiceId: billing.InvoiceId,
      };

      const response = await axiosInstance.post(
        ApiRoutesEnum.DOWNLOAD_BILLING,
        formData,
        { authNeeded: true } as CustomAxiosRequestConfig
      );

      const { invoiceUrl } = response.data;

      window.open(invoiceUrl, "_blank");

      setIsDownloadInvoice(false);

      return;
    } catch (error: any) {
      NotificationService.setError(error);

      setIsDownloadInvoice(false);
    }
  };

  return (
    <Layout>
      <Helmet>
        <title>socialspicker.com - Billing</title>
      </Helmet>
      <div className="relative w-full">

        <div className={`w-full mx-auto`}>
          {isProfileLoading ? (
            <>
              <LoadingScreen
                loadingMessage={`Loading billing...`}
              />
            </>
          ) : (
            <>
              <form onSubmit={handleSubmit(onSubmit)} className='w-full max-w-lg mx-auto'>
                <p className={`text-lg font-semibold mb-4`}>
                  Billing Info
                </p>
                <div className="mb-4">
                  <span className='text-sm'>Business Name</span>
                  <input
                    type="text"
                    {...register('businessName', {
                    })}
                    maxLength={100}
                    placeholder='Business Name'
                    className={`${getInputTextStyleClass()} w-full py-2 px-2`}
                  />
                  {errors.businessName && <p className="text-red-500 text-sm">{errors.businessName.message}</p>}
                </div>
                <div className="mb-4">
                  <span className='text-sm'>Business Address</span>
                  <input
                    type="text"
                    {...register('businessAddress', {
                    })}
                    maxLength={100}
                    placeholder='Business Address'
                    className={`${getInputTextStyleClass()} w-full py-2 px-2`}
                  />
                  {errors.businessAddress && <p className="text-red-500 text-sm">{errors.businessAddress.message}</p>}
                </div>
                <div className="mb-4">
                  <span className='text-sm'>Business Tax Id (VAT only)</span>
                  <input
                    type="text"
                    {...register('businessTaxId', {
                    })}
                    maxLength={50}
                    placeholder='Business Tax Id'
                    className={`${getInputTextStyleClass()} w-full py-2 px-2`}
                  />
                  {errors.businessTaxId && <p className="text-red-500 text-sm">{errors.businessTaxId.message}</p>}
                </div>
                <button
                  type="submit"
                  className={`${getButtonSuccessStyleClass(false)} w-full mt-4 mb-2`}
                  disabled={isLoadingBusinessInfo}
                >
                  {isLoadingBusinessInfo ? "Wait..." : "Save"}
                </button>
              </form>

              <div className="list-group list-group-flush w-full h-full min-h-96 max-w-lg mt-4 mx-auto">
                {!isDownloadInvoice && billing && billing.map((billing, index) =>
                  <div key={index} className="list-group-item flex items-center mb-1 justify-between bg-white shadow-md group">
                    <div className="rounded-lg p-6 flex flex-col items-center w-full h-full">
                      {/*content*/}
                      <div className="w-full flex flex-col sm:flex-row items-center space-y-4 sm:space-y-0 sm:space-x-4">
                        <div className="flex-1">
                          <div className="text-sm text-gray-500">
                            <div className="mr-4 mt-2 inline-block">
                              <FontAwesomeIcon icon={faCalendar} className="mr-1" />
                              {billing.Date}
                            </div>
                          </div>
                          <div className="text-sm text-gray-500">
                            <div className="mr-4 mt-2 inline-block">
                              <FontAwesomeIcon icon={faMoneyBill} className="mr-1" />
                              {billing.Amount} {billing.Currency}
                            </div>
                          </div>
                        </div>
                        {/*dropdown*/}
                        <div className="ml-4 flex flex-col sm:flex-row items-center space-y-2 sm:space-y-0 sm:space-x-2">
                          <div className="relative inline-block text-left">
                            <Tooltip
                              text='Download Invoice'
                            >
                              <button
                                type="button"
                                className="ml-2 inline-flex items-center p-2 text-gray-500"
                                onClick={() => { handleBillingDownload(billing); }}
                              >
                                <FontAwesomeIcon icon={faDownload} className="text-lg" />
                              </button>
                            </Tooltip>
                          </div>
                        </div>
                        {/*dropdown - end*/}
                      </div>
                      {/*content - end*/}

                    </div>
                  </div>
                )}
                {billing?.length === 0 && (
                  <div className={`${getStandardCardBackground(true, MaxWEnum.MaxWLg)} mt-4 mx-auto`}>
                    No payments made
                  </div>
                )}
                {isDownloadInvoice && (
                  <div className={`${getStandardCardBackground(true, MaxWEnum.MaxWLg)} mt-4 mx-auto`}>
                    Downloading invoice...
                  </div>
                )}
                <SPPagination
                  pageCount={pageCount}
                  onPageChange={handlePageChange}
                  currentPage={currentPage}
                />
              </div>
            </>
          )}
        </div>

      </div>
    </Layout>
  );
};

export default Billing;