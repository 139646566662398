import { useNavigate } from 'react-router-dom';
import Layout from '../../components/Layout';
import { Helmet } from 'react-helmet';
import { useEffect } from 'react';
import { RoutesEnum as AppRoutes } from '../../routes/RoutesEnum';
import { CampaignViewEnum } from '../../helpers/CampaignViewEnum';
import CampaignSettings from '../../components/campaign/CampaignSettings';
import CampaignLoading from '../../components/loadings/CampaignLoading';
import { useCampaignContext } from '../../contexts/CampaignContext';
import { useIsLoading } from '../../hooks/useIsLoading';
import { useLoadingMessage } from '../../hooks/useLoadingMessage';
import { getGenerateSimulateDataParticipants, simulateData } from '../../config/envConfig';
import { CampaignTypeEnum } from '../../helpers/CampaignTypeEnum';
import CampaignPreview from '../../components/campaign/CampaignPreview';
import ProgressSteps from '../../components/ProgressSteps';
import CampaignCredits from '../../components/campaign/CampaignCredits';
import { getCampaignStatePersistent, setCampaignStatePersistent } from '../../helpers/CampaignHelper';

const Campaign: React.FC = () => {
  const {
    campaignParticipants,
    setCampaignParticipants,
    campaignViewEnum,
    setCampaignViewEnum,
    campaignStateDataDTO,
    setCampaignStateDataDTO,
    campaignSettingsDTO,
    setCampaignSettingsDTO,
    currentProgressStepIndex,
    setCurrentProgressStepIndex,
    setWinners,
    setSubstitutes,
    setEliminateDuplicates,
    setFilterByMention,
    setMention,
    setFilterByHashtag,
    setHashtag,
    setCountdown,
  } = useCampaignContext();
  const { isLoading, setIsLoading } = useIsLoading();
  const { loadingMessage } = useLoadingMessage();
  const navigate = useNavigate();

  useEffect(() => {
    const stateValidate = async (): Promise<boolean> => {
      setIsLoading(true);

      const campaignStatePersistent = getCampaignStatePersistent();

      if (!campaignStatePersistent && !campaignStateDataDTO) {
        setIsLoading(false);

        navigate(AppRoutes.HOME);

        return false;
      }
      else {
        if (campaignStatePersistent) {
          setCampaignStateDataDTO(campaignStatePersistent.campaignStateDataDTO);
          if (campaignStatePersistent.campaignSettingsDTO) {
            setCampaignSettingsDTO(campaignStatePersistent.campaignSettingsDTO);
            setWinners(campaignStatePersistent.campaignSettingsDTO.Winners);
            setSubstitutes(campaignStatePersistent.campaignSettingsDTO.Substitutes);
            setEliminateDuplicates(campaignStatePersistent.campaignSettingsDTO.EliminateDuplicates);
            setFilterByMention(campaignStatePersistent.campaignSettingsDTO.FilterByMention);
            setMention(campaignStatePersistent.campaignSettingsDTO.Mention);
            setFilterByHashtag(campaignStatePersistent.campaignSettingsDTO.FilterByHashtag);
            setHashtag(campaignStatePersistent.campaignSettingsDTO.Hashtag);
            setCountdown(campaignStatePersistent.campaignSettingsDTO.Countdown);
          }
          setCampaignViewEnum(campaignStatePersistent.campaignViewEnum);
          setCurrentProgressStepIndex(campaignStatePersistent.currentProgressStepIndex);
          setCampaignParticipants(campaignStatePersistent.campaignParticipants ?? []);
        }
      }

      setIsLoading(false);

      return true;
    };

    const init = async () => {
      if (!(await stateValidate()))
        return;
    };

    init();
  }, []);

  useEffect(() => {
    if (currentProgressStepIndex && currentProgressStepIndex > 0) {
      setCampaignStatePersistent(campaignStateDataDTO, campaignSettingsDTO, campaignViewEnum, currentProgressStepIndex, campaignParticipants);
    }
  }, [currentProgressStepIndex]);

  return (
    <Layout>
      <Helmet>
        <title>socialspicker.com - Campaign</title>
      </Helmet>
      <div className="relative w-full max-w-2xl">

        <div className={`w-full mx-auto`}>
          {isLoading ? (
            <>
              <CampaignLoading loadingMessage={loadingMessage} />
            </>
          ) : (
            <>
              <ProgressSteps
                currentProgressStepIndex={currentProgressStepIndex}
                progressStepsNames={[
                  "Preview",
                  "Settings",
                  "Credits"
                ]}
              />

              {campaignViewEnum === CampaignViewEnum.Preview && (
                <>
                  <CampaignPreview />
                </>
              )}

              {campaignViewEnum === CampaignViewEnum.Settings && (
                <>
                  <CampaignSettings />
                </>
              )}

              {campaignViewEnum === CampaignViewEnum.Credits && (
                <>
                  <CampaignCredits />
                </>
              )}
            </>
          )}
        </div>

      </div>
    </Layout>
  );
};

export default Campaign;