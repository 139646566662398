import React, { useEffect, useState } from 'react';
import { useAuthContext } from '../auth/contexts/AuthContext';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { RoutesEnum } from '../routes/RoutesEnum';
import { getButtonSuccessStyleClass } from '../config/FormSettings';
import { prettifyNumbers } from '../helpers/StringsHelper';
import { PaymentConstants } from '../helpers/PaymentConstants';
import axiosInstance, { CustomAxiosRequestConfig } from '../auth/config/axiosInstance';
import { ApiRoutesEnum } from '../routes/ApiRoutesEnum';
import NotificationService from '../services/NotificationService';
import AuthService from '../auth/services/AuthService';
import { AxiosResponse } from 'axios';
import { useIsLoading } from '../hooks/useIsLoading';
import CheckoutModal from './modals/CheckoutModal';
import Checkout from './Checkout';

interface Props {
  showNotifications?: boolean;
}

const Credits: React.FC<Props> = ({ showNotifications = true }) => {
  const { getUserDataFromToken, isLoggedIn, userData, setIsPolingPaymentFired } = useAuthContext();
  const { setLastPaymentStorage, getLastPaymentStorage } = AuthService();
  const { isLoading, setIsLoading } = useIsLoading();
  const navigate = useNavigate();
  const location = useLocation();
  const [isCheckoutModalOpen, setIsCheckoutModalOpen] = useState<boolean>(false);
  const queryParams = new URLSearchParams(location.search);
  const action = queryParams.get('action');
  const sessionId = queryParams.get('sessionid');

  useEffect(() => {
    const init = async () => {
      try {
        setIsLoading(true);

        if (action === PaymentConstants.payment) {
          if (showNotifications) {
            NotificationService.setMessage(
              {
                message: "Thank you, your payment will validate automatically and your credits will be updated",
                status: true,
              });
          }

          if (sessionId) {
            setLastPaymentStorage({
              SessionId: sessionId
            });
          }
        } else if (action === PaymentConstants.payment) {
          if (showNotifications) {
            NotificationService.setMessage(
              {
                message: "Your payment didn't go through, check your card details or contact your bank",
                status: false,
              });
          }
        }

        const lastPaymentStorage = getLastPaymentStorage();

        if (lastPaymentStorage) {
          if (await isLoggedIn()) {
            const pollingPayment = async (retries = 10, delay = 1000): Promise<AxiosResponse | undefined> => {
              try {
                const response = await axiosInstance.get(ApiRoutesEnum.POLLING_PAYMENT,
                  {
                    authNeeded: true,
                    params: {
                      SessionId: lastPaymentStorage.SessionId
                    }
                  } as CustomAxiosRequestConfig);

                const { status } = response.data;

                if (status) {
                  await getUserDataFromToken(navigate, true);

                  setIsPolingPaymentFired(true);

                  return response;
                }
                else {
                  if (retries === 0) {
                    NotificationService.setMessage(
                      {
                        message: "Please refresh page to update your credits",
                        status: false,
                      });

                    return undefined;
                  }

                  await new Promise(resolve => setTimeout(resolve, delay));

                  return pollingPayment(retries - 1, delay * 2);
                }
              } catch (error) {
                if (retries === 0) {
                  NotificationService.setMessage(
                    {
                      message: "Please refresh page to update your credits",
                      status: false,
                    });

                  return undefined;
                }

                await new Promise(resolve => setTimeout(resolve, delay));

                return pollingPayment(retries - 1, delay * 2);
              }
            };

            await pollingPayment();
          }
        }

        setIsLoading(false);
      } catch (Ex) {
        setIsLoading(false);
      }
    }

    init();
  }, [sessionId]);

  return (
    <>
      <div className="flex items-center p-1 rounded">
        <div className="mr-2">
          Credits: <span className='font-bold text-success'>{!isLoading ? userData?.Credits ? userData.CreditsDisplay : 0 : "Loading..."}</span>
        </div>
        <div className='mr-2'>
          |
        </div>
        <div>
          <div
            onClick={() => setIsCheckoutModalOpen(true)}
            className={`${getButtonSuccessStyleClass(false)} cursor-pointer`}>
            Buy
          </div>
        </div>
      </div>

      <CheckoutModal isOpen={isCheckoutModalOpen} onClose={() => setIsCheckoutModalOpen(false)}>
        <Checkout />
      </CheckoutModal>
    </>
  );
};

export default Credits;