import React, { useEffect, useRef, useState } from 'react';
import CloseButton from '../buttons/CloseButton';
import { getButtonSuccessStyleClass, getInputTextStyleClass } from '../../config/FormSettings';
import { CampaignModel } from '../../models/CampaignModel';
import axiosInstance, { CustomAxiosRequestConfig } from '../../auth/config/axiosInstance';
import { ApiRoutesEnum } from '../../routes/ApiRoutesEnum';
import notificationService from '../../services/NotificationService';

interface Props {
  campaign: CampaignModel | null;
  setCampaigns: (value: CampaignModel[] | null | ((prevState: CampaignModel[] | null) => CampaignModel[] | null)) => void;
  isOpen: boolean;
  onClose: () => void;
}

const EditCampaignModal: React.FC<Props> = ({ campaign, setCampaigns, isOpen, onClose }) => {
  const [isEditCampaign, setIsEditCampaign] = useState<boolean>(false);
  const [title, setTitle] = useState<string>(campaign?.Title ?? "");
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (campaign) {
      setTitle(campaign.Title);
    }
  }, [campaign]);

  useEffect(() => {
    const editCampaign = async () => {
      if (isEditCampaign && campaign) {
        try {
          const newCampaign = { ...campaign, Title: title }

          const formData = {
            Title: newCampaign.Title,
            Code: newCampaign.Code
          }

          const response = await axiosInstance.post(
            ApiRoutesEnum.EDIT_CAMPAIGN,
            formData,
            { authNeeded: true } as CustomAxiosRequestConfig);

          setCampaigns((prevCampaigns) => {
            return (prevCampaigns ?? []).map((oldCampaign) => {
              return oldCampaign.Id === newCampaign.Id ? newCampaign : oldCampaign;
            });
          });

          setIsEditCampaign(false);

          onClose();
        }
        catch (error: any) {
          notificationService.setError(error);

          setIsEditCampaign(false);
        }
      }
    }

    const init = async () => {
      await editCampaign();
    }

    init();
  }, [isEditCampaign]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose]);

  if (!isOpen || !campaign) return null;

  return (
    <div
      className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 overflow-auto">
      <div
        ref={modalRef}
        className="relative bg-white p-6 rounded shadow-lg w-full max-w-lg mx-4 sm:mx-0 max-h-[90vh] overflow-y-auto">
        <div className='flex items-center justify-center absolute top-2 right-2'>
        </div>
        <div className="flex flex-col w-full my-4 text-center">
          <div className="font-semibold p-2 rounded-md mb-2">
            Title
          </div>
          <div className='p-1'>
            <input
              type="text"
              maxLength={100}
              placeholder='Title'
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className={`${getInputTextStyleClass()} w-full py-2 px-2`}
            />
          </div>
          <div className="p-4 rounded-md mb-2">
            <div className="px-5 mt-4 text-center">
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-2">
                <CloseButton
                  text="Close"
                  onClick={onClose}
                />
                <button
                  type="button"
                  onClick={() => { setIsEditCampaign(true); }}
                  className={`w-full ${getButtonSuccessStyleClass(true)}`}>
                  {isEditCampaign ? "Editing..." : "Edit"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditCampaignModal;