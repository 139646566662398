import { useNavigate, useParams } from 'react-router-dom';
import Layout from '../components/Layout';
import { Helmet } from 'react-helmet';
import { useEffect, useState } from 'react';
import axiosInstance, { CustomAxiosRequestConfig } from '../auth/config/axiosInstance';
import { ApiRoutesEnum } from '../routes/ApiRoutesEnum';
import { CampaignModel } from '../models/CampaignModel';
import NotificationService from '../services/NotificationService';
import { useCampaignContext } from '../contexts/CampaignContext';
import { WinnerModel } from '../models/WinnerModel';
import { useAuthContext } from '../auth/contexts/AuthContext';
import { StatusEnum } from '../helpers/StatusEnum';
import { CampaignViewEnum } from '../helpers/CampaignViewEnum';
import CampaignSettings from '../components/campaign/CampaignSettings';
import CampaignWinner from '../components/campaign/CampaignWinner';
import { WinnerDTO } from '../dto/WinnerDTO';
import { RoutesEnum } from '../routes/RoutesEnum';
import { CampaignSettingsDTO } from '../dto/CampaignSettingsDTO';
import LoadingScreen from '../components/loadings/LoadingScreen';
import { CampaignTypeEnum } from '../helpers/CampaignTypeEnum';

const C: React.FC = () => {
  const {
    campaignViewEnum,
    setCampaignViewEnum,
    setCampaignSettingsDTO,
    setWinners,
    setCampaignWinners,
    setCampaignSubstitutes,
    setSubstitutes,
    setEliminateDuplicates,
    setFilterByMention,
    setMention,
    setFilterByHashtag,
    setHashtag,
    setCountdown,
    setIsCampaignOwner,
    setCampaign,
    unmountCampaignRevertDefaults,
  } = useCampaignContext();
  const { isLoggedIn, getUserDataFromToken } = useAuthContext();
  const navigate = useNavigate();
  const { code } = useParams<{ code: string }>();
  const [isCampaignLoading, setIsCampaignLoading] = useState<boolean>(false);

  useEffect(() => {
    const getCampaign = async () => {
      try {
        setIsCampaignLoading(true);

        const response = await axiosInstance.get(ApiRoutesEnum.GET_CAMPAIGN_BY_CODE,
          {
            authNeeded: true,
            params: {
              code: code,
            }
          } as CustomAxiosRequestConfig);

        const { campaignResponse } = response.data;

        const campaignData: CampaignModel = {
          Id: campaignResponse.id,
          Title: campaignResponse.title,
          SocialPlatform: campaignResponse.socialPlatform,
          CampaignType: campaignResponse.campaignType,
          Date: campaignResponse.date,
          ParticipantsCount: campaignResponse.participantsCount,
          Author: campaignResponse.author,
          Post: campaignResponse.post,
          Code: campaignResponse.code,
          Status: campaignResponse.status,
          Message: campaignResponse.message,
          Username: campaignResponse.username,
          UserId: campaignResponse.userId,
          Description: campaignResponse.description,
        };

        setCampaign(campaignData);

        const winnersData: WinnerDTO[] = campaignResponse.winners
          .filter((winner: any) => !winner.isSubstitute)
          .map((winner: any) => ({
            Id: winner.id,
            Username: winner.username,
            Comment: winner.comment,
            CommentLink: winner.commentLink,
            ProfileLink: winner.profileLink,
            IsValid: winner.isValid,
            IsSubstitute: winner.isSubstitute,
            InvalidReason: winner.invalidReason,
          }));

        setCampaignWinners(winnersData);

        const substitutesData: WinnerDTO[] = campaignResponse.winners
          .filter((winner: any) => winner.isSubstitute)
          .map((winner: any) => ({
            Id: winner.id,
            Username: winner.username,
            Comment: winner.comment,
            CommentLink: winner.commentLink,
            ProfileLink: winner.profileLink,
            IsValid: winner.isValid,
            IsSubstitute: winner.isSubstitute,
            InvalidReason: winner.invalidReason,
          }));

        setCampaignSubstitutes(substitutesData);

        const campaignSettingsDTO: CampaignSettingsDTO = {
          Title: campaignResponse.title,
          Winners: campaignResponse.settings.winners,
          Substitutes: campaignResponse.settings.substitutes,
          EliminateDuplicates: campaignResponse.settings.eliminateDuplicates,
          FilterByMention: campaignResponse.settings.filterByMention,
          Mention: campaignResponse.settings.mention,
          FilterByHashtag: campaignResponse.settings.filterByHashtag,
          Hashtag: campaignResponse.settings.hashtag,
          Countdown: campaignResponse.settings.countdown,
        }

        setCampaignSettingsDTO(campaignSettingsDTO);

        const userLoggedIn = await getUserDataFromToken(navigate, false);

        if (campaignData.Status === StatusEnum.Ready) {
          if (await isLoggedIn() && userLoggedIn) {
            if (campaignData.Username === userLoggedIn?.Username) {
              //settings
              setWinners(campaignSettingsDTO.Winners);
              setSubstitutes(campaignSettingsDTO.Substitutes);
              setEliminateDuplicates(campaignSettingsDTO.EliminateDuplicates);
              setFilterByMention(campaignSettingsDTO.FilterByMention);
              setMention(campaignSettingsDTO.Mention);
              setFilterByHashtag(campaignSettingsDTO.FilterByHashtag);
              setHashtag(campaignSettingsDTO.Hashtag);
              setCountdown(campaignSettingsDTO.Countdown);
              //settings

              setIsCampaignOwner(true);

              setCampaignViewEnum(CampaignViewEnum.SettingsEdit);
            }
            //unauthorized and not owner of the campaign
            else {
              navigate(RoutesEnum.NOT_FOUND);
              return;
            }
          }
          //not found if not logged in & campaign is in ready state, it will allow to edit only
          else {
            navigate(RoutesEnum.NOT_FOUND);
            return;
          }
        }
        else if (campaignData.Status === StatusEnum.Finish) {
          if (await isLoggedIn()) {
            //if logged in and owner of the campaign, edit winners
            if (campaignData.Username === userLoggedIn?.Username) {
              setIsCampaignOwner(true);
            }
          }

          setCampaignViewEnum(CampaignViewEnum.Winners);
        }
        //only ready or finished allowed
        else {
          navigate(RoutesEnum.NOT_FOUND);
          return;
        }

        setIsCampaignLoading(false);
      }
      catch (error: any) {
        NotificationService.setError(error);

        setIsCampaignLoading(false);
      }
    };

    const init = async () => {
      await getCampaign();
    };

    init();

    return () => {
      unmountCampaignRevertDefaults();
    };
  }, []);

  return (
    <Layout>
      <Helmet>
        <title>socialspicker.com - Campaign</title>
      </Helmet>
      <div className="relative w-full">

        <div className={`w-full mx-auto`}>
          {isCampaignLoading ? (
            <>
              <LoadingScreen
                loadingMessage={`Loading campaign code ${code}...`}
              />
            </>
          ) : (
            <>
              {campaignViewEnum === CampaignViewEnum.SettingsEdit && (
                <div className='w-full mx-auto max-w-lg'>
                  <CampaignSettings />
                </div>
              )}
              {(campaignViewEnum === CampaignViewEnum.WinnersEdit || campaignViewEnum === CampaignViewEnum.Winners) && (
                <CampaignWinner />
              )}
            </>
          )}
        </div>

      </div>
    </Layout>
  );
};

export default C;