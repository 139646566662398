import React, { ReactNode, useEffect, useState } from 'react';
import Navigation from './navigation/Navigation';
import { useLocation } from 'react-router-dom';
import Footer from './Footer';
import { register } from './../serviceWorkerRegistration';
import NotificationToast from './NotificationToast';
import notificationService from '../services/NotificationService';
import useIsAuthenticationPagesRoute from '../auth/hooks/useIsAuthenticationPagesRoute';
import useIsAppsPagesRoute from '../hooks/useIsAppsPagesRoute';
import VerifyEmailModal from '../auth/components/modals/VerifyEmailModal';
import { RoutesEnum as AppRoutes } from '../routes/RoutesEnum';
import { useAuthContext } from '../auth/contexts/AuthContext';
import { routeContains } from '../helpers/RoutesHelper';
import useIsCampaignPageRoute from '../hooks/useIsCampaignPageRoute';
import { version } from '../config/envConfig';
import GoogleAdsense from './GoogleAdsense';
import useIsMobile from '../hooks/useIsMobile';
import { getAdsenseSettingsDisplayAdHorizontal1, getAdsenseSettingsDisplayAdVertical1, getAdsenseSettingsDisplayAdVertical2 } from '../config/AdsenseSettings';
import LayoutContent from './LayoutContent';
import { NotificationItem } from '../dto/NotificationItem';
import StickyFollowButton from './StickyFollowButton';

interface LayoutProps {
  children: ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const { userData,
    isLoggedInState,
    showGoogleAdsense } = useAuthContext();
  const { isUnder1200px } = useIsMobile();
  const isAuthenticationPagesRoute = useIsAuthenticationPagesRoute();
  const isCampaignPageRoute = useIsCampaignPageRoute();
  const isAppsPagesRoute = useIsAppsPagesRoute();
  const location = useLocation();

  const [notifications, setNotifications] = useState<NotificationItem[]>([]);
  const [isVerifyEmailModaOpen, setIsVerifyEmailModalOpen] = useState<boolean>(true);

  useEffect(() => {
    setIsVerifyEmailModalOpen(userData?.Verified ? false : true);
  }, [userData]);

  useEffect(() => {
    /*PWA service worker*/
    register({
      onUpdate: () => {
        notificationService.setMessage({
          message: `A new version is available: ${version}. Please refresh the page.`,
          status: true
        });
      },
    });
    /*PWA service worker*/

    notificationService.subscribe(setNotifications);
    return () => notificationService.unsubscribe();
  }, []);

  const handleToastClose = (index: number) => {
    notificationService.removeMessage(index);
  };

  return (
    <>
      {/*we have margin top bigger with navigation because of navigation, without it it's smaller*/}
      {!isAuthenticationPagesRoute && <Navigation />}
      <main className={`
      ${(isAuthenticationPagesRoute) ?
          "" :
          "mt-14"}`}>

        {/*we force modal to verify email*/}
        {isLoggedInState && !userData?.Verified && userData?.Email && !routeContains(location, AppRoutes.SET_EMAIL_VERIFY) && (
          <>
            {isVerifyEmailModaOpen && (
              <VerifyEmailModal
                email={userData.Email}
                setIsVerifyEmailModalOpen={setIsVerifyEmailModalOpen}
              />
            )}
          </>
        )}

        {notifications.map((notification, index) => (
          <NotificationToast
            key={index}
            message={notification.message}
            onClose={() => handleToastClose(index)}
            status={notification.status}
            index={index}
          />
        ))}

        {/*content*/}
        {/*if left & right columns display, show grid cols*/}
        <div className={`${!isUnder1200px ? "grid" : ""} ${showGoogleAdsense ? "grid-cols-[auto_1fr_auto]" : ""} relative`}>
          {!isUnder1200px ? (
            <>
              {showGoogleAdsense && (
                <div className="ml-2 mt-1 w-64 h-full max-h-600 sticky top-0">
                  <div className="w-full max-w-xs mx-auto">
                    <GoogleAdsense
                      adsenseSettingsDTO={getAdsenseSettingsDisplayAdVertical1()}
                    />
                  </div>
                </div>
              )}
              {/*middle column*/}
              <LayoutContent>
                {children}
              </LayoutContent>
              {showGoogleAdsense && (
                <div className="mr-2 mt-1 w-64 h-full max-h-600 sticky top-0">
                  <div className="w-full max-w-xs mx-auto">
                    <GoogleAdsense
                      adsenseSettingsDTO={getAdsenseSettingsDisplayAdVertical2()}
                    />
                  </div>
                </div>
              )}
            </>
          ) : (
            <>
              {showGoogleAdsense && (
                <div className="w-full max-h-[100px] mx-auto p-1 mt-2 z-50">
                  <GoogleAdsense
                    adsenseSettingsDTO={getAdsenseSettingsDisplayAdHorizontal1()}
                  />
                </div>
              )}
              <LayoutContent>
                {children}
              </LayoutContent>
            </>
          )}
        </div>
        {/*content - end*/}

      </main>

      <StickyFollowButton />
          
      {!isLoggedInState && !isAuthenticationPagesRoute && <Footer />}
    </>
  );
};

export default Layout;