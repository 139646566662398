import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faFacebook, faTiktok } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';
import { RoutesEnum } from '../routes/RoutesEnum';
import { faX } from '@fortawesome/free-solid-svg-icons';
import useIsMobile from '../hooks/useIsMobile';
import { getTextLinkStyleClass } from '../config/FormSettings';
import { navigationAppsItems } from '../config/envConfig';

const Footer: React.FC = () => {
  const { isMobile } = useIsMobile();
  const columnsGap = isMobile ? "gap-2" : "gap-8";

  return (
    <>
      <footer aria-labelledby="footer-heading" className="bg-white py-8 mt-4">
        <div className={`container mx-auto grid grid-cols-1 md:grid-cols-3 ${columnsGap}`}>

          <div className="space-y-6 p-2">
            <Link to={RoutesEnum.HOME} className="w-full items-center">
              <img src={"/assets/logoBlack.svg"} alt="Logo" className={`w-full h-auto max-w-3xs ${isMobile && "mx-auto"}`} />
            </Link>
            <p className="">
              Create Giveaways from Social Media platforms, pick a random comment winner from Instagram and others
            </p>
            <div className={`space-x-6 ${isMobile && "text-center"}`}>
              <Link to="https://instagram.com/socialspicker" target="_blank" className="group">
                <FontAwesomeIcon icon={faInstagram} className='text-instagram text-2xl group-hover:text-instagram' />
              </Link>
              <Link to="https://tiktok.com/@socialspicker" target="_blank" className="group">
                <FontAwesomeIcon icon={faTiktok} className='text-tiktok text-2xl group-hover:text-tiktok' />
              </Link>
              <Link to="https://x.com/socialspicker" target="_blank" className="group">
                <FontAwesomeIcon icon={faX} className='text-twitter text-2xl group-hover:text-twitter' />
              </Link>
              <Link to="https://facebook.com/socialspicker" target="_blank" className="group">
                <FontAwesomeIcon icon={faFacebook} className='text-facebook text-2xl group-hover:text-facebook' />
              </Link>
            </div>
          </div>

          <div className={`grid grid-cols-2 ${columnsGap} p-4`}>
            <div>
              <h3 className="text-sm font-semibold">{navigationAppsItems[0].Header.Name}</h3>
              <ul className="mt-4 space-y-4">
                {navigationAppsItems[0].Header.Items.map((item) => (
                  <React.Fragment key={item.Name}>
                    <li><Link to={item.Route} className={`${getTextLinkStyleClass()}`}>{item.Name}</Link></li>
                  </React.Fragment>
                ))}
              </ul>
            </div>
            <div>
              <h3 className="text-sm font-semibold">{navigationAppsItems[1].Header.Name}</h3>
              <ul className="mt-4 space-y-4">
                {navigationAppsItems[1].Header.Items.map((item) => (
                  <React.Fragment key={item.Name}>
                    <li><Link to={item.Route} className={`${getTextLinkStyleClass()}`}>{item.Name}</Link></li>
                  </React.Fragment>
                ))}
              </ul>
            </div>
            <div>

            </div>
          </div>
          <div className={`grid grid-cols-2 ${columnsGap} p-4`}>
            <div>
              <h3 className="text-sm font-semibold">{navigationAppsItems[2].Header.Name}</h3>
              <ul className="mt-4 space-y-4">
                {navigationAppsItems[2].Header.Items.map((item) => (
                  <React.Fragment key={item.Name}>
                    <li><Link to={item.Route} className={`${getTextLinkStyleClass()}`}>{item.Name}</Link></li>
                  </React.Fragment>
                ))}
              </ul>
            </div>
            <div>
              <h3 className="text-sm font-semibold">Resources</h3>
              <ul className="mt-4 space-y-4">
                <li><Link to={RoutesEnum.PLANS} className={`${getTextLinkStyleClass()}`}>Prices</Link></li>
                <li><Link to={RoutesEnum.HELP} className={`${getTextLinkStyleClass()}`}>Help</Link></li>
                <li><Link to={RoutesEnum.TERMS} className={`${getTextLinkStyleClass()}`}>Terms & Conditions</Link></li>
                <li><Link to={RoutesEnum.PRIVACY_POLICY} className={`${getTextLinkStyleClass()}`}>Privacy Policy</Link></li>
              </ul>
            </div>
          </div>

        </div>

        {/* Footer Bottom */}
        <div className="border-t border-gray-100 mt-8 pt-8">
          <p className="text-center text-lg">
            &copy; socialspicker.com. All rights reserved.
          </p>
          <p className='text-center border-t border-gray-100 mt-4 pt-4 mb-4 text-base max-w-xl mx-auto'>
            When you visit or interact with our sites, services or tools, we or our authorised service providers may use cookies for storing information to help provide you with a better, faster and safer experience and for marketing purposes.
          </p>
        </div>
      </footer>
    </>
  );
};

export default Footer;