import React, { useEffect, useRef, useState } from 'react';
import CloseButton from '../buttons/CloseButton';
import { getButtonErrorStyleClass, getButtonSuccessStyleClass, getInputTextStyleClass } from '../../config/FormSettings';
import { CampaignModel } from '../../models/CampaignModel';
import axiosInstance, { CustomAxiosRequestConfig } from '../../auth/config/axiosInstance';
import { ApiRoutesEnum } from '../../routes/ApiRoutesEnum';
import notificationService from '../../services/NotificationService';
import { WinnerDTO } from '../../dto/WinnerDTO';
import { useCampaignContext } from '../../contexts/CampaignContext';

interface Props {
  winner: WinnerDTO | null;
  setCampaignWinners: (value: WinnerDTO[] | null | ((prevState: WinnerDTO[] | null) => WinnerDTO[] | null)) => void;
  setCampaignSubstitutes: (value: WinnerDTO[] | null | ((prevState: WinnerDTO[] | null) => WinnerDTO[] | null)) => void;
  isOpen: boolean;
  onClose: () => void;
}

const EditCampaignModal: React.FC<Props> = ({ winner, setCampaignWinners, setCampaignSubstitutes, isOpen, onClose }) => {
  const {
    campaign,
  } = useCampaignContext();
  const [isEditWinner, setIsEditWinner] = useState<boolean>(false);
  const [invalidReason, setInvalidReason] = useState<string>("");
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const editWinner = async () => {
      if (isEditWinner && winner) {
        try {
          const formData = {
            WinnerId: winner.Id,
            Code: campaign?.Code,
            IsValid: !winner.IsValid,
            InvalidReason: invalidReason
          }

          const response = await axiosInstance.post(
            ApiRoutesEnum.EDIT_CAMPAIGN_WINNER,
            formData,
            { authNeeded: true } as CustomAxiosRequestConfig);

          const newWinner = { ...winner, IsValid: !winner.IsValid, InvalidReason: invalidReason }

          if (newWinner.IsSubstitute) {
            setCampaignSubstitutes((prevCampaignSubstitutes) => {
              return (prevCampaignSubstitutes ?? []).map((oldWinner) => {
                return oldWinner.Id === newWinner.Id ? newWinner : oldWinner;
              });
            });
          }
          else {
            setCampaignWinners((prevCampaignWinners) => {
              return (prevCampaignWinners ?? []).map((oldWinner) => {
                return oldWinner.Id === newWinner.Id ? newWinner : oldWinner;
              });
            });
          }

          setIsEditWinner(false);

          onClose();
        }
        catch (error: any) {
          notificationService.setError(error);

          setIsEditWinner(false);
        }
      }
    }

    const init = async () => {
      await editWinner();
    }

    init();

    return () => {
      setInvalidReason("");
    }
  }, [isEditWinner]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose]);

  if (!isOpen || !winner) return null;

  return (
    <div
      className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 overflow-auto">
      <div
        ref={modalRef}
        className="relative bg-white p-6 rounded shadow-lg w-full max-w-lg mx-4 sm:mx-0 max-h-[90vh] overflow-y-auto">
        <div className='flex items-center justify-center absolute top-2 right-2'>
        </div>
        <div className="flex flex-col w-full my-4 text-center">
          <div className="font-semibold p-2 rounded-md mb-2">
            {winner.Username} <span className='font-normal'>Invalid Reason (optional)</span>
          </div>
          <div className='p-1'>
            <input
              type="text"
              maxLength={50}
              placeholder="Didn't follow the rules"
              value={invalidReason}
              onChange={(e) => setInvalidReason(e.target.value)}
              className={`${getInputTextStyleClass()} w-full py-2 px-2`}
            />
          </div>
          <div className="p-4 rounded-md mb-2">
            <div className="px-5 mt-4 text-center">
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-2">
                <CloseButton
                  text="Close"
                  onClick={onClose}
                />
                <button
                  type="button"
                  onClick={() => { setIsEditWinner(true); }}
                  className={`w-full ${winner.IsValid ? getButtonErrorStyleClass(true) : getButtonSuccessStyleClass(true)}`}>
                  {isEditWinner ?
                    "Wait..." :
                    winner.IsValid ?
                      "Invalidate" :
                      "Validate"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditCampaignModal;