import React, { useEffect, useState } from 'react';
import Layout from '../../../components/Layout';
import { Helmet } from 'react-helmet';
import { useAuthContext } from '../../../auth/contexts/AuthContext';
import './RollDice.css';
import { getButtonPrimaryStyleClass } from '../../../config/FormSettings';
import AdBlockModal from '../../../components/modals/AdBlockModal';

const RollDice: React.FC = () => {
  const { setShowGoogleAdsense } = useAuthContext();
  const [diceCount, setDiceCount] = useState<number>(1);
  const [sides, setSides] = useState<number>(6);
  const [diceValues, setDiceValues] = useState<number[]>(Array(1).fill(1)); // default 2 dice
  const [isRolling, setIsRolling] = useState<boolean>(false);

  useEffect(() => {
    setShowGoogleAdsense(true);

    return () => {
      setShowGoogleAdsense(false);
    }
  }, []);

  useEffect(() => {
    setDiceValues(generateRandomDiceValues(diceCount, sides)); // Randomize dice values on sides change
  }, [sides, diceCount]);

  useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      if (e.code === 'Space') {
        e.preventDefault();
        rollDice();
      }
    };
    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  const renderDiceDots = (value: number) => {
    const dotPositionClasses: { [key: number]: string } = {
      1: 'dot-center',
      2: 'dot-top-left dot-bottom-right',
      3: 'dot-top-left dot-center dot-bottom-right',
      4: 'dot-top-left dot-top-right dot-bottom-left dot-bottom-right',
      5: 'dot-top-left dot-top-right dot-center dot-bottom-left dot-bottom-right',
      6: 'dot-top-left dot-top-right dot-bottom-left dot-bottom-right dot-mid-left dot-mid-right',
    };

    if (value > 6 || value < 1) {
      return null; // Or handle as needed for invalid values
    }

    return (
      <div className="dice-dots bg-primary shadow-lg">
        {dotPositionClasses[value].split(' ').map((positionClass, i) => (
          <span key={i} className={`dot ${positionClass}`}></span>
        ))}
      </div>
    );
  };

  const generateRandomDiceValues = (diceCount: number, sides: number) => {
    return Array.from({ length: diceCount }, () => Math.floor(Math.random() * sides) + 1);
  };

  const rollDice = () => {
    setIsRolling(true);
    const newValues = generateRandomDiceValues(diceCount, sides);
    setTimeout(() => {
      setDiceValues(newValues);
      setIsRolling(false);
    }, 1000); // duration of animation
  };

  const sumOfDiceValues = diceValues.reduce((sum, value) => sum + value, 0);

  return (
    <Layout>
      <Helmet>
        <title>socialspicker.com - Roll Dice Online</title>
      </Helmet>

      <h1 className="text-3xl font-bold mb-4">Dice Roller Online</h1>
      <div className='mb-4'>
        <p>Use the free virtual dice simulator from any device and generate dice online to your liking.</p>
      </div>

      <div className="grid grid-cols-2 gap-4 mb-4">
        {/* Dice Count Select */}
        <div className='items-center'>
          <label className="mx-auto block text-sm font-medium">Number of Dices:</label>
          <select
            className="mx-auto mt-1 block w-auto p-2 border border-gray-300 rounded-md"
            value={diceCount}
            onChange={(e) => setDiceCount(Number(e.target.value))}
          >
            {[...Array(20)].map((_, i) => (
              <option key={i + 1} value={i + 1}>
                {i + 1}
              </option>
            ))}
          </select>
        </div>

        {/* Sides Select */}
        <div className='items-center'>
          <label className="mx-auto block text-sm font-medium">Number of Sides per Dice:</label>
          <select
            className="mx-auto mt-1 block w-auto p-2 border border-gray-300 rounded-md"
            value={sides}
            onChange={(e) => setSides(Number(e.target.value))}
          >
            {[...Array(20)].map((_, i) => (
              <option key={i + 1} value={i + 1}>
                {i + 1}
              </option>
            ))}
          </select>
        </div>
      </div>

      {/* Dice Display */}
      <div className="flex flex-wrap justify-center mt-6">
        {diceValues.map((value, index) => (
          <div key={index} className={`dice bg-primary ${isRolling ? 'rolling' : ''}`}>
            {sides <= 6 ? renderDiceDots(value) : value}
          </div>
        ))}
      </div>

      {/* Your result display */}
      <div className="mt-4 mb-4">
        <h2 className="text-xl font-bold">Your result: {sumOfDiceValues}</h2>
      </div>

      {/* Roll Button */}
      <button
        className={`${getButtonPrimaryStyleClass(false)} mt-4 px-6 py-2`}
        onClick={rollDice}
      >
        Roll
      </button>

      <p className='mt-2'>(Or press the space bar)</p>

      <AdBlockModal />
    </Layout>
  );
};

export default RollDice;