import React from 'react';
import Layout from '../../components/Layout';
import { Helmet } from 'react-helmet';
import LoginAuth from '../components/LoginAuth';
import { Link } from 'react-router-dom';
import { RoutesEnum as AppRoutes } from './../../routes/RoutesEnum';

const Login: React.FC = () => {
  return (
    <Layout>
      <Helmet>
        <title>socialspicker.com - Login</title>
      </Helmet>
      <div className="justify-center mb-8">
        <Link to={AppRoutes.HOME}>
          <img src="/assets/logoBlack.svg" alt="Logo" className="w-full h-auto max-w-3xs" />
        </Link>
      </div>
      <LoginAuth />
    </Layout>
  );
};

export default Login;