import React, { useEffect, useState } from 'react';
import Layout from '../../components/Layout';
import { Helmet } from 'react-helmet';
import AdBlockModal from '../../components/modals/AdBlockModal';
import { useAuthContext } from '../../auth/contexts/AuthContext';
import { getButtonPrimaryStyleClass, getInputTextStyleClass } from '../../config/FormSettings';
import { GiveawayImageTemplatesDto } from '../../dto/GiveawayImageTemplatesDto';
import html2canvas from 'html2canvas';

const CreateGiveawayImage: React.FC = () => {
  const { setShowGoogleAdsense } = useAuthContext();
  const [username, setUsername] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const giveawayImageTemplatesDto: GiveawayImageTemplatesDto[] = [
    {
      Id: 1,
      UsernamePositionY: "2%",
      DescriptionPositionY: "30%",
      TemplatePath: "/assets/giveawayImageTemplates/template1.png",
      BackgroundColorOpacityTitle: "text-white",
      BackgroundColorOpacityDesc: "text-white",
    },
    {
      Id: 2,
      UsernamePositionY: "2%",
      DescriptionPositionY: "30%",
      TemplatePath: "/assets/giveawayImageTemplates/template2.png",
      BackgroundColorOpacityTitle: "text-white",
      BackgroundColorOpacityDesc: "text-white",
    },
    {
      Id: 3,
      UsernamePositionY: "2%",
      DescriptionPositionY: "30%",
      TemplatePath: "/assets/giveawayImageTemplates/template3.png",
      BackgroundColorOpacityTitle: "text-white",
      BackgroundColorOpacityDesc: "text-white",
    },
    {
      Id: 4,
      UsernamePositionY: "2%",
      DescriptionPositionY: "30%",
      TemplatePath: "/assets/giveawayImageTemplates/template4.png",
      BackgroundColorOpacityTitle: "text-white",
      BackgroundColorOpacityDesc: "text-white",
    },
    {
      Id: 5,
      UsernamePositionY: "2%",
      DescriptionPositionY: "30%",
      TemplatePath: "/assets/giveawayImageTemplates/template5.png",
      BackgroundColorOpacityTitle: "text-white",
      BackgroundColorOpacityDesc: "text-white",
    },
    {
      Id: 6,
      UsernamePositionY: "2%",
      DescriptionPositionY: "30%",
      TemplatePath: "/assets/giveawayImageTemplates/template6.png",
      BackgroundColorOpacityTitle: "text-white",
      BackgroundColorOpacityDesc: "text-white",
    },
    {
      Id: 7,
      UsernamePositionY: "2%",
      DescriptionPositionY: "30%",
      TemplatePath: "/assets/giveawayImageTemplates/template7.png",
      BackgroundColorOpacityTitle: "text-white",
      BackgroundColorOpacityDesc: "text-white",
    },
    {
      Id: 8,
      UsernamePositionY: "2%",
      DescriptionPositionY: "30%",
      TemplatePath: "/assets/giveawayImageTemplates/template8.png",
      BackgroundColorOpacityTitle: "text-white",
      BackgroundColorOpacityDesc: "text-white",
    },
    {
      Id: 9,
      UsernamePositionY: "2%",
      DescriptionPositionY: "30%",
      TemplatePath: "/assets/giveawayImageTemplates/template9.png",
      BackgroundColorOpacityTitle: "text-white",
      BackgroundColorOpacityDesc: "text-white",
    },
    {
      Id: 10,
      UsernamePositionY: "2%",
      DescriptionPositionY: "30%",
      TemplatePath: "/assets/giveawayImageTemplates/template10.png",
      BackgroundColorOpacityTitle: "text-white",
      BackgroundColorOpacityDesc: "text-white",
    },
  ];

  useEffect(() => {
    setShowGoogleAdsense(true);

    return () => {
      setShowGoogleAdsense(false);
    }
  }, []);

  const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(e.target.value);
  };

  const handleDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(e.target.value);
  };

  const handleDownload = (item: GiveawayImageTemplatesDto) => {
    const element = document.getElementById(`template-${item.Id}`);
    if (!element) return;

    // Get the computed height of the element
    const { width, height } = element.getBoundingClientRect();

    html2canvas(element, {
      width: Math.ceil(width), // Set the width of the canvas
      height: Math.ceil(height), // Set the height of the canvas
      scale: 3 // Optional: Increase the scale for higher resolution
    }).then((canvas: any) => {
      const link = document.createElement('a');
      link.href = canvas.toDataURL('image/png');
      link.download = `socialspicker${item.Id}-giveaway.png`;
      link.click();
    });
  };

  return (
    <Layout>
      <Helmet>
        <title>socialspicker.com - Create Giveaway Image for Instagram and others</title>
      </Helmet>

      <div className='w-full items-center text-center mb-4'>
        <h1 className="text-3xl font-bold mb-4">Giveaway Image Creator</h1>
        <div className='mb-4'>
          <p>Use our Giveaway Image Creator to announce the giveaway to your audience, select from many templates. You can post or use as a story.</p>
        </div>
      </div>

      <div className='w-full items-center text-center'>
        <input
          type="text"
          value={username}
          onChange={handleUsernameChange}
          placeholder="@username"
          className={`p-2 mb-4 w-full max-w-md ${getInputTextStyleClass()}`}
        />
      </div>
      <div className='w-full items-center text-center'>
        <textarea
          value={description}
          onChange={handleDescriptionChange}
          placeholder="Description..."
          rows={3}
          className={`py-2 px-2 w-full max-w-md ${getInputTextStyleClass()}`}
        ></textarea>
      </div>

      <div className="flex flex-wrap gap-4 mt-4">

        {giveawayImageTemplatesDto.map((item) => (
          <React.Fragment key={item.Id}>
            <div className="flex flex-col items-center w-full sm:w-auto">
              <div id={`template-${item.Id}`} className="relative w-full max-w-[400px] mx-auto bg-gray-200 rounded">
                <img src={`${item.TemplatePath}`} alt="Template" className="w-full h-auto rounded" />
                <div className="absolute inset-0 flex flex-col items-center justify-center text-center">
                  <div
                    className={`text-lg font-bold absolute max-w-[90%] block truncate overflow-hidden text-ellipsis whitespace-normal rounded py-4 px-1 ${item.BackgroundColorOpacityTitle}`}
                    style={{
                      top: item.UsernamePositionY,
                      textShadow: `
      -1px -1px 3px rgba(0, 0, 0, 1),
      1px -1px 3px rgba(0, 0, 0, 1),
      -1px 1px 3px rgba(0, 0, 0, 1),
      1px 1px 3px rgba(0, 0, 0, 1)
    `
                    }}
                  >
                    {username}
                  </div>
                  <div
                    className={`text-lg font-bold absolute max-w-[90%] overflow-hidden text-ellipsis whitespace-pre-line rounded py-4 px-1 ${item.BackgroundColorOpacityDesc}`}
                    style={{
                      top: item.DescriptionPositionY,
                      textShadow: `
      -1px -1px 3px rgba(0, 0, 0, 1),
      1px -1px 3px rgba(0, 0, 0, 1),
      -1px 1px 3px rgba(0, 0, 0, 1),
      1px 1px 3px rgba(0, 0, 0, 1)
    `
                    }}
                  >
                    {description}
                  </div>
                </div>
              </div>

              <div>
                <button
                  onClick={() => handleDownload(item)}
                  className={`mt-4 py-2 px-4 ${getButtonPrimaryStyleClass(false)}`}
                >
                  Download
                </button>
              </div>
            </div>
          </React.Fragment>
        ))}

      </div>

      <AdBlockModal />
    </Layout>
  );
};

export default CreateGiveawayImage;