import React, { useState } from 'react';
import Layout from '../../components/Layout';
import { Helmet } from 'react-helmet';
import './Help.css';
import { MaxWEnum } from '../../helpers/FormEnum';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { getBackgroundHover, getTextLinkStyleClass } from '../../config/FormSettings';
import { Link } from 'react-router-dom';

const Help: React.FC = () => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const toggleAnswer = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const faqData = [
    {
      question: "Why should I sign up?",
      answer: "1. All your transactions are recorded on our secure servers regardless of your device and browser.\n2. You can easily find your previous giveaways.\n3. You have a public profile which you can share with your followers.\n4. Public code certificate for your Giveaways which are easily accessible by anyone with the code."
    },
    {
      question: "How can I find the link to my giveaway post?",
      answer: "Click on the three dots at the top right of your Instagram post, similar for other platforms we support like Youtube, X, TikTok, etc., copy the link and paste your copied giveaway's link into the field in the socialspicker.com giveaway tools"
    },
    {
      question: "Can I add conditions and filters to Giveaways?",
      answer: "Yes, you can add filters to force participants to comply with certain conditions, such as @mentions, #hashtags, or filter duplicate users."
    },
    {
      question: "Can I filter who has shared my post in Stories?",
      answer: "No. We can't access the Stories of all giveaway participants to verify if they've shared your post. You will have to verify it yourself when running the giveaway and get the winners."
    },
    {
      question: "Can I download a list of giveaway participants?",
      answer: "Unfortunately, no."
    },
    {
      question: "Can I get a payment invoice?",
      answer: "It is important to us that you have a track record of all your payments, so you can download your payment invoice from the billing section of your account. You can also add your Business name, address and tax id."
    },
  ];

  return (
    <Layout>
      <Helmet>
        <title>socialspicker.com - Frequently Asked Questions / Help</title>
      </Helmet>

      <div className={`${MaxWEnum.MaxWXl} w-full`}>
        <div className='text-left w-full my-4'>
          <h2 className='font-semibold text-lg'>Frequently Asked Questions</h2>
        </div>
        <dl className="faq-list w-full">
          {faqData.map((item, index) => (
            <div key={index} className="faq-item">
              <dt>
                <button
                  className={`faq-question w-full ${getBackgroundHover()}`}
                  type="button"
                  aria-expanded={openIndex === index}
                  onClick={() => toggleAnswer(index)}
                >
                  <span>{item.question}</span>
                  <span className="icon">
                    {openIndex === index ? (
                      <FontAwesomeIcon icon={faMinus} />
                    ) : (
                      <FontAwesomeIcon icon={faPlus} />
                    )}
                  </span>
                </button>
              </dt>
              {openIndex === index && (
                <dd className="faq-answer w-full">
                  <pre style={{ fontFamily: "inherit", fontSize: "inherit", whiteSpace: "pre-wrap", wordWrap: "break-word" }} className='whitespace-pre-line text-base leading-7'>{item.answer}</pre>
                </dd>
              )}
            </div>
          ))}
        </dl>

        <div className='mx-auto text-center items-center w-full mt-8'>
          <p className='font-semibold text-lg'>Do you have more questions?</p>
          <Link className={`${getTextLinkStyleClass()}`} to="mailto:hq@socialspicker.com">hq@socialspicker.com</Link>
        </div>
      </div>
    </Layout>
  );
};

export default Help;