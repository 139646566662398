import React from 'react';
import Layout from '../../components/Layout';
import { useForm, SubmitHandler } from 'react-hook-form';
import { Helmet } from 'react-helmet';
import axiosInstance from '../config/axiosInstance';
import { Link } from 'react-router-dom';
import notificationService from '../../services/NotificationService';
import { useIsLoading } from '../../hooks/useIsLoading';
import { useLoadingMessage } from '../../hooks/useLoadingMessage';
import { LoadingMessageEnum as LoadingMessage } from '../../helpers/LoadingMessageEnum';
import { RoutesEnum as AppRoutes } from '../../routes/RoutesEnum';
import { ApiRoutesEnum as ApiRoutes } from '../../routes/ApiRoutesEnum';
import useLoadRecaptcha from '../../hooks/useLoadRecaptcha';
import { canSubmit } from '../../helpers/RateLimitActionHelper';
import { getButtonPrimaryStyleClass, getInputTextStyleClass, getStandardCardBackground, getTextLinkStyleClass } from '../../config/FormSettings';

interface ResetPasswordFormInputs {
  email: string;
}

const ResetPassword: React.FC = () => {
  const { register, handleSubmit, formState: { errors }, reset } = useForm<ResetPasswordFormInputs>();
  const { isLoading, setIsLoading } = useIsLoading();
  const { loadingMessage, setLoadingMessage } = useLoadingMessage();
  const isRecaptchaLoaded = useLoadRecaptcha();

  const onSubmit: SubmitHandler<ResetPasswordFormInputs> = async data => {
    const handleSubmitForm = async () => {
      try {
        setIsLoading(true);
        setLoadingMessage(LoadingMessage.Message);

        if (!isRecaptchaLoaded) {
          setLoadingMessage('Loading reCAPTCHA...');
          setTimeout(handleSubmitForm, 1000);
          return;
        }

        let recaptchaToken;
        try {
          recaptchaToken = await window.grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY as string, { action: 'submit' });
        } catch (error) {
          notificationService.setMessage({
            message: 'reCAPTCHA failed. Please try again.',
            status: false
          });

          setIsLoading(false);

          return;
        }

        const formData = { ...data, recaptchaToken };

        if (!canSubmit()) {
          setIsLoading(false);

          return;
        }

        const response = await axiosInstance.post(ApiRoutes.RESET_PASSWORD, formData, {

        });

        const { status, message } = response.data;

        reset();

        notificationService.setMessage({
          message: message,
          status: status
        });

        setIsLoading(false);
      } catch (error: any) {
        notificationService.setError(error);

        setIsLoading(false);
      }
    };

    handleSubmitForm();
  };

  return (
    <Layout>
      <Helmet>
        <title>socialspicker.com - Reset Your Password</title>
      </Helmet>
      <Link to={AppRoutes.HOME} className="mb-8">
        <img src="/assets/logoBlack.svg" alt="Logo" className="w-full h-auto max-w-3xs" />
      </Link>
      <div className={`${getStandardCardBackground()}`}>
        <h2 className="text-2xl font-bold mb-6">Reset Your Password</h2>
        <form onSubmit={handleSubmit(onSubmit)} className='w-full'>
          <div className="mb-4">
            <input
              type="email"
              {...register('email', {
                required: 'Email is required',
                pattern: {
                  value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                  message: 'Invalid email address'
                },
                maxLength: { value: 100, message: "Email cannot exceed 100 characters" }
              })}
              maxLength={100}
              placeholder='Email'
              className={`${getInputTextStyleClass()} w-full p-2 mt-1`}
            />
            {errors.email && <p className="text-red-500 text-sm">{errors.email.message}</p>}
          </div>
          <button
            type="submit"
            className={`${getButtonPrimaryStyleClass(false)} w-full mt-4 mb-2`}
            disabled={isLoading}
          >
            {isLoading ? loadingMessage : "Reset Password"}
          </button>
        </form>
        <div className="mt-4 text-center">
          <Link to={AppRoutes.LOGIN} className={`${getTextLinkStyleClass()}`}>I Remember my password</Link>
        </div>
      </div>
    </Layout>
  );
};

export default ResetPassword;