import { useNavigate, useParams } from 'react-router-dom';
import Layout from '../components/Layout';
import { Helmet } from 'react-helmet';
import { useEffect, useState } from 'react';
import axiosInstance, { CustomAxiosRequestConfig } from '../auth/config/axiosInstance';
import { ApiRoutesEnum } from '../routes/ApiRoutesEnum';
import NotificationService from '../services/NotificationService';
import { RoutesEnum } from '../routes/RoutesEnum';
import LoadingScreen from '../components/loadings/LoadingScreen';
import { prettifyNumbers } from '../helpers/StringsHelper';
import { UserDataDTO } from '../dto/UserDataDTO';
import { getButtonSuccessStyleClass, getInputTextStyleClass, getStandardCardBackground } from '../config/FormSettings';
import { MaxWEnum } from '../helpers/FormEnum';
import { useAuthContext } from '../auth/contexts/AuthContext';
import { SubmitHandler, useForm } from 'react-hook-form';
import { getDefinedRoutesWithoutParams } from '../helpers/RoutesHelper';

interface AccountInfoFormInputs {
  name: string;
  username: string;
  email: string;
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}

const P: React.FC = () => {
  const { register, handleSubmit, formState: { errors }, watch } = useForm<AccountInfoFormInputs>();
  const navigate = useNavigate();
  const { getUserDataFromToken } = useAuthContext();
  const { username } = useParams<{ username: string }>();
  const [isProfileLoading, setIsProfileLoading] = useState<boolean>(false);
  const [profileData, setProfileData] = useState<{ campaignCount: number; totalParticipantsCount: number; totalWinnersCount: number; }>({ campaignCount: 0, totalParticipantsCount: 0, totalWinnersCount: 0 });
  const [profile, setProfile] = useState<UserDataDTO | null>(null);
  const [isProfileOwner, setIsProfileOwner] = useState<boolean>(false);
  const [isLoadingAccountInfo, setIsLoadingAccountInfo] = useState<boolean>(false);

  useEffect(() => {
    const getProfileData = async () => {
      try {
        setIsProfileLoading(true);

        const response = await axiosInstance.get(ApiRoutesEnum.GET_USER_BY_USERNAME,
          {
            authNeeded: true,
            params: {
              username: username,
            }
          } as CustomAxiosRequestConfig);

        const { user, campaignCount, totalParticipantsCount, totalWinnersCount } = response.data;

        setProfileData({
          campaignCount: campaignCount,
          totalParticipantsCount: totalParticipantsCount,
          totalWinnersCount: totalWinnersCount,
        });

        const profileData: UserDataDTO = {
          Id: user.id,
          Email: user.email,
          Name: user.name,
          Username: user.username,
          Verified: user.verified,
          UserCreatedDateString: user.userCreatedDateString,
        };

        if (!profileData.Verified) {
          setIsProfileLoading(false);

          navigate(RoutesEnum.NOT_FOUND);

          return;
        }

        setProfile(profileData);

        var currentUser = await getUserDataFromToken(navigate, false);

        if (profileData.Id === currentUser?.Id)
          setIsProfileOwner(true);

        setIsProfileLoading(false);
      }
      catch (error: any) {
        NotificationService.setError(error);

        setIsProfileLoading(false);

        navigate(RoutesEnum.NOT_FOUND);
      }
    };

    const init = async () => {
      await getProfileData();
    };

    init();
  }, []);

  const onSubmitAccountInfo: SubmitHandler<AccountInfoFormInputs> = async (data: any) => {
    const handleSubmitForm = async () => {
      try {
        setIsLoadingAccountInfo(true);

        //account info change

        //change password

        //if new password and confirm password are set without the current password, we display fail
        if (!data.currentPassword && data.newPassword && data.confirmPassword) {
          NotificationService.setMessage({
            message: "Set the current password",
            status: false
          });

          setIsLoadingAccountInfo(false);

          return;
        }

        var currentPassword = (data.currentPassword === "" || data.currentPassword === null) ? null : data.currentPassword;

        if (currentPassword) {
          if (data.newPassword !== data.confirmPassword) {
            NotificationService.setMessage({
              message: "New Password and Confirm Password do not match",
              status: false
            });

            setIsLoadingAccountInfo(false);

            return;
          }
        }

        var newPassword = data.newPassword;

        const formData = {
          Name: data.name,
          Username: data.username,
          ...(currentPassword && { CurrentPassword: currentPassword }),
          ...(newPassword && { NewPassword: newPassword })
        };

        const response = await axiosInstance.post(
          ApiRoutesEnum.EDIT_USER,
          formData,
          { authNeeded: true } as CustomAxiosRequestConfig
        );

        const { status, message } = response.data;

        NotificationService.setMessage({
          status: status,
          message: message
        });

        //we get fresh data to update the token
        await getUserDataFromToken(navigate, true);

        //email change

        var newEmail = (profile?.Email !== data.email) ? data.email : null;

        if (newEmail) {
          const formDataEmailVerify = {
            Email: profile?.Email,
            ...(newEmail && { NewEmail: data.email })
          };

          const responseEmailVerify = await axiosInstance.post(
            ApiRoutesEnum.EMAIL_VERIFY,
            formDataEmailVerify,
            { authNeeded: true } as CustomAxiosRequestConfig);

          NotificationService.setMessage({
            message: responseEmailVerify.data.message,
            status: responseEmailVerify.data.status
          });
        }

        setIsLoadingAccountInfo(false);

        setProfile((prevState: UserDataDTO | null) => {
          if (!prevState) return null;

          return {
            ...prevState,
            Name: data.name,
            Username: data.username,
          };
        });

        navigate(getDefinedRoutesWithoutParams(RoutesEnum.P) + "@" + decodeURIComponent(data.username));

        return;
      } catch (error: any) {
        NotificationService.setError(error);

        setIsLoadingAccountInfo(false);
      }
    };

    handleSubmitForm();
  };

  return (
    <Layout>
      <Helmet>
        <title>socialspicker.com - Campaign</title>
      </Helmet>
      <div className="relative w-full">

        <div className={`w-full mx-auto`}>
          {isProfileLoading ? (
            <>
              <LoadingScreen
                loadingMessage={`Loading profile ${username}...`}
              />
            </>
          ) : (
            <>
              <div className={`${getStandardCardBackground(false, MaxWEnum.MaxWLg, undefined, undefined, undefined, true, false)}`}>
                <p className={`text-lg font-semibold`}>
                  @{profile?.Username}
                </p>
                <p className={`text-lg font-semibold mt-4`}>
                  {profile?.Name}
                </p>
                <p className={`text-sm mt-4`}>
                  Member since {profile?.UserCreatedDateString}
                </p>
              </div>

              {isProfileOwner && (
                <>
                  <form onSubmit={handleSubmit(onSubmitAccountInfo)} className='w-full max-w-lg mx-auto'>
                    <p className={`text-lg font-semibold mb-4`}>
                      Account info
                    </p>
                    <div className="mb-4">
                      <span className='text-sm'>Name</span>
                      <input
                        type="text"
                        {...register('name', {
                          required: 'Name is required'
                        })}
                        maxLength={100}
                        defaultValue={profile?.Name}
                        placeholder='Name'
                        className={`${getInputTextStyleClass()} w-full py-2 px-2`}
                      />
                      {errors.name && <p className="text-red-500 text-sm">{errors.name.message}</p>}
                    </div>
                    <div className="mb-4">
                      <span className='text-sm'>Username</span>
                      <input
                        type="text"
                        {...register('username', {
                          required: 'Username is required',
                          maxLength: { value: 50, message: "Username cannot exceed 50 characters" },
                          onChange: (e) => {
                            e.target.value = e.target.value.replace(/\s+/g, '');
                          },
                        })}
                        maxLength={50}
                        defaultValue={profile?.Username}
                        placeholder='Username'
                        className={`${getInputTextStyleClass()} w-full py-2 px-2`}
                      />
                      {errors.username && <p className="text-red-500 text-sm">{errors.username.message}</p>}
                    </div>
                    <div className="mb-4">
                      <span className='text-sm'>Email</span>
                      <input
                        type="email"
                        {...register('email', {
                          required: 'Email is required',
                          maxLength: { value: 100, message: "Email cannot exceed 100 characters" }
                        })}
                        maxLength={100}
                        defaultValue={profile?.Email}
                        placeholder='Email'
                        className={`${getInputTextStyleClass()} w-full py-2 px-2`}
                      />
                      {errors.email && <p className="text-red-500 text-sm">{errors.email.message}</p>}
                    </div>
                    <button
                      type="submit"
                      className={`${getButtonSuccessStyleClass(false)} w-full mt-4 mb-2`}
                      disabled={isLoadingAccountInfo}
                    >
                      {isLoadingAccountInfo ? "Wait..." : "Save"}
                    </button>

                    <p className={`text-lg font-semibold mb-4 mt-4`}>
                      Change Password
                    </p>
                    <div className="mb-4">
                      <span className='text-sm'>Current Password</span>
                      <input
                        type="password"
                        {...register('currentPassword', {
                          minLength: { value: 6, message: 'Password must be at least 6 characters' },
                          maxLength: { value: 20, message: "Password cannot exceed 20 characters" }
                        })}
                        maxLength={20}
                        placeholder='Current Password'
                        className={`${getInputTextStyleClass()} w-full p-2 mt-1`}
                      />
                      {errors.currentPassword && <p className="text-red-500 text-sm">{errors.currentPassword.message}</p>}
                    </div>
                    <div className="mb-4">
                      <span className='text-sm'>New Password</span>
                      <input
                        type="password"
                        {...register('newPassword', {
                          minLength: { value: 6, message: 'Password must be at least 6 characters' },
                          maxLength: { value: 20, message: "Password cannot exceed 20 characters" }
                        })}
                        maxLength={20}
                        placeholder='New Password'
                        className={`${getInputTextStyleClass()} w-full p-2 mt-1`}
                      />
                      {errors.newPassword && <p className="text-red-500 text-sm">{errors.newPassword.message}</p>}
                    </div>
                    <div className="mb-4">
                      <span className='text-sm'>Confirm Password</span>
                      <input
                        type="password"
                        {...register('confirmPassword', {
                          minLength: { value: 6, message: 'Password must be at least 6 characters' },
                          maxLength: { value: 20, message: "Password cannot exceed 20 characters" }
                        })}
                        maxLength={20}
                        placeholder='Confirm Password'
                        className={`${getInputTextStyleClass()} w-full p-2 mt-1`}
                      />
                      {errors.confirmPassword && <p className="text-red-500 text-sm">{errors.confirmPassword.message}</p>}
                    </div>
                    <button
                      type="submit"
                      className={`${getButtonSuccessStyleClass(false)} w-full mt-4 mb-2`}
                      disabled={isLoadingAccountInfo}
                    >
                      {isLoadingAccountInfo ? "Wait..." : "Save"}
                    </button>
                  </form>
                </>
              )}

              <div className={`${getStandardCardBackground(true, MaxWEnum.MaxWLg, undefined, undefined, undefined, true, false)} mt-4 p-8 bg-gradient-to-r from-primary to-secondary`}>
                <p className={`text-2xl font-semibold text-white text-left`}>
                  <span className='text-5xl'>{prettifyNumbers(profileData.campaignCount)}</span> Giveaways
                </p>
                <p className={`text-base font-semibold text-white text-right mt-4`}
                >
                  Giveaways via socialspicker.com
                </p>
              </div>

              <div className={`${getStandardCardBackground(true, MaxWEnum.MaxWLg, undefined, undefined, undefined, true, false)} mt-4 p-8 bg-gradient-to-r from-primary to-secondary`}>
                <p className={`text-2xl font-semibold text-white text-left`}>
                  <span className='text-5xl'>{prettifyNumbers(profileData.totalParticipantsCount)}</span> Participants
                </p>
                <p className={`text-base font-semibold text-white text-right mt-4`}
                >
                  Received Participants via socialspicker.com
                </p>
              </div>

              <div className={`${getStandardCardBackground(true, MaxWEnum.MaxWLg, undefined, undefined, undefined, true, false)} mt-4 p-8 bg-gradient-to-r from-primary to-secondary`}>
                <p className={`text-2xl font-semibold text-white text-left`}
                >
                  <span className='text-5xl'>{prettifyNumbers(profileData.totalWinnersCount)}</span> Winners
                </p>
                <p className={`text-base font-semibold text-white text-right mt-4`}>
                  People given gifts via socialspicker.com
                </p>
              </div>
            </>
          )}
        </div>

      </div>
    </Layout>
  );
};

export default P;