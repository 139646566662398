import React, { useRef, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import notificationService from '../services/NotificationService';
import { ApiRoutesEnum } from '../routes/ApiRoutesEnum';
import { getBackgroundActive, getBackgroundHover, getButtonSuccessStyleClass } from '../config/FormSettings';
import loggerService from '../services/LoggerService';
import { participantCreditPackages } from '../config/envConfig';
import { prettifyNumbers } from '../helpers/StringsHelper';
import useIsMobile from '../hooks/useIsMobile';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faListNumeric, faX } from '@fortawesome/free-solid-svg-icons';
import { faInstagram, faTiktok, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { useLocation } from 'react-router-dom';
import AuthModal from '../auth/components/modals/AuthModal';
import LoginAuth from '../auth/components/LoginAuth';
import { AuthLinkLabelsDisplayEnum } from '../auth/helpers/AuthLinkLabelsDisplayEnum';
import SignupAuth from '../auth/components/SignupAuth';
import { useAuthContext } from '../auth/contexts/AuthContext';
import axiosInstance, { CustomAxiosRequestConfig } from '../auth/config/axiosInstance';
import { useIsLoading } from '../hooks/useIsLoading';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY as string);

const Checkout: React.FC = () => {
  const { isLoggedIn } = useAuthContext();
  const { isLoading, setIsLoading } = useIsLoading();
  const { isMobile } = useIsMobile();
  const location = useLocation();
  const [selectedPackage, setSelectedPackage] = useState(participantCreditPackages[0]);
  const selectedPackageRef = useRef<HTMLDivElement | null>(null);
  const [isAuthModalOpen, setIsAuthModalOpen] = useState<boolean>(false);
  const [authLinkLabelsDisplayEnum, setAuthLinkLabelsDisplayEnum] = useState<AuthLinkLabelsDisplayEnum>(AuthLinkLabelsDisplayEnum.LOGIN);

  const handleCheckout = async () => {
    try {
      setIsLoading(true);

      if (!await isValidAuth())
        return;

      const returnPath = `${location.pathname}${location.search}`;

      const stripe = await stripePromise;

      if (!stripe)
        throw new Error("Failed to load stripePromise");

      var formData = {
        credits: { ...selectedPackage },
        returnPath: returnPath,
      }

      const response = await axiosInstance.post(
        ApiRoutesEnum.CREATE_CHECKOUT_SESSION,
        formData,
        { authNeeded: true } as CustomAxiosRequestConfig);

      const { sessionId } = response.data;

      const { error } = await stripe.redirectToCheckout({ sessionId });

      if (error) {
        throw error;
      }
    } catch (error) {
      loggerService.error(error);

      notificationService.setMessage({
        message: "Failed to create payment",
        status: false,
      });
    }
    finally {
      setIsLoading(false);
    }
  };

  const handlePackageClick = (pkg: typeof participantCreditPackages[0]) => {
    setSelectedPackage(pkg);

    if (isMobile) {
      selectedPackageRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const isValidAuth = async (): Promise<boolean> => {
    if (!(await isLoggedIn())) {
      setIsAuthModalOpen(true);

      return false;
    }

    return true;
  };

  return (
    <>
      <h2 className="text-xl font-bold mb-6">Choose Your Package</h2>
      <div className="flex flex-col md:flex-row">
        <div className="w-full md:w-1/2 pr-0 md:pr-4 mb-4 md:mb-0">
          <div className="flex flex-wrap">
            {participantCreditPackages.map((pkg) => (
              <button
                key={pkg.ParticipantCredits}
                onClick={() => handlePackageClick(pkg)}
                className={`p-2 m-2 rounded-lg shadow-lg flex-col w-full md:w-[calc(45%-1rem)] min-h-[90px] group items-center justify-between ${getBackgroundHover()}
                ${selectedPackage.ParticipantCredits === pkg.ParticipantCredits ? `${getBackgroundActive()}` : 'bg-white text-gray-800 border border-gray-300'}`}
              >
                <div className="text-base">
                  <span className={`text-md font-semibold
                    ${selectedPackage.ParticipantCredits === pkg.ParticipantCredits ? `text-white` : ''}
                    `}>{prettifyNumbers(pkg.ParticipantCredits)}</span> participant credits
                </div>
                <div className={`text-xl font-bold group-hover:text-white
                  ${selectedPackage.ParticipantCredits === pkg.ParticipantCredits ? `text-white` : 'text-success'}`}>
                  <span className={`text-sm`}>$</span> {pkg.Price.toFixed(2)}
                </div>
              </button>
            ))}
          </div>
        </div>
        <div className="w-full md:w-1/2" ref={selectedPackageRef}>
          <div className="p-4 bg-successBackground rounded-lg shadow-lg transition-transform duration-500 transform justify-start text-left">
            <h3 className="text-xl font-bold mb-2">Selected Package</h3>
            <div className="text-1xl pt-2 font-semibold transition-colors duration-500 text-success">
              {prettifyNumbers(selectedPackage.ParticipantCredits)} participant credits.
            </div>
            <div className="text-gray-800 text-sm pt-2 font-semibold">
              1 Giveaway up to {prettifyNumbers(selectedPackage.ParticipantCredits)} comments, retweets or entries
            </div>
            <div className="text-gray-800 text-sm pt-4">
              Only
            </div>
            <div className="text-[30px] pb-2 font-semibold transition-colors duration-500 text-success">
              <span className='text-[20px]'>$</span> {selectedPackage.Price.toFixed(2)}
            </div>
            <div className="p-2 w-full">
              <button className={`${getButtonSuccessStyleClass(true)} w-full`} onClick={handleCheckout}
                disabled={isLoading}
              >
                {isLoading ? "Rolling payment..." : "Buy Now"}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="mx-auto shadow-md mt-4 mb-4 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
        <div className="p-8 sm:p-10 lg:flex-auto">
          <div className="flex items-center gap-x-4">
            <h4 className="flex-none text-sm font-semibold leading-6 text-gray-800">What’s included</h4>
            <div className="h-px flex-auto bg-gray-100"></div>
          </div>
          <ul className="mt-8 grid grid-cols-1 gap-4 text-sm items-center leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6">
            <li className="flex gap-x-3 items-center">
              <FontAwesomeIcon icon={faCheck} className='text-success mr-2' />
              <FontAwesomeIcon icon={faInstagram} className='text-instagram' />
              Up to 500,000 Instagram Comments per Giveaway
            </li>
            <li className="flex gap-x-3 items-center">
              <FontAwesomeIcon icon={faCheck} className='text-success mr-2' />
              <FontAwesomeIcon icon={faTiktok} className='text-tiktok' />
              Up to 500,000 TikTok Comments per Giveaway
            </li>
            <li className="flex gap-x-3 items-center">
              <FontAwesomeIcon icon={faCheck} className='text-success mr-2' />
              <FontAwesomeIcon icon={faX} className='text-twitter' />
              Up to 500,000 Twitter / X Retweeters per Giveaway
            </li>
            <li className="flex gap-x-3 items-center">
              <FontAwesomeIcon icon={faCheck} className='text-success mr-2' />
              <FontAwesomeIcon icon={faYoutube} className='text-youtube' />
              Up to 500,000 Youtube Comments per Giveaway
            </li>
            <li className="flex gap-x-3 items-center">
              <FontAwesomeIcon icon={faCheck} className='text-success mr-2' />
              <FontAwesomeIcon icon={faListNumeric} className='text-randomNameWinner' />
              Up to 500,000 Random Name Winner List Participants per Giveaway
            </li>
          </ul>
        </div>
      </div>

      <AuthModal isOpen={isAuthModalOpen} onClose={() => setIsAuthModalOpen(false)}>
        {authLinkLabelsDisplayEnum === AuthLinkLabelsDisplayEnum.LOGIN ? (
          <LoginAuth
            isAuthModal={true}
            setIsModalOpen={setIsAuthModalOpen}
            setAuthLinkLabelsDisplayEnum={setAuthLinkLabelsDisplayEnum}
            authLinkLabelsDisplayEnum={authLinkLabelsDisplayEnum}
          />
        ) :
          <SignupAuth
            isAuthModal={true}
            setIsModalOpen={setIsAuthModalOpen}
            setAuthLinkLabelsDisplayEnum={setAuthLinkLabelsDisplayEnum}
            authLinkLabelsDisplayEnum={authLinkLabelsDisplayEnum}
          />
        }
      </AuthModal>
    </>
  );
};

export default Checkout;