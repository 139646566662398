import React, { useEffect, useRef } from 'react';
import CloseButton from '../buttons/CloseButton';
import { getButtonErrorStyleClass, getButtonSuccessStyleClass } from '../../config/FormSettings';
import { useCampaignContext } from '../../contexts/CampaignContext';
import { CampaignViewEnum } from '../../helpers/CampaignViewEnum';
import Tooltip from '../Tooltip';
import NotificationService from '../../services/NotificationService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { getMaxLength } from '../../helpers/StringsHelper';
import { generateCampaignCodeUrl } from '../../helpers/CampaignHelper';

interface ConfirmCampaignModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const CampaignShareModal: React.FC<ConfirmCampaignModalProps> = ({ isOpen, onClose }) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const {
    campaign,
  } = useCampaignContext();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div
      className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 overflow-auto">
      <div
        ref={modalRef}
        className="relative bg-white p-6 rounded shadow-lg w-full max-w-lg mx-4 sm:mx-0 max-h-[90vh] overflow-y-auto">
        <div className='flex items-center justify-center absolute top-2 right-2'>
        </div>
        <div className="flex flex-col w-full my-4 text-center">
          <div className="font-semibold p-2 rounded-md mb-4 text-primary">
            Via socialspicker.com Giveaway
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-2">
            <div>
              <div className='w-full'>
                Result Code
              </div>
              <div className='w-full text-sm'>
                Your followers can access the results via this code
              </div>
            </div>
            <div className="inline-block text-center rounded shadow-md bg-gray-100 p-1 cursor-pointer items-center flex"
              onClick={() => {
                navigator.clipboard.writeText(campaign?.Code ?? "");
                NotificationService.setMessage({
                  message: "Copied",
                  status: true,
                });
              }
              }>
              <Tooltip text="Click to copy">
                <span className='font-semibold text-primary'>{campaign?.Code}</span>
                <FontAwesomeIcon className='ml-2' icon={faCopy} />
              </Tooltip>
            </div>
          </div>
          <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-2">
            <div>
              <div className='w-full'>
                Result Link
              </div>
              <div className='w-full text-sm'>
                Your followers can view the results via this link
              </div>
            </div>
            <div className="inline-block text-center rounded shadow-md bg-gray-100 p-1 cursor-pointer items-center flex"
              onClick={() => {
                navigator.clipboard.writeText(generateCampaignCodeUrl(campaign?.Code ?? ""));
                NotificationService.setMessage({
                  message: "Copied",
                  status: true,
                });
              }
              }>
              <Tooltip text="Click to copy">
                <span className='font-semibold text-primary break-all'>{generateCampaignCodeUrl(campaign?.Code ?? "")}</span>
                <FontAwesomeIcon className='ml-2' icon={faCopy} />
              </Tooltip>
            </div>
          </div>
          <div className="rounded-md">
            <div className="px-5 mt-6 text-center">
              <div className="grid grid-cols-1">
                <CloseButton
                  text="Close"
                  onClick={onClose}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampaignShareModal;