import React, { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../../components/Layout';
import axiosInstance, { CustomAxiosRequestConfig } from '../../auth/config/axiosInstance';
import { ApiRoutesEnum as ApiRoutes } from '../../routes/ApiRoutesEnum';
import { useIsLoading } from '../../hooks/useIsLoading';
import { useLoadingMessage } from '../../hooks/useLoadingMessage';
import { LoadingMessageEnum as LoadingMessage } from '../../helpers/LoadingMessageEnum';
import notificationService from '../../services/NotificationService';
import { Pie } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend
} from 'chart.js';
import { useReactTable, ColumnDef, getCoreRowModel, getPaginationRowModel, PaginationState, flexRender } from '@tanstack/react-table';
import { UserDataDTO } from '../../dto/UserDataDTO';
import SPPagination from '../../components/SPPagination';
import { getButtonPrimaryStyleClass, getInputTextStyleClass, getMainCardBackground } from '../../config/FormSettings';
import { paginationSize } from '../../config/envConfig';
import { SocialPlatformEnum } from '../../helpers/SocialPlatformEnum';
import { CampaignTypeEnum } from '../../helpers/CampaignTypeEnum';
import Credits from '../../components/Credits';
import useIsMobile from '../../hooks/useIsMobile';
import EditUserModal from '../../components/admin/modals/EditUserModal';

ChartJS.register(ArcElement, Tooltip, Legend);

const AdminDashboard: React.FC = () => {
  const { isMobile } = useIsMobile();
  const [isSearchTerm, setIsSearchTerm] = useState<boolean>(false);
  const { isLoading, setIsLoading } = useIsLoading();
  const { loadingMessage, setLoadingMessage } = useLoadingMessage();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [usersData, setUsersData] = useState<UserDataDTO[]>([]);
  const [usersDataStatistics, setUsersDataStatistics] = useState<{ admins: UserDataDTO[]; adminsEmail: string; totalCountUsers: number; totalCountVerifiedUsers: number; }>({ admins: [], adminsEmail: "", totalCountUsers: 0, totalCountVerifiedUsers: 0 });
  const [userAdmins, setUserAdmins] = useState<UserDataDTO[]>([]);
  const [campaignsDataStatistics, setCampaignsDataStatistics] = useState<{
    campaignTypeCommentsCount: number,
    campaignTypeListCount: number,
    campaignTypeRepostsCount: number,
    facebookCount: number,
    instagramCount: number,
    tiktokCount: number,
    youtubeCount: number,
    twitterCount: number,
    listCount: number,
    totalCount: number
  }>({
    campaignTypeCommentsCount: 0,
    campaignTypeListCount: 0,
    campaignTypeRepostsCount: 0,
    facebookCount: 0,
    instagramCount: 0,
    tiktokCount: 0,
    youtubeCount: 0,
    twitterCount: 0,
    listCount: 0,
    totalCount: 0
  });
  const [pagination, setPagination] = useState<PaginationState>({ pageIndex: 0, pageSize: 15 });
  const [currentPage, setCurrentPage] = useState(1);
  const pageCount = Math.ceil(usersDataStatistics.totalCountUsers / paginationSize);
  const [selectedUser, setSelectedUser] = useState<UserDataDTO | null>(null);
  const [isEditUserModalOpen, setIsEditUserModalOpen] = useState(false);

  useEffect(() => {
    fetchData();
  }, [currentPage]);

  useEffect(() => {
    if (isSearchTerm) {
      fetchData();
    }
  }, [isSearchTerm]);

  const handleEditUserModalOpen = (user: UserDataDTO) => {
    setSelectedUser(user);
    setIsEditUserModalOpen(true);
  };

  const handleEditUserModalClose = () => {
    setSelectedUser(null);
    setIsEditUserModalOpen(false);

    fetchData();
  };

  const fetchData = async () => {
    try {
      setIsLoading(true);
      setLoadingMessage(LoadingMessage.Message);

      const response = await axiosInstance.get(ApiRoutes.GET_USERS, {
        authNeeded: true,
        params: {
          pageNumber: currentPage,
          pageSize: paginationSize,
          ...(isSearchTerm && { searchEmailOrUsernameOrName: searchTerm })
        }
      } as CustomAxiosRequestConfig);

      setIsSearchTerm(false);

      const usersData: UserDataDTO[] = response.data.users.map((user: any) => ({
        Id: user.id,
        Email: user.email,
        Name: user.name,
        Username: user.username,
        Verified: user.verified,
        Role: user.role,
        CampaignsCount: user.campaignsCount,
        Credits: user.credits,
        UserCreatedDateString: user.userCreatedDateString,
      }));

      setUsersData(usersData);

      const usersAdmin: UserDataDTO[] = response.data.usersAdmin.map((user: any) => ({
        Id: user.id,
        Email: user.email,
        Name: user.name,
        Username: user.username,
      }));

      setUserAdmins(usersAdmin);

      const reponseGetCampaigns = await axiosInstance.get(ApiRoutes.GET_CAMPAIGNS_STATISTICS, { authNeeded: true } as CustomAxiosRequestConfig);

      setCampaignsDataStatistics({
        campaignTypeCommentsCount: reponseGetCampaigns.data.campaignTypeCommentsCount,
        campaignTypeListCount: reponseGetCampaigns.data.campaignTypeListCount,
        campaignTypeRepostsCount: reponseGetCampaigns.data.campaignTypeRepostsCount,
        facebookCount: reponseGetCampaigns.data.facebookCount,
        instagramCount: reponseGetCampaigns.data.instagramCount,
        tiktokCount: reponseGetCampaigns.data.tiktokCount,
        youtubeCount: reponseGetCampaigns.data.youtubeCount,
        twitterCount: reponseGetCampaigns.data.twitterCount,
        listCount: reponseGetCampaigns.data.listCount,
        totalCount: reponseGetCampaigns.data.totalCount,
      });

      const adminsEmail = usersAdmin
        .map(usersAdmin => usersAdmin.Email + " " + usersAdmin.Name + " / " + usersAdmin.Username)
        .join(', ');
      setUsersDataStatistics({
        admins: usersAdmin,
        adminsEmail: adminsEmail,
        totalCountUsers: response.data.totalCountUsers,
        totalCountVerifiedUsers: response.data.totalCountVerifiedUsers
      });
    } catch (error: any) {
      notificationService.setError(error);
    }
    finally {
      setIsLoading(false);
    }
  };

  const chartData = {
    labels: ['Verified', 'Not Verified'],
    datasets: [
      {
        data: [usersDataStatistics.totalCountVerifiedUsers, usersDataStatistics.totalCountUsers - usersDataStatistics.totalCountVerifiedUsers],
        backgroundColor: ['#4CAF50', '#F44336'],
      },
    ],
  };

  const roleChartData = {
    labels: ['Admin', 'User'],
    datasets: [
      {
        data: [userAdmins.length, usersDataStatistics.totalCountUsers],
        backgroundColor: ['#2196F3', '#FFC107'],
      },
    ],
  };

  const chartCampaignSocialPlatform = {
    labels: [SocialPlatformEnum.Facebook, SocialPlatformEnum.Instagram, SocialPlatformEnum.List, SocialPlatformEnum.Tiktok, SocialPlatformEnum.Twitter, SocialPlatformEnum.Youtube],
    datasets: [
      {
        data: [campaignsDataStatistics.facebookCount, campaignsDataStatistics.instagramCount, campaignsDataStatistics.listCount, campaignsDataStatistics.tiktokCount, campaignsDataStatistics.twitterCount, campaignsDataStatistics.youtubeCount],
        backgroundColor: ['#2196F3', '#FFC107', '#4CAF50', '#FF5722', '#9C27B0', '#E91E63'],
      },
    ],
  };

  const chartCampaignCampaignType = {
    labels: [CampaignTypeEnum.Comments, CampaignTypeEnum.List, CampaignTypeEnum.Reposts],
    datasets: [
      {
        data: [campaignsDataStatistics.campaignTypeCommentsCount, campaignsDataStatistics.campaignTypeListCount, campaignsDataStatistics.campaignTypeRepostsCount],
        backgroundColor: ['#2196F3', '#FFC107', '#4CAF50'],
      },
    ],
  };

  const handlePageChange = (selected: { selected: number }) => {
    setCurrentPage(selected.selected + 1);
  };

  const columns = useMemo<ColumnDef<UserDataDTO>[]>(
    () => [
      {
        accessorKey: 'Id',
        header: 'ID',
        cell: info => info.getValue(),
      },
      {
        accessorKey: 'Email',
        header: 'Email',
        cell: info => info.getValue()
      },
      {
        accessorKey: 'Name',
        header: 'Name',
        cell: info => info.getValue()
      },
      {
        accessorKey: 'Username',
        header: 'Username',
        cell: info => info.getValue()
      },
      {
        accessorKey: 'Credits',
        header: 'Credits',
        cell: info => info.getValue()
      },
      {
        accessorKey: 'Verified',
        header: 'Verified',
        cell: info => (info.getValue() ? 'Yes' : 'No'),
      },
      {
        accessorKey: 'Role',
        header: 'Role',
        cell: info => info.getValue()
      },
      {
        accessorKey: 'CampaignsCount',
        header: 'Campaigns',
        cell: info => info.getValue()
      },
      {
        accessorKey: 'UserCreatedDateString',
        header: 'Created Date',
        cell: info => info.getValue()
      },
      {
        // New column for the modal button
        id: 'actions', // Unique identifier for the column
        header: 'Actions',
        cell: ({ row }) => (
          <button
            onClick={() => handleEditUserModalOpen(row.original)}
            className={`${getButtonPrimaryStyleClass(false)}`}
          >
            Edit
          </button>
        ),
      },
    ],
    []
  );

  const table = useReactTable({
    data: usersData || [],
    columns,
    state: { pagination },
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  return (
    <Layout>
      <Helmet>
        <title>socialspicker.com - Admin Dashboard</title>
      </Helmet>
      <div className={`flex flex-col items-center p-4`}>
        <h1 className="text-2xl font-bold">Admin Dashboard {isLoading && (loadingMessage)}</h1>

        <div className="mt-2">
          <p className="text-lg font-semibold">Accounts:</p>
        </div>
        <div className="mt-2">
          <h2 className="text-base">Admins: {isLoading ? loadingMessage : usersDataStatistics.adminsEmail || 'No admins available'}</h2>
        </div>
        <div className="mt-2">
          <p className="text-base">Accounts Created: {isLoading ? loadingMessage : usersDataStatistics.totalCountUsers}</p>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-6">
          <div className="max-h-60 w-full aspect-w-1 aspect-h-1">
            <Pie data={chartData} />
          </div>
          <div className="max-h-60 w-full aspect-w-1 aspect-h-1">
            <Pie data={roleChartData} />
          </div>
        </div>

        <div className="mt-6">
          <p className="text-lg font-semibold">Campaigns:</p>
        </div>
        <div className="mt-2">
          <p className="text-base">Campaigns Created: {isLoading ? loadingMessage : campaignsDataStatistics.totalCount}</p>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-6">
          <div className="max-h-60 w-full aspect-w-1 aspect-h-1">
            <Pie data={chartCampaignSocialPlatform} />
          </div>
          <div className="max-h-60 w-full aspect-w-1 aspect-h-1">
            <Pie data={chartCampaignCampaignType} />
          </div>
        </div>
      </div>

      <div className="mt-6 w-full">
        {isLoading ? (
          <div>{loadingMessage}</div>
        ) : (
          <>
            <div className="flex mt-6">
              <input
                type="text"
                placeholder="Search by Email, Name or Username"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className={`${getInputTextStyleClass()} w-full p-2`}
              />
              <button className={getButtonPrimaryStyleClass(false)} onClick={() => setIsSearchTerm(true)}>
                Search
              </button>
            </div>
            <div className="overflow-x-auto">
              <table className="table-auto">
                {/* Table Header */}

                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id} className="border-b bg-gray-200">
                    {headerGroup.headers.map((header) => (
                      <th
                        key={header.id}
                        className="py-2 px-4 text-left border-r"
                      >
                        {header.isPlaceholder
                          ? null
                          : flexRender(header.column.columnDef.header, header.getContext())}
                      </th>
                    ))}
                  </tr>
                ))}


                {/* Table Body */}

                {table.getRowModel().rows.map((row) => (
                  <tr
                    key={row.id}
                    className="border-b"
                  >
                    {row.getVisibleCells().map((cell) => (
                      <td
                        key={cell.id}
                        className="py-2 px-4 border-r"
                        data-label={flexRender(cell.column.columnDef.cell, cell.getContext())}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    ))}
                  </tr>
                ))}

              </table>
            </div>

            <div className="mt-4">
              <SPPagination
                pageCount={pageCount}
                onPageChange={handlePageChange}
                currentPage={currentPage}
              />
            </div>
          </>
        )}
      </div>

      <EditUserModal
        userDataDto={selectedUser}
        isOpen={isEditUserModalOpen}
        onClose={handleEditUserModalClose}
      />

    </Layout>
  );
};

export default AdminDashboard;