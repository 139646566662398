import notificationService from "../services/NotificationService";

/*
*@waitTime value in milliseconds
*/
export const canSubmit = (waitTime: number = 5000): boolean => {
  const lastSubmitted = localStorage.getItem('lastSubmitted');
  const now = new Date().getTime();

  if (lastSubmitted && now - parseInt(lastSubmitted) < waitTime) {
    const remainingTime = Math.ceil((waitTime - (now - parseInt(lastSubmitted))) / 1000);
    notificationService.setMessage({
      message: `Please wait ${remainingTime} seconds before trying again.`, 
      status: false
    });
    return false;
  }

  localStorage.setItem('lastSubmitted', now.toString());
  return true;
};