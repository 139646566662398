import React, { useEffect, useRef } from 'react';
import CloseButton from '../../components/buttons/CloseButton';

interface CheckoutModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

const CheckoutModal: React.FC<CheckoutModalProps> = ({ isOpen, onClose, children }) => {
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div
      className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 overflow-auto">
      <div
        ref={modalRef}
        className="relative bg-white p-6 rounded shadow-lg container mx-4 sm:mx-0 max-h-[90vh] overflow-y-auto">
        <div className='flex items-center justify-center absolute top-2 right-2'>
          <CloseButton
            text="Close"
            onClick={onClose}
          />
        </div>
        <div>
          {children}
        </div>
      </div>
    </div>
  );
};

export default CheckoutModal;