import React from 'react';

const VideoIframe: React.FC = () => {

    return (
        <>
            <iframe className='relative w-full h-full' src="https://www.youtube.com/embed/1eEVPCwKNNw?si=s6NrElTwUXAg0_9S&loop=1&controls=2&showinfo=1&autoplay=1" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen={true}></iframe>
        </>
    );
};

export default VideoIframe;