import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear, faQuestion, faInfo, faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { useForm, SubmitHandler } from 'react-hook-form';
import loggerService from '../../services/LoggerService';
import { CampaignSettingsDTO } from '../../dto/CampaignSettingsDTO';
import { getBackgroundActive, getButtonDisabledStyleClass, getButtonSuccessStyleClass, getInputTextStyleClass, getStandardCardBackground } from '../../config/FormSettings';
import { FormStateValidator } from '../../config/FormStateValidator';
import { useCampaignContext } from '../../contexts/CampaignContext';
import notificationService from '../../services/NotificationService';
import { CampaignTypeEnum } from '../../helpers/CampaignTypeEnum';
import { useIsLoading } from '../../hooks/useIsLoading';
import { useLoadingMessage } from '../../hooks/useLoadingMessage';
import CampaignLoading from '../loadings/CampaignLoading';
import CloseButton from '../buttons/CloseButton';
import { MaxWEnum } from '../../helpers/FormEnum';
import { CampaignViewEnum } from '../../helpers/CampaignViewEnum';
import { RoutesEnum } from '../../routes/RoutesEnum';
import { useNavigate, useParams } from 'react-router-dom';
import ConfirmCampaignModal from '../modals/ConfirmCampaignModal';
import CampaignPreview from './CampaignPreview';
import axiosInstance, { CustomAxiosRequestConfig } from '../../auth/config/axiosInstance';
import { AxiosResponse } from 'axios';
import { ApiRoutesEnum } from '../../routes/ApiRoutesEnum';
import { defaultMaxRetry, defaultWaitBetweenRequestsMs, minParticipantsCount } from '../../config/envConfig';
import { WinnerDTO } from '../../dto/WinnerDTO';
import { getRandomWinners } from '../../helpers/CampaignHelper';
import NotificationService from '../../services/NotificationService';

interface CampaignSettingsFormInputs {
    title: string;
    winners: number;
    countdown: number;
    eliminateDuplicates: boolean;
}

const CampaignSettings: React.FC = () => {
    const {
        setFilteredCampaignParticipants,
        filteredCampaignParticipants,
        campaignSettingsDTO,
        setCampaignSettingsDTO,
        setIsSavingSettings,
        isSavingSettings,
        isSettingsOpened,
        campaignViewEnum,
        setCampaignViewEnum,
        campaignStateDataDTO,
        navigateReferrer,
        setCurrentProgressStepIndex,
        winners,
        setWinners,
        substitutes,
        setSubstitutes,
        eliminateDuplicates,
        setEliminateDuplicates,
        filterByMention,
        setFilterByMention,
        mention,
        setMention,
        filterByHashtag,
        setFilterByHashtag,
        hashTag,
        setHashtag,
        countdown,
        setCountdown,
        campaignParticipants,
        setCampaignWinners,
        setCampaignSubstitutes,
        campaign,
    } = useCampaignContext();
    const navigate = useNavigate();
    const [formStateValidator, setFormStateValidator] = useState<FormStateValidator[]>([]);
    const { register, handleSubmit, formState: { errors } } = useForm<CampaignSettingsFormInputs>();
    const [isInfoOpened, setIsInfoOpened] = useState(false);
    const [isConfirmCampaignModal, setIsConfirmCampaignModal] = useState<boolean>(false);
    const [isStartingCampaign, setIsStartingCampaign] = useState<boolean>(false);
    const { isLoading, setIsLoading } = useIsLoading();
    const { loadingMessage, setLoadingMessage } = useLoadingMessage();
    //when used in C component, code will never be null
    const { code } = useParams<{ code: string }>();

    const handleFilterByMention = () => {
        setFilterByMention(prevState => !prevState);
    };

    const handleFilterByHashtag = () => {
        setFilterByHashtag(prevState => !prevState);
    };

    const handleEliminateDuplicates = () => {
        setEliminateDuplicates(prevState => !prevState);
    };

    const handleIncrement = (setter: React.Dispatch<React.SetStateAction<number>>, value: number) => {
        setter(prev => Math.min(prev + 1, value));
    };

    const handleDecrement = (setter: React.Dispatch<React.SetStateAction<number>>, value: number) => {
        setter(prev => Math.max(prev - 1, 1));
    };

    useEffect(() => {
        const startCampaign = async () => {
            if (isStartingCampaign) {
                try {
                    const formData = {
                        code: code,
                        settingsDto: campaignSettingsDTO
                    };

                    const startCampaignRetry = async (retries = 0, delay = defaultWaitBetweenRequestsMs): Promise<AxiosResponse> => {
                        try {
                            const response = await axiosInstance.post(
                                ApiRoutesEnum.START_CAMPAIGN,
                                formData,
                                { authNeeded: true } as CustomAxiosRequestConfig);
                            return response;
                        } catch (error: any) {
                            if (error.response && error.response.status === 403) {
                                throw error;
                            } else if (retries > 0) {
                                await new Promise(resolve => setTimeout(resolve, delay));
                                return startCampaignRetry(retries - 1, delay);
                            } else {
                                throw error;
                            }
                        }
                    };

                    const response: any = await startCampaignRetry();
                    const { winners, substitutes } = response.data;

                    const winnersData: WinnerDTO[] = winners
                        .map((winner: any) => ({
                            Id: winner.id,
                            Username: winner.username,
                            Comment: winner.comment,
                            CommentLink: winner.commentLink,
                            ProfileLink: winner.profileLink,
                            IsValid: winner.isValid,
                            IsSubstitute: winner.isSubstitute,
                            InvalidReason: winner.invalidReason,
                        }));

                    setCampaignWinners(winnersData);

                    const substitutesData: WinnerDTO[] = substitutes
                        .map((winner: any) => ({
                            Id: winner.id,
                            Username: winner.username,
                            Comment: winner.comment,
                            CommentLink: winner.commentLink,
                            ProfileLink: winner.profileLink,
                            IsValid: winner.isValid,
                            IsSubstitute: winner.isSubstitute,
                            InvalidReason: winner.invalidReason,
                        }));

                    setCampaignSubstitutes(substitutesData);

                    setCampaignViewEnum(CampaignViewEnum.WinnersEdit);
                }
                catch (error: any) {
                    notificationService.setError(error);

                    setIsStartingCampaign(false);
                }
            }
        }

        startCampaign();
    }, [isStartingCampaign]);

    useEffect(() => {
        if (campaign?.CampaignType === CampaignTypeEnum.List && campaignViewEnum === CampaignViewEnum.SettingsEdit) {
            setIsStartingCampaign(true);
        }
    }, []);

    const onSubmit: SubmitHandler<CampaignSettingsFormInputs> = async (data: any) => {
        const handleSubmitForm = async () => {
            //save campaign
            if (campaignViewEnum === CampaignViewEnum.Settings) {
                try {
                    setIsSavingSettings(true);

                    if (!isFormStateValid()) {
                        setIsSavingSettings(false);
                        return;
                    }

                    const campaignSettingsDTO: CampaignSettingsDTO = {
                        Title: data.title,
                        Winners: winners,
                        Substitutes: substitutes,
                        EliminateDuplicates: eliminateDuplicates,
                        FilterByMention: filterByMention,
                        Mention: mention,
                        FilterByHashtag: filterByHashtag,
                        Hashtag: hashTag,
                        Countdown: countdown,
                    }

                    if (campaignStateDataDTO?.CampaignType === CampaignTypeEnum.List) {
                        if (campaignParticipants?.length && campaignParticipants?.length < minParticipantsCount) {
                            notificationService.setMessage({
                                message: "Not enough participants",
                                status: false,
                            });

                            setIsSavingSettings(false);

                            navigateReferrer();

                            return;
                        }

                        let filtered;

                        //filters
                        if (campaignSettingsDTO.EliminateDuplicates) {
                            const seenUsernames = new Set();
                            filtered = campaignParticipants?.filter((winner) => {
                                if (seenUsernames.has(winner.Username)) {
                                    return false;
                                } else {
                                    seenUsernames.add(winner.Username);
                                    return true;
                                }
                            });
                        }
                        //filters - end

                        //no filters applied check
                        if (!filtered) {
                            filtered = campaignParticipants;
                            setFilteredCampaignParticipants(filtered);
                        }
                        else if (filtered && filtered.length > 0) {
                            setFilteredCampaignParticipants(filtered);
                        }
                        else {
                            filtered = null;
                            setFilteredCampaignParticipants(null);
                        }

                        const [statusMessage, selectedWinners, selectedSubstitutes] = await getRandomWinners(campaignStateDataDTO, campaignSettingsDTO, filtered);

                        if (statusMessage) {
                            NotificationService.setMessage({
                                status: false,
                                message: statusMessage,
                            });

                            setIsSavingSettings(false);

                            return;
                        }

                        setCampaignWinners(selectedWinners);
                        setCampaignSubstitutes(selectedSubstitutes);
                    }

                    setCampaignSettingsDTO(campaignSettingsDTO);
                    setIsSavingSettings(false);

                    setCurrentProgressStepIndex(2);
                    setCampaignViewEnum(CampaignViewEnum.Credits);
                }
                catch (error: any) {
                    notificationService.setMessage({
                        message: "Failed to save, try again",
                        status: false
                    });

                    loggerService.error(error);

                    setIsSavingSettings(false);
                }
            }
            //start campaign and finish
            else if (campaignViewEnum === CampaignViewEnum.SettingsEdit) {
                try {
                    if (!isFormStateValid()) {
                        setIsStartingCampaign(false);
                        return;
                    }

                    const campaignSettingsDTO: CampaignSettingsDTO = {
                        Title: data.title,
                        Winners: winners,
                        Substitutes: substitutes,
                        EliminateDuplicates: eliminateDuplicates,
                        FilterByMention: filterByMention,
                        Mention: mention,
                        FilterByHashtag: filterByHashtag,
                        Hashtag: hashTag,
                        Countdown: countdown,
                    }

                    setCampaignSettingsDTO(campaignSettingsDTO);

                    setIsConfirmCampaignModal(true);

                    return;
                }
                catch (error: any) {
                    notificationService.setMessage({
                        message: "Failed to start, try again",
                        status: false
                    });

                    loggerService.error(error);

                    setIsStartingCampaign(false);
                }
            }
        };

        handleSubmitForm();
    };

    const isFormStateValid = () => {
        const validators: FormStateValidator[] = [];

        if (!winners || winners < 1 || winners > 50) {
            validators.push({
                Name: 'winners',
                Error: 'Winners must be between 1 and 50',
            });

            notificationService.setMessage({
                message: "Winners must be between 1 and 50",
                status: false,
            });
        }

        if (!substitutes || substitutes < 1 || substitutes > 50) {
            validators.push({
                Name: 'substitutes',
                Error: 'Substitutes must be between 1 and 50',
            });

            notificationService.setMessage({
                message: "Substitutes must be between 1 and 50",
                status: false,
            });
        }

        setFormStateValidator(validators);

        return validators.length === 0;
    };

    return (
        <>
            {isLoading ? (
                <>
                    <CampaignLoading
                        loadingMessage={loadingMessage}
                    />
                </>
            ) : (
                <>
                    <div className={`${getStandardCardBackground(true, MaxWEnum.None, "", "my-2", true)}`}>
                        <div className="mb-2 mt-2 text-right float-right">
                            <CloseButton
                                text="Back"
                                onClick={() => {
                                    if (campaignViewEnum === CampaignViewEnum.SettingsEdit) {
                                        navigate(RoutesEnum.DASHBOARD);
                                    }
                                    else {
                                        navigateReferrer();
                                    }
                                }}
                            />
                        </div>
                    </div>

                    {isSettingsOpened && (
                        <div className={`card ${getBackgroundActive()} rounded-lg shadow-md w-full`}>
                            <div className="flex justify-between items-center px-4 py-2">
                                <div className="flex-1 text-white text-center text-lg font-semibold">
                                    <FontAwesomeIcon icon={faGear} className="mr-2 text-white" />
                                    Settings
                                </div>
                                <div className="text-white text-center text-base">
                                    <button onClick={() => { setIsInfoOpened(!isInfoOpened); }}>
                                        <FontAwesomeIcon icon={faQuestion} className="mr-2 text-white" />
                                        Help
                                    </button>
                                </div>
                            </div>
                            <div className="card-body py-3 px-4 border-t border-opacity-20 border-white rounded-lg">
                                <div className="flex flex-col items-center pt-0 pb-0">
                                    <form onSubmit={handleSubmit(onSubmit)} className="w-full mt-0 mb-0">
                                        <div className="grid grid-cols-1 gap-4">
                                            <div className="col-span-1">
                                                {isInfoOpened && (
                                                    <div>
                                                        <FontAwesomeIcon icon={faInfo} className="mr-2 text-white" />
                                                        <label className="text-sm text-white">Title: Giveaway / Promotion title</label>
                                                    </div>
                                                )}
                                                <input type="text" className={`${getInputTextStyleClass()} text-white placeholder-gray-400 w-full p-2 mt-1`}
                                                    {...register('title', { required: 'Title is required', maxLength: { value: 100, message: "Title cannot exceed 100 characters" } })}
                                                    maxLength={100}
                                                    placeholder='Title'
                                                    defaultValue={`${campaignSettingsDTO?.Title
                                                        ? campaignSettingsDTO.Title
                                                        : `${campaignStateDataDTO?.UsernameDisplay ?? ""}${campaignStateDataDTO?.Username ?? ""}${campaignStateDataDTO?.CampaignType === CampaignTypeEnum.List ? "Giveaway" : "'s Giveaway"}`
                                                        }`}
                                                />
                                                {errors.title && <p className="text-white bg-error text-center text-sm">{errors.title.message}</p>}
                                            </div>

                                            <div className="col-span-1">
                                                <div className="flex items-center mt-2">
                                                    <label className="block text-white">~ Winners</label>
                                                    <div className="ml-auto flex items-center">
                                                        <div className="relative flex items-center max-w-[8rem]">
                                                            <button type="button" className="bg-transparent rounded-s-lg p-3 h-11 focus:ring-white focus:outline-none group"
                                                                onClick={() => handleDecrement(setWinners, 50)}>
                                                                <FontAwesomeIcon icon={faArrowDown} className='w-3 h-full text-white group-hover:text-silver' />
                                                            </button>
                                                            <input
                                                                type="text"
                                                                name="winners"
                                                                className={`h-11 text-center text-sm focus:ring-secondary block w-full py-2.5 text-white ${getInputTextStyleClass()}`}
                                                                value={winners}
                                                                onChange={(e) => setWinners(Number(e.target.value))}
                                                            />
                                                            <button type="button" className="bg-transparent rounded-e-lg p-3 h-11 focus:ring-white focus:outline-none group"
                                                                onClick={() => handleIncrement(setWinners, 50)}>
                                                                <FontAwesomeIcon icon={faArrowUp} className='w-3 h-full text-white group-hover:text-silver' />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                {formStateValidator
                                                    .filter(error => error.Name === "winners")
                                                    .map((error, index) => (
                                                        <p key={index} className="text-white bg-error text-center text-sm">{error.Error}</p>
                                                    ))}
                                                {isInfoOpened && (
                                                    <div>
                                                        <FontAwesomeIcon icon={faInfo} className="mr-2 text-white" />
                                                        <label className="text-sm text-white">~ Winners: The number of winners extracted for this Giveaway / Promotion, between 1-50</label>
                                                    </div>
                                                )}
                                            </div>

                                            <div className="col-span-1">
                                                <div className="flex items-center mt-2">
                                                    <label className="block text-white">~ Substitutes</label>
                                                    <div className="ml-auto flex items-center">
                                                        <div className="relative flex items-center max-w-[8rem]">
                                                            <button type="button" className="bg-transparent rounded-s-lg p-3 h-11 focus:ring-white focus:outline-none group"
                                                                onClick={() => handleDecrement(setSubstitutes, 50)}>
                                                                <FontAwesomeIcon icon={faArrowDown} className='w-3 h-full text-white group-hover:text-silver' />
                                                            </button>
                                                            <input
                                                                type="text"
                                                                name="substitutes"
                                                                className={`h-11 text-center text-sm focus:ring-secondary block w-full py-2.5 text-white ${getInputTextStyleClass()}`}
                                                                value={substitutes}
                                                                onChange={(e) => setSubstitutes(Number(e.target.value))}
                                                            />
                                                            <button type="button" className="bg-transparent rounded-e-lg p-3 h-11 focus:ring-white focus:outline-none group"
                                                                onClick={() => handleIncrement(setSubstitutes, 50)}>
                                                                <FontAwesomeIcon icon={faArrowUp} className='w-3 h-full text-white group-hover:text-silver' />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                {formStateValidator
                                                    .filter(error => error.Name === "substitutes")
                                                    .map((error, index) => (
                                                        <p key={index} className="text-white bg-error text-center text-sm">{error.Error}</p>
                                                    ))}
                                                {isInfoOpened && (
                                                    <div>
                                                        <FontAwesomeIcon icon={faInfo} className="mr-2 text-white" />
                                                        <label className="text-sm text-white">~ Substitutes: If the winners do not follow the rules / settings or you cannot reach them, you can use substitutes, between 1-50</label>
                                                    </div>
                                                )}
                                            </div>

                                            <div className="col-span-1">
                                                <div className="flex items-center mt-3">
                                                    <label className="block text-white">Eliminate Duplicates</label>
                                                    <div className="ml-auto flex items-center switcher">
                                                        <label className="inline-flex items-center cursor-pointer">
                                                            <input type="checkbox" className="sr-only peer"
                                                                checked={eliminateDuplicates}
                                                                onChange={handleEliminateDuplicates}
                                                            />
                                                            <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-white rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-success"></div>
                                                            <span className="ms-3 text-sm font-medium text-white">{eliminateDuplicates ? 'YES' : 'NO'}</span>
                                                        </label>
                                                    </div>
                                                </div>
                                                {isInfoOpened && (
                                                    <div>
                                                        <FontAwesomeIcon icon={faInfo} className="mr-2 text-white" />
                                                        <label className="text-sm text-white">Eliminate Duplicates: All participants will have the same chance of winning without taking into account the number of times they comment</label>
                                                    </div>
                                                )}
                                            </div>

                                            {campaignStateDataDTO?.CampaignType !== CampaignTypeEnum.List && (
                                                <>
                                                    <div className="col-span-1">
                                                        <div className="flex items-center mt-3">
                                                            <label className="block text-white">Filter by @Mention</label>
                                                            <div className="ml-auto flex items-center switcher">
                                                                <label className="inline-flex items-center cursor-pointer">
                                                                    <input type="checkbox" className="sr-only peer"
                                                                        checked={filterByMention}
                                                                        onChange={handleFilterByMention}
                                                                    />
                                                                    <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-white rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-success"></div>
                                                                    <span className="ms-3 text-sm font-medium text-white">{filterByMention ? 'YES' : 'NO'}</span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        {filterByMention && (
                                                            <>
                                                                <div className="pt-2 text-center">
                                                                    <input type="text"
                                                                        placeholder="Write a @Mention"
                                                                        value={mention}
                                                                        maxLength={100}
                                                                        onChange={(e) => setMention(e.target.value)}
                                                                        className={`${getInputTextStyleClass()} w-full max-w-md py-2 px-2 text-white`}
                                                                    />
                                                                </div>
                                                            </>
                                                        )}
                                                        {isInfoOpened && (
                                                            <div>
                                                                <FontAwesomeIcon icon={faInfo} className="mr-2 text-white" />
                                                                <label className="text-sm text-white">Filter by @Mention: The participants must @Tag a specific account you mention. You can automatically dismiss all participants that don't meet this requirement.</label>
                                                            </div>
                                                        )}
                                                    </div>

                                                    <div className="col-span-1">
                                                        <div className="flex items-center mt-3">
                                                            <label className="block text-white">Filter by #Hashtag</label>
                                                            <div className="ml-auto flex items-center switcher">
                                                                <label className="inline-flex items-center cursor-pointer">
                                                                    <input type="checkbox" className="sr-only peer"
                                                                        checked={filterByHashtag}
                                                                        onChange={handleFilterByHashtag}
                                                                    />
                                                                    <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-white rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-success"></div>
                                                                    <span className="ms-3 text-sm font-medium text-white">{filterByHashtag ? 'YES' : 'NO'}</span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        {filterByHashtag && (
                                                            <>
                                                                <div className="pt-2 text-center">
                                                                    <input type="text"
                                                                        placeholder="Write a #Hashtag"
                                                                        value={hashTag}
                                                                        maxLength={100}
                                                                        onChange={(e) => setHashtag(e.target.value)}
                                                                        className={`${getInputTextStyleClass()} w-full max-w-md py-2 px-2 text-white`}
                                                                    />
                                                                </div>
                                                            </>
                                                        )}
                                                        {isInfoOpened && (
                                                            <div>
                                                                <FontAwesomeIcon icon={faInfo} className="mr-2 text-white" />
                                                                <label className="text-sm text-white">Filter by #Hashtag: You should apply this filter when one of the conditions is to type a #hashtag in the comment section.</label>
                                                            </div>
                                                        )}
                                                    </div>
                                                </>
                                            )}

                                            <div className="col-span-1">
                                                <div className="flex items-center mt-3">
                                                    <label className="block text-white">Countdown</label>
                                                    <div className="ml-auto flex items-center">
                                                        <div className="relative flex items-center max-w-[8rem]">
                                                            <button type="button" className="bg-transparent rounded-s-lg p-3 h-11 focus:ring-white focus:outline-none group"
                                                                onClick={() => handleDecrement(setCountdown, 999)}>
                                                                <FontAwesomeIcon icon={faArrowDown} className='w-3 h-full text-white group-hover:text-silver' />
                                                            </button>
                                                            <input
                                                                type="text"
                                                                name="countdown"
                                                                value={countdown} className={`h-11 text-center text-sm focus:ring-secondary block w-full py-2.5 text-white ${getInputTextStyleClass()}`}
                                                                onChange={(e) => setCountdown(Number(e.target.value))}
                                                            />
                                                            <button type="button" className="bg-transparent rounded-e-lg p-3 h-11 focus:ring-white focus:outline-none group"
                                                                onClick={() => handleIncrement(setCountdown, 999)}>
                                                                <FontAwesomeIcon icon={faArrowUp} className='w-3 h-full text-white group-hover:text-silver' />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                {isInfoOpened && (
                                                    <div>
                                                        <FontAwesomeIcon icon={faInfo} className="mr-2 text-white" />
                                                        <label className="text-sm text-white">Countdown: The number in seconds to display the winners</label>
                                                    </div>
                                                )}
                                            </div>

                                            <div className="col-span-1">
                                                {campaignViewEnum === CampaignViewEnum.Settings &&
                                                    (
                                                        <button
                                                            type="submit"
                                                            disabled={isSavingSettings ? true : false}
                                                            className={`${isSavingSettings ? getButtonDisabledStyleClass(true) : getButtonSuccessStyleClass(true)} w-full`}>
                                                            {isSavingSettings ?
                                                                "Applying filters" :
                                                                "Save"}
                                                        </button>
                                                    )}
                                                {campaignViewEnum === CampaignViewEnum.SettingsEdit &&
                                                    (
                                                        <button
                                                            type="submit"
                                                            disabled={isStartingCampaign ? true : false}
                                                            className={`${isStartingCampaign ? getButtonDisabledStyleClass(true) : getButtonSuccessStyleClass(true)} w-full`}>
                                                            {isStartingCampaign ?
                                                                "Starting campaign..." :
                                                                "Start"}
                                                        </button>
                                                    )}
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    )}
                </>
            )}

            <ConfirmCampaignModal
                isOpen={isConfirmCampaignModal}
                onClose={() => { setIsConfirmCampaignModal(false); }}
                setValue={() => setIsStartingCampaign(true)} />
        </>
    );
};

export default CampaignSettings;