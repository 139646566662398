import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { getStandardCardBackground } from '../config/FormSettings';
import { MaxWEnum } from '../helpers/FormEnum';

interface Props {
    currentProgressStepIndex: number;
    progressStepsNames: string[];
}

const ProgressSteps: React.FC<Props> = ({ currentProgressStepIndex, progressStepsNames }) => {
    return (
        <div className={`justify-center ${getStandardCardBackground(undefined, MaxWEnum.None)} mb-2 mx-auto`}>
            <nav aria-label="Progress">
                <ol className="flex flex-col md:flex-row">

                    {progressStepsNames.map((name, index) => (
                        <React.Fragment key={name}>
                            <li className="relative flex flex-col md:flex-row items-center mb-2 mr-2">
                                <div className="flex items-center text-gray-500">
                                    {currentProgressStepIndex < index && (
                                        <span className="flex items-center justify-center w-8 h-8 bg-gray-300 text-gray-500 rounded-full">{index + 1}</span>
                                    )}
                                    {currentProgressStepIndex === index && (
                                        <span className="flex items-center justify-center w-8 h-8 bg-primary text-white rounded-full">{index + 1}</span>
                                    )}
                                    {currentProgressStepIndex > index && (
                                        <span className="flex items-center justify-center w-8 h-8 bg-green-600 text-white rounded-full">
                                            <FontAwesomeIcon icon={faCheck} />
                                        </span>
                                    )} 
                                    <span className="ml-2">{progressStepsNames[index]}</span>
                                </div>
                            </li>
                        </React.Fragment>
                    ))}
                    
                </ol>
            </nav>
        </div>
    );
};

export default ProgressSteps;