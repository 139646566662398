import React, { useEffect } from 'react';
import Layout from '../../components/Layout';
import { Helmet } from 'react-helmet';
import CampaignSearchByCode from '../../components/campaign/CampaignSearchByCode';
import AdBlockModal from '../../components/modals/AdBlockModal';
import { useAuthContext } from '../../auth/contexts/AuthContext';

const CampaignSearchByCodePage: React.FC = () => {
  const { setShowGoogleAdsense } = useAuthContext();

  useEffect(() => {
    setShowGoogleAdsense(true);

    return () => {
      setShowGoogleAdsense(false);
    }
  }, []);

  return (
    <Layout>
      <Helmet>
        <title>socialspicker.com - Search Giveaway Result By Code</title>
      </Helmet>
      <CampaignSearchByCode />
      <AdBlockModal />
    </Layout>
  );
};

export default CampaignSearchByCodePage;