import React, { useEffect, useState } from 'react';
import Layout from '../../../components/Layout';
import { Helmet } from 'react-helmet';
import { useAuthContext } from '../../../auth/contexts/AuthContext';
import AdBlockModal from '../../../components/modals/AdBlockModal';
import './FlipCoin.css';
import { getButtonPrimaryStyleClass } from '../../../config/FormSettings';

const FlipCoin: React.FC = () => {
  const { setShowGoogleAdsense } = useAuthContext();
  const [isFlipping, setIsFlipping] = useState(false);
  const [result, setResult] = useState<'Heads' | 'Tails' | null>('Heads');
  const [headsCount, setHeadsCount] = useState(0);
  const [tailsCount, setTailsCount] = useState(0);

  useEffect(() => {
    setShowGoogleAdsense(true);

    return () => {
      setShowGoogleAdsense(false);
    }
  }, []);

  useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      if (e.code === 'Space') {
        e.preventDefault();
        flipCoin();
      }
    };
    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  const flipCoin = () => {
    setIsFlipping(true); // Start flipping

    setTimeout(() => {
      const result = Math.random() < 0.5 ? 'Heads' : 'Tails';
      setResult(result);

      if (result === 'Heads') {
        setHeadsCount((prev) => prev + 1);
      } else {
        setTailsCount((prev) => prev + 1);
      }

      setIsFlipping(false); // End flipping
    }, 2000); // Coin flips for 2 seconds
  };

  return (
    <Layout>
      <Helmet>
        <title>socialspicker.com - Flip a Coin Online, Coin Toss</title>
      </Helmet>

      <h1 className="text-3xl font-bold mb-4">Heads or Tails</h1>
      <div className='mb-4'>
        <p>Flip a virtual coin with this online and free app</p>
      </div>

      {/* Coin Display with Animation */}
      <div className={`p-[100px] coin mt-6 text-white hover:cursor-pointer ${isFlipping ? 'flipping' : ''} ${result === 'Heads' ? 'bg-primary' : 'bg-error'}`}
        onClick={flipCoin}
      >
        {isFlipping ? '?' : result}
      </div>

      {/* Scoreboard */}
      <div className="mt-6 text-xl flex flex-wrap gap-2">
        <p className='flex-col'>Heads: {headsCount}</p>
        <p className='flex-col'>Tails: {tailsCount}</p>
      </div>

      <button
        className={`mt-6 px-6 py-2 ${getButtonPrimaryStyleClass(false)}`}
        onClick={flipCoin}
        disabled={isFlipping} // Disable button during flipping
      >
        {isFlipping ? 'Flipping...' : 'Flip'}
      </button>
      <p className='mt-2'>(Touch screen or press space bar)</p>

      <AdBlockModal />
    </Layout>
  );
};

export default FlipCoin;