export enum RoutesEnum {
    HOME = '/',
    LOGIN = '/login',
    SIGNUP = '/signup',
    SET_NEW_PASSWORD = '/set-new-password/:token',
    SET_EMAIL_VERIFY = '/set-email-verify/:token/:newEmail?',
    RESET_PASSWORD = '/reset-password',
    DASHBOARD = '/dashboard',
    CAMPAIGN = '/campaign',
    PLANS = '/plans',
    HELP = '/help',
    BILLING = '/billing',
    APPS = '/apps',
    C = '/c/:code',
    P = '/:username',
    CAMPAIGN_SEARCH_BY_CODE = '/apps/giveaway-search-code',
    APP_INSTAGRAM_URL = '/apps/instagram-comment-picker-url',
    APP_TIKTOK_URL = '/apps/tiktok-comment-picker-url',
    APP_YOUTUBE_URL = '/apps/youtube-comment-picker-url',
    APP_TWITTER_URL = '/apps/twitter-x-comment-picker-url',
    APP_RANDOM_NAME_WINNERS = '/apps/random-name-winners-list',
    APP_CREATE_GIVEAWAY_IMAGE = '/apps/create-giveaway-image',
    APP_ROLL_DICE = '/apps/roll-dice-online',
    APP_RANDOM_NUMBERS = '/apps/random-numbers',
    APP_FLIP_COIN = '/apps/flip-a-coin-online',
    PRIVACY_POLICY = '/privacy-policy',
    TERMS = '/terms',
    REDIRECT_HANDLER = '/redirect-handler/:socialPlatform',
    MAINTENANCE = '*',
    TEST = '*',
    NOT_FOUND = '*',
    ADMIN_DASHBOARD = '/admin-dashboard',
  }