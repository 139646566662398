import React, { useEffect, useState } from 'react';
import Layout from '../../components/Layout';
import { Helmet } from 'react-helmet';
import { useAuthContext } from '../../auth/contexts/AuthContext';
import { getButtonSuccessStyleClass, getCardBackgroundPrimary, getInputTextStyleClass } from '../../config/FormSettings';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import ConfettiCanvas from '../../components/ConfettiCanvas';
import AdBlockModal from '../../components/modals/AdBlockModal';

const RandomNumbers: React.FC = () => {
  const { setShowGoogleAdsense } = useAuthContext();
  const [minNumber, setMinNumber] = useState(1);
  const [maxNumber, setMaxNumber] = useState(10);
  const [amountOfNumbers, setamountOfNumbers] = useState(1);
  const [randomNumbers, setRandomNumbers] = useState<number[]>([]);
  const [isGenerated, setIsGenerated] = useState<boolean>(false);
  const [isConfetti, setIsConfetti] = useState<boolean>(false);
  const [isUnique, setIsUnique] = useState(true);

  useEffect(() => {
    setShowGoogleAdsense(true);

    return () => {
      setShowGoogleAdsense(false);
    }
  }, []);

  const handleIsUnique = () => {
    setIsUnique(prevState => !prevState);
  };

  const handleIncrement = (setter: React.Dispatch<React.SetStateAction<number>>, value: number) => {
    setter(prev => Math.min(prev + 1, value));
  };

  const handleDecrement = (setter: React.Dispatch<React.SetStateAction<number>>, value: number) => {
    setter(prev => Math.max(prev - 1, 1));
  };

  const generateRandomNumbers = () => {
    let result: number[] = [];
    const range = maxNumber - minNumber + 1;

    // Ensure that the number of winners doesn't exceed the range of possible numbers if unique
    const totalNumbers = isUnique ? Math.min(amountOfNumbers, range) : amountOfNumbers;

    while (result.length < totalNumbers) {
      const randomNum = Math.floor(Math.random() * range) + minNumber;

      // For unique numbers, avoid duplicates
      if (isUnique) {
        if (!result.includes(randomNum)) {
          result.push(randomNum); // Ensure numbers are unique
        }
      } else {
        result.push(randomNum); // Allow duplicates if not unique
      }
    }

    return result;
  };

  const handleStart = () => {
    setIsGenerated(true);
    setIsConfetti(true);

    setTimeout(() => {
      setIsConfetti(false);
    }, 3000);

    const generatedNumbers = generateRandomNumbers();
    setRandomNumbers(generatedNumbers);
  };

  return (
    <Layout>
      <Helmet>
        <title>socialspicker.com - Random Numbers Generator</title>
      </Helmet>

      <div className={`${getCardBackgroundPrimary()}`}>
        <h1 className="text-3xl font-bold mb-4">Random Number Generator</h1>
        <div className='mb-4'>
          <p>Generate a sequence of random numbers online and for free with our Numbers Giveaway app.</p>
        </div>

        <div className="grid grid-cols-2 gap-4 mb-2">
          <div className='items-center'>
            <div className="col-span-1">
              <div className="flex items-center mt-2">
                <label className="block text-white">Min Number:</label>
                <div className="ml-auto flex items-center">
                  <div className="relative flex items-center max-w-[8rem]">
                    <button type="button" className="bg-transparent rounded-s-lg p-3 h-11 focus:ring-white focus:outline-none group"
                      onClick={() => handleDecrement(setMinNumber, 50)}>
                      <FontAwesomeIcon icon={faArrowDown} className='w-3 h-full text-white group-hover:text-silver' />
                    </button>
                    <input
                      type="text"
                      className={`h-11 text-center text-sm focus:ring-secondary block w-full py-2.5 text-white ${getInputTextStyleClass()}`}
                      value={minNumber}
                      maxLength={7}
                      onChange={(e) => setMinNumber(Number(e.target.value))}
                    />
                    <button type="button" className="bg-transparent rounded-e-lg p-3 h-11 focus:ring-white focus:outline-none group"
                      onClick={() => handleIncrement(setMinNumber, 50)}>
                      <FontAwesomeIcon icon={faArrowUp} className='w-3 h-full text-white group-hover:text-silver' />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='items-center'>
            <div className="col-span-1">
              <div className="flex items-center mt-2">
                <label className="block text-white">Max Number:</label>
                <div className="ml-auto flex items-center">
                  <div className="relative flex items-center max-w-[8rem]">
                    <button type="button" className="bg-transparent rounded-s-lg p-3 h-11 focus:ring-white focus:outline-none group"
                      onClick={() => handleDecrement(setMaxNumber, 50)}>
                      <FontAwesomeIcon icon={faArrowDown} className='w-3 h-full text-white group-hover:text-silver' />
                    </button>
                    <input
                      type="text"
                      className={`h-11 text-center text-sm focus:ring-secondary block w-full py-2.5 text-white ${getInputTextStyleClass()}`}
                      value={maxNumber}
                      maxLength={7}
                      onChange={(e) => setMaxNumber(Number(e.target.value))}
                    />
                    <button type="button" className="bg-transparent rounded-e-lg p-3 h-11 focus:ring-white focus:outline-none group"
                      onClick={() => handleIncrement(setMaxNumber, 50)}>
                      <FontAwesomeIcon icon={faArrowUp} className='w-3 h-full text-white group-hover:text-silver' />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

        <div className="flex flex-wrap justify-center mt-4">
          <div className='items-center'>
            <div className="col-span-1">
              <div className="flex items-center mt-2">
                <label className="block text-white">Amount of numbers:</label>
                <div className="ml-auto flex items-center">
                  <div className="relative flex items-center max-w-[8rem]">
                    <button type="button" className="bg-transparent rounded-s-lg p-3 h-11 focus:ring-white focus:outline-none group"
                      onClick={() => handleDecrement(setamountOfNumbers, 50)}>
                      <FontAwesomeIcon icon={faArrowDown} className='w-3 h-full text-white group-hover:text-silver' />
                    </button>
                    <input
                      type="text"
                      className={`h-11 text-center text-sm focus:ring-secondary block w-full py-2.5 text-white ${getInputTextStyleClass()}`}
                      value={amountOfNumbers}
                      maxLength={7}
                      onChange={(e) => setamountOfNumbers(Number(e.target.value))}
                    />
                    <button type="button" className="bg-transparent rounded-e-lg p-3 h-11 focus:ring-white focus:outline-none group"
                      onClick={() => handleIncrement(setamountOfNumbers, 5)}>
                      <FontAwesomeIcon icon={faArrowUp} className='w-3 h-full text-white group-hover:text-silver' />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex items-center mt-8 mb-4">
          <label className="block text-white mr-2">Unique numbers (no repeats)</label>
          <div className="ml-auto flex items-center switcher">
            <label className="inline-flex items-center cursor-pointer">
              <input type="checkbox" className="sr-only peer"
                checked={isUnique}
                onChange={handleIsUnique}
              />
              <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-white rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-success"></div>
              <span className="ms-3 text-sm font-medium text-white">{isUnique ? 'YES' : 'NO'}</span>
            </label>
          </div>
        </div>

        <button
          className={`${getButtonSuccessStyleClass(false)} mt-6 px-6 py-2`}
          onClick={handleStart}
        >
          Start
        </button>

        {isGenerated && (
          <>
            <div className="text-center mt-6">
              <h4 className="text-xl text-white font-semibold">Winners</h4>
            </div>
            <div className="p-4 gap-2 justify-center items-center flex flex-wrap">
              {randomNumbers && randomNumbers.map((winner, index) => (
                <div key={winner} className={`flex-col bg-white rounded-lg flex items-center shadow-lg border-[0.5px]`}>
                  <div className='relative text-[35px] font-bold text-gray-800 p-6 w-full h-full'>
                    <span className="absolute top-0 left-0 text-base p-1 text-white bg-success shadow-md rounded">{index + 1}</span>
                    <p className="p-1">{winner}</p>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}

      </div>

      {isConfetti && (
        <ConfettiCanvas />
      )}
      <AdBlockModal />
    </Layout>
  );
};

export default RandomNumbers;