import React, { ReactNode, useState } from 'react';

interface TooltipProps {
    text: string;
    children: ReactNode;
}

const Tooltip: React.FC<TooltipProps> = ({ text, children }) => {
    const [isVisible, setIsVisible] = useState(false);

    return (
        <div className="relative inline-block mx-auto">
            <div className="group">
                <div
                    className="cursor-pointer"
                    onMouseEnter={() => setIsVisible(true)}
                    onMouseLeave={() => setIsVisible(false)}
                >
                    {children}
                </div>
                {isVisible && (
                    <div className="absolute left-1/2 transform -translate-x-1/2 break-words whitespace-nowrap bottom-full mb-2 w-max px-3 py-1 text-white text-sm bg-black rounded-md shadow-lg">
                        {text}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Tooltip;