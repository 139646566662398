export enum ApiRoutesEnum {
  GET_USERS = '/api/Users/get-users',
  GET_USER = '/api/Users/get-user',
  GET_USER_BY_USERNAME = '/api/Users/get-user-by-username',
  EDIT_USER_ADMIN = '/api/Users/edit-user-admin',
  EDIT_USER = '/api/Users/edit-user',
  SIGNUP = '/api/Users/signup',
  LOGIN = '/api/Users/login',
  REFRESH = '/api/Users/refresh',
  RESET_PASSWORD = '/api/Users/reset-password',
  SET_NEW_PASSWORD = '/api/Users/set-new-password',
  EMAIL_VERIFY = '/api/Users/email-verify',
  SET_EMAIL_VERIFY = '/api/Users/set-email-verify',
  CHECK_EMAIL_VERIFY = '/api/Users/check-email-verify',
  OAUTH_GOOGLE = '/api/Users/oauth-google',
  OAUTH_FACEBOOK_INSTAGRAM = '/api/Users/oauth-facebook-instagram',

  GET_CAMPAIGNS_STATISTICS = '/api/Campaigns/get-campaigns-statistics',
  GET_CAMPAIGNS_BY_USER = '/api/Campaigns/get-campaigns-by-user',
  GET_CAMPAIGN_BY_CODE = '/api/Campaigns/get-campaign-by-code',
  START_CAMPAIGN = '/api/Campaigns/start-campaign',
  CREATE_CAMPAIGN = '/api/Campaigns/create-campaign',
  EDIT_CAMPAIGN = '/api/Campaigns/edit-campaign',
  EDIT_CAMPAIGN_WINNER = '/api/Campaigns/edit-campaign-winner',

  CAMPAIGNS_FETCH_GET_POST = '/api/CampaignsFetch/get-post',

  CREATE_CHECKOUT_SESSION = '/api/Payment/create-checkout-session',
  POLLING_PAYMENT = '/api/Payment/polling-payment',
  GET_BILLING_BY_USER = 'api/Payment/get-billing-by-user',
  DOWNLOAD_BILLING = 'api/Payment/download-billing',
}