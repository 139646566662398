import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import AxiosInterceptorSetup from './auth/config/AxiosInterceptorSetup';
import Home from './pages/Home';
import Login from './auth/pages/Login';
import Signup from './auth/pages/Signup';
import SetNewPassword from './auth/pages/SetNewPassword';
import ResetPassword from './auth/pages/ResetPassword';
import Dashboard from './pages/Dashboard';
import Plans from './pages/Plans';
import Help from './pages/Help/Help';
import Billing from './pages/Billing';
import InstagramUrl from './pages/campaign/apps/InstagramUrl';
import RollDice from './pages/apps/RollDice/RollDice';
import RandomNumbers from './pages/apps/RandomNumbers';
import FlipCoin from './pages/apps/FlipCoin/FlipCoin';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Terms from './pages/Terms';
import Maintenance from './pages/Maintenance';
import NotFound from './pages/NotFound';
import AdminDashboard from './pages/admin/AdminDashboard';
import { isMaintenance, isTestComponentAlwaysDisplay } from './config/envConfig';
import LoggedInNotAllowedProtectedRoute from './auth/routes/LoggedInNotAllowedProtectedRoute';
import RoleProtectedRoute from './auth/routes/RoleProtectedRoute';
import { RoutesEnum as AppRoutes } from './routes/RoutesEnum';
import { RolesEnum as Roles } from './auth/helpers/RolesEnum';
import SetEmailVerify from './auth/pages/SetEmailVerify';
import Campaign from './pages/campaign/Campaign';
import RedirectHandler from './pages/RedirectHandler';
import AuthProvider from './auth/contexts/AuthContext';
import CampaignProvider from './contexts/CampaignContext';
import { GoogleOAuthProvider } from '@react-oauth/google';
import CampaignRoutes from './routes/CampaignRoutes';
import Apps from './pages/Apps';
import Test from './components/Test';
import TiktokUrl from './pages/campaign/apps/TiktokUrl';
import YoutubeUrl from './pages/campaign/apps/YoutubeUrl';
import TwitterXUrl from './pages/campaign/apps/XTwitterUrl';
import RandomNameWinnerList from './pages/campaign/apps/RandomNameWinnerList';
import CampaignSearchByCodePage from './pages/apps/CampaignSearchByCodePage';
import CreateGiveawayImage from './pages/apps/CreateGiveawayImage';
import C from './pages/C';
import P from './pages/P';
import ScrollToTopAuto from './components/ScrollToTopAuto';

const App: React.FC = () => {
  return (
    <div className={`flex flex-col bg-gray-50`}>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID as string}>
        <Router>
          <ScrollToTopAuto />
          <AppRouter />
        </Router>
      </GoogleOAuthProvider>
    </div>
  );
}

const AppRouter: React.FC = () => {
  if (isMaintenance) {
    return (
      <Routes>
        <Route path={AppRoutes.MAINTENANCE} element={<Maintenance />} />
      </Routes>
    );
  }
  if (isTestComponentAlwaysDisplay) {
    return (
      <Routes>
        <Route path={AppRoutes.TEST} element={<Test />} />
      </Routes>
    );
  }

  return (
    <AuthProvider>
      <AxiosInterceptorSetup />
      <Routes>
        <Route path={AppRoutes.PLANS} element={<Plans />} />
        <Route path={AppRoutes.HELP} element={<Help />} />
        <Route path={AppRoutes.PRIVACY_POLICY} element={<PrivacyPolicy />} />
        <Route path={AppRoutes.TERMS} element={<Terms />} />
        <Route path={AppRoutes.REDIRECT_HANDLER} element={<RedirectHandler />} />
        <Route path={AppRoutes.SET_EMAIL_VERIFY} element={<SetEmailVerify />} />
        <Route path={AppRoutes.P} element={<P />} />

        {/*apps*/}
        <Route path={AppRoutes.APPS} element={<Apps />} />
        <Route path={AppRoutes.CAMPAIGN_SEARCH_BY_CODE} element={<CampaignSearchByCodePage />} />
        <Route path={AppRoutes.APP_CREATE_GIVEAWAY_IMAGE} element={<CreateGiveawayImage />} />
        <Route element={<CampaignProvider><CampaignRoutes /></CampaignProvider>}>
          <Route element={<LoggedInNotAllowedProtectedRoute />}>
            <Route path={AppRoutes.HOME} element={<Home />} />
          </Route>
          <Route path={AppRoutes.C} element={<C />} />
          <Route path={AppRoutes.CAMPAIGN} element={<Campaign />} />
          <Route path={AppRoutes.APP_INSTAGRAM_URL} element={<InstagramUrl />} />
          <Route path={AppRoutes.APP_TIKTOK_URL} element={<TiktokUrl />} />
          <Route path={AppRoutes.APP_YOUTUBE_URL} element={<YoutubeUrl />} />
          <Route path={AppRoutes.APP_TWITTER_URL} element={<TwitterXUrl />} />
          <Route path={AppRoutes.APP_RANDOM_NAME_WINNERS} element={<RandomNameWinnerList />} />
        </Route>
        <Route path={AppRoutes.APP_ROLL_DICE} element={<RollDice />} />
        <Route path={AppRoutes.APP_FLIP_COIN} element={<FlipCoin />} />
        <Route path={AppRoutes.APP_RANDOM_NUMBERS} element={<RandomNumbers />} />
        {/*apps - end*/}

        <Route element={<LoggedInNotAllowedProtectedRoute />}>
          <Route path={AppRoutes.RESET_PASSWORD} element={<ResetPassword />} />
          <Route path={AppRoutes.SET_NEW_PASSWORD} element={<SetNewPassword />} />
          <Route path={AppRoutes.LOGIN} element={<Login />} />
          <Route path={AppRoutes.SIGNUP} element={<Signup />} />
        </Route>

        <Route element={<RoleProtectedRoute roles={[Roles.ADMIN, Roles.USER]} />}>
          <Route path={AppRoutes.DASHBOARD} element={<Dashboard />} />
          <Route path={AppRoutes.BILLING} element={<Billing />} />
        </Route>

        <Route element={<RoleProtectedRoute roles={[Roles.ADMIN]} />}>
          <Route path={AppRoutes.ADMIN_DASHBOARD} element={<AdminDashboard />} />
        </Route>

        <Route path={AppRoutes.NOT_FOUND} element={<NotFound />} />
      </Routes>
    </AuthProvider>
  );
};

export default App;